import React from "react";
import { DeleteOutlined, BookOutlined, MoreOutlined } from "@ant-design/icons";
import { Dropdown, Flex, MenuProps, Tag } from "antd";
import { ColumnsType } from "antd/lib/table";
import { Issue, UserRole } from "../../../utilities/api/jelbi-dashboard-api";
import { humanizedDateTimeFormat } from "../../../utilities/client/date-format";
import styles from "./index.module.scss";
import getProblemTagStyle from "../../../styles/getProblemTagStyle";
import RoleBasedRender from "../../RoleBasedRender";
import { isRangerOrHigher } from "../../../utilities/client/roles.util";

export const getStationInformationIssuesTableConfigMobile = (
	deleteIssue: (issueId: string) => void,
	trackIssue: (issue: Issue) => void,
	userRoles: UserRole[]
): ColumnsType<Issue> => [
	{
		key: "problem",
		title: (
			<span className={styles["issue-table__column-header"]}>Problem</span>
		),
		dataIndex: "problem",
		render: (problem: string) => {
			return (
				<Tag style={getProblemTagStyle(problem)}>
					<span>{problem}</span>
				</Tag>
			);
		},
	},
	{
		key: "createdAt",
		title: <span className={styles["issue-table__column-header"]}>Datum</span>,
		dataIndex: "createdAt",
		width: 80,
		render: (reportedAtDate: string) => {
			return humanizedDateTimeFormat(new Date(reportedAtDate));
		},
	},
	{
		key: "actions",
		width: 40,
		render: (_, issue) => {
			let items: MenuProps["items"] = [
				{
					key: "nachverfolgen",
					label: (
						<span
							className={
								issue.isTracked
									? styles["issue-table__dropdown__content--tracked"]
									: styles["issue-table__dropdown__content"]
							}
						>
							Nachverfolgen
						</span>
					),
					icon: (
						<BookOutlined
							className={styles["issue-table__dropdown__content"]}
							style={issue.isTracked ? { color: "#4079E3" } : {}}
							twoToneColor="#eb2f96"
							title="Nachverfolgen"
						/>
					),
					onClick: (e) => {
						e.domEvent.stopPropagation();
						trackIssue(issue);
					},
				},
				{
					type: "divider",
				},
				{
					key: "löschen",
					label: (
						<span className={styles["issue-table__dropdown__content"]}>
							Löschen
						</span>
					),
					icon: (
						<DeleteOutlined
							className={styles["issue-table__dropdown__content"]}
							title="Löschen"
						/>
					),
					onClick: (e) => {
						e.domEvent.stopPropagation();
						deleteIssue(issue.id);
					},
				},
			];

			if (!isRangerOrHigher(userRoles)) {
				items = items.filter(
					(item) => item && item.key !== "löschen" && item.type !== "divider"
				);
			}

			return (
				<Dropdown menu={{ items }} trigger={["click"]}>
					<Flex
						align="center"
						justify="center"
						className={styles["issue-table__dropdown__container"]}
						role="button"
						title="Aktionen anzeigen"
						onClick={(e) => e.stopPropagation()}
					>
						<MoreOutlined className={styles["issue-table__dropdown__icon"]} />
					</Flex>
				</Dropdown>
			);
		},
		align: "center",
	},
];

export const getStationInformationIssuesTableConfigDesktop = (
	deleteIssue: (issueId: string) => void,
	trackIssue: (issue: Issue) => void
): ColumnsType<Issue> => [
	{
		key: "problem",
		title: (
			<span className={styles["issue-table__column-header"]}>Problem</span>
		),
		dataIndex: "problem",
		width: 130,
		render: (problem: string) => {
			return (
				<Tag style={getProblemTagStyle(problem)}>
					<span>{problem}</span>
				</Tag>
			);
		},
	},
	{
		key: "description",
		title: (
			<span className={styles["issue-table__column-header"]}>Beschreibung</span>
		),
		dataIndex: "description",
		render: (description: string) => {
			return (
				<span className={styles["issue-table__description"]}>
					{description}
				</span>
			);
		},
	},
	{
		key: "createdAt",
		title: <span className={styles["issue-table__column-header"]}>Datum</span>,
		dataIndex: "createdAt",
		width: 170,
		render: (reportedAtDate: string) => {
			return humanizedDateTimeFormat(new Date(reportedAtDate));
		},
	},
	{
		key: "actions",
		title: (
			<span className={styles["issue-table__column-header"]}>Aktionen</span>
		),
		width: 130,
		render: (_, issue) => {
			return (
				<>
					<BookOutlined
						className={styles["issue-table__action"]}
						style={issue.isTracked ? { color: "#4079E3" } : {}}
						twoToneColor="#eb2f96"
						title="Nachverfolgen"
						onClick={() => {
							trackIssue(issue);
						}}
					/>
					<RoleBasedRender allowedRoles={[UserRole.Admin, UserRole.Ranger]}>
						<DeleteOutlined
							className={styles["issue-table__action"]}
							title="Löschen"
							onClick={() => deleteIssue(issue.id)}
						/>
					</RoleBasedRender>
				</>
			);
		},
		align: "center",
	},
];
