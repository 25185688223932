import React, { ReactElement } from "react";
import { Box, Image, LoadingOverlay } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { Photo } from "./utils/photos-reducer";

type ImagePreviewProps = {
	photo: Photo;
	onDelete?: () => void;
};

function ImagePreview({
	photo: { isProcessing, imageUrl },
	onDelete,
}: ImagePreviewProps): ReactElement {
	const loaderProps =
		onDelete === undefined
			? undefined
			: {
					children: (
						<IconX
							style={{
								color: "var(--mantine-color-white)",
								height: 52,
								width: 52,
							}}
							stroke={1.5}
						/>
					),
				};

	return (
		<Box pos="relative" mah={100} w={100}>
			{imageUrl && <Image src={imageUrl} w={100} h={100} fit="cover" />}
			<LoadingOverlay
				visible
				overlayProps={{
					radius: "xs",
					blur: 1,
					color: "black",
					backgroundOpacity: 0.15,
				}}
				loaderProps={isProcessing ? undefined : loaderProps}
				onClick={isProcessing ? undefined : onDelete}
			/>
		</Box>
	);
}

export default ImagePreview;
