import { Reducer } from "react";

export type Photo = {
	isProcessing: boolean;
	id: string;
	file?: File;
	imageUrl?: ReturnType<typeof URL.createObjectURL>;
};

export enum PhotoUpdateActionType {
	Add = "add-photos",
	UpdateDashboardPhoto = "update-dashboard-photo",
	UploadComplete = "upload-complete",
	Delete = "delete",
	RemoveFailedUpload = "remove-failed-uploads",
}

type PhotosAddAction = {
	type: PhotoUpdateActionType.Add;
	files: File[];
};

type PhotoDeleteAction = {
	type: PhotoUpdateActionType.Delete;
	id: string;
};

type PhotoUploadCompleteAction = {
	type: PhotoUpdateActionType.UploadComplete;
	file: File;
	uploadedId: string;
};

type PhotoRemoveFailedUploadAction = {
	type: PhotoUpdateActionType.RemoveFailedUpload;
	file: File;
};

type PhotoUpdateDashboardPhotoAction = {
	type: PhotoUpdateActionType.UpdateDashboardPhoto;
	id: string;
	data: string;
};

type PhotoUpdateAction =
	| PhotosAddAction
	| PhotoDeleteAction
	| PhotoUploadCompleteAction
	| PhotoRemoveFailedUploadAction
	| PhotoUpdateDashboardPhotoAction;

export type PhotosReducer = Reducer<Photo[], PhotoUpdateAction>;

export const photosReducer = (
	state: Photo[],
	action: PhotoUpdateAction
): Photo[] => {
	if (action.type === PhotoUpdateActionType.Add) {
		return state.concat(
			action.files.map(
				(file): Photo => ({
					file,
					imageUrl: URL.createObjectURL(file),
					isProcessing: true,
					id: crypto.randomUUID(),
				})
			)
		);
	}

	if (action.type === PhotoUpdateActionType.Delete) {
		const imageUrl = state.find(({ id }) => id === action.id)?.imageUrl;
		if (imageUrl) {
			URL.revokeObjectURL(imageUrl);
		}
		return state.filter(({ id }) => id !== action.id);
	}

	if (action.type === PhotoUpdateActionType.RemoveFailedUpload) {
		return state.filter(({ file }) => file !== action.file);
	}

	if (action.type === PhotoUpdateActionType.UpdateDashboardPhoto) {
		const file = new File(
			[
				Uint8Array.from(
					atob(action.data)
						.split("")
						.map((char) => char.charCodeAt(0))
				),
			],
			action.id
		);

		return state.map((photo) =>
			photo.id === action.id
				? {
						id: photo.id,
						isProcessing: false,
						file,
						imageUrl: URL.createObjectURL(file),
					}
				: photo
		);
	}

	// action.type === PhotoUpdateActionTypes.UploadComplete
	return state.map((photo) =>
		photo.file === action.file
			? {
					...photo,
					id: action.uploadedId,
					isProcessing: false,
				}
			: photo
	);
};
