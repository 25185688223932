import { UserRole } from "../../api/jelbi-dashboard-api";
import useGetAccessToken from "./useGetAccessToken";

const useGetUserRoles = (): UserRole[] => {
	const accessToken = useGetAccessToken();
	if (accessToken) {
		const tokenPayload = JSON.parse(atob(accessToken.split(".")[1]));
		const userRoles = tokenPayload.roles;
		return userRoles;
	}

	return [];
};

export default useGetUserRoles;
