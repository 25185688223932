import React, { ReactElement, useState, useEffect, createRef } from "react";
import {
	MapContainer,
	TileLayer,
	useMapEvents,
	ZoomControl,
} from "react-leaflet";
import { useQuery } from "@tanstack/react-query";
import { ok } from "@oazapfts/runtime";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import L, { latLngBounds, Map } from "leaflet";
import Draggable, { DraggableEventHandler } from "react-draggable";
import { useNavigate, useParams } from "react-router-dom";
import VectorTileLayer from "react-leaflet-vector-tile-layer";
import MarkerClusterGroup from "react-leaflet-cluster";
import { readLocalStorageValue, useLocalStorage } from "@mantine/hooks";
import stationService from "../../services/stationService";
import StationInformation from "../StationInformation/StationInformation";
import ActiveStationMarkers from "./ActiveStationMarkers";
import {
	getIssues,
	Station,
	StationState,
} from "../../utilities/api/jelbi-dashboard-api";
import {
	getStationQueryKey,
	QUERY_KEY_ALL_ISSUES,
} from "../../utilities/client/query-keys";
import {
	MAP_NORTH_WEST_BOUND,
	MAP_SOUTH_EAST_BOUND,
} from "../../utilities/client/map";
import styles from "./StationCollectionMap.module.scss";
import useIsMobileView from "../../utilities/client/hooks/useIsMobileView";
import MicromobilityMailArea from "./MicromobilityMailArea/MicromobilityMailArea";
import NoParkingZones from "./IllegalAreas/NoParkingZones";
import OtherMicromobilityAreaMarkers from "./IllegalAreas/OtherMicromobilityAreaMarkers/OtherMicromobilityAreaMarkers";
import CreateStationClusterMarkerIcon from "./CreateStationClusterMarkerIcon";
import MapButtons from "./MapButtons/MapButtons";
import useNoParkingZones from "../../utilities/client/hooks/useNoParkingZones";
import useOtherMicromobilityAreas from "../../utilities/client/hooks/useOtherMicromobilityAreas";
import {
	MapFilterOptions,
	defaultMapFilters,
	getFilteredStations,
	getStationTextSearchResult,
} from "./MapButtons/Buttons/FilterButton/MapFilterDialog/helper";
import useStations from "../../utilities/client/hooks/useStations";
import { MapSettingsOptions } from "./MapButtons/Buttons/SettingsButton";
import {
	LOCAL_STORAGE_KEY_MAP_FILTERS,
	LOCAL_STORAGE_KEY_MAP_SETTINGS,
	LOCAL_STORAGE_KEY_MAP_ZOOM,
} from "../../utilities/client/local-storage-keys";

const DEFAULT_ZOOM = 12;
const MIN_ZOOM = 11;
const MAX_ZOOM = 20;
const ZOOM_LEVEL_TO_SHOW_STATION_GEOFENCE = 18;
const ZOOM_LEVEL_AFTER_STATION_SEARCH = 19;
const COORDINATES_BRANDENBURG_GATE: [number, number] = [52.516275, 13.377704];

function StationCollectionMap(): ReactElement {
	const [selectedStation, setSelectedStation] = useState<Station>();
	const [selectedStationCoordinates, setSelectedCoordinates] = useState<
		[number, number]
	>(COORDINATES_BRANDENBURG_GATE);
	const [showStationInformation, setShowStationInformation] =
		useState<boolean>(false);
	const [currentZoomLevel, setCurrentZoomLevel] = useLocalStorage<number>({
		key: LOCAL_STORAGE_KEY_MAP_ZOOM,
		defaultValue: DEFAULT_ZOOM,
	});
	const [mapVisible, setMapVisible] = useState<boolean>(true);

	const [mapRef, setMapRef] = useState<Map>();
	const mapWrapperRef = createRef<HTMLDivElement>();
	const detailsRef = createRef<HTMLDivElement>();

	const [displayedStations, setDisplayedStations] = useState<Station[]>([]);

	const otherMicromobilityAreas = useOtherMicromobilityAreas();
	const noParkingZones = useNoParkingZones();

	const controlledPosition = { x: 0, y: 0 };
	const bodyHeight = document.body.getBoundingClientRect().height;
	const mobileHeaderHeight = 72;
	const fullMobileMapHeight = bodyHeight - mobileHeaderHeight;
	const mapSizePercentage = 0.25; // 25vh
	const dragMapBreakpoint = mapSizePercentage + 0.1; // map + 10vh
	const animationEaseLinearity = 1; // 1.0 means a linear animation
	const stationDetailsOpeningAnimationDuration = Number(
		styles.export_station_details_animation_open_duration.slice(0, -2)
	);
	const isCurrentViewMobile = useIsMobileView();
	const navigate = useNavigate();
	const { stationId: stationIdInURL } = useParams();

	const { PUBLIC_URL } = process.env;

	const [isSplitView, setIsSplitView] = useState<boolean>(true);

	const [mapSettings, setMapSettings] = useLocalStorage<MapSettingsOptions>({
		key: LOCAL_STORAGE_KEY_MAP_SETTINGS,
		defaultValue: {
			shouldShowNoParkingZones: false,
			shouldShowOtherMicromobilityAreas: false,
		},
	});

	const [mapFilters, setMapFilters] = useLocalStorage<MapFilterOptions>({
		key: LOCAL_STORAGE_KEY_MAP_FILTERS,
		defaultValue: defaultMapFilters,
	});

	// show station geofences when zoomed in
	mapRef?.on("zoomend", () => {
		setCurrentZoomLevel(mapRef?.getZoom());
	});

	useEffect(() => {
		if (detailsRef.current && mapWrapperRef.current) {
			if (showStationInformation && selectedStation) {
				// station is clicked
				detailsRef.current.className = `${styles.details} ${styles["details--open"]}`;
				// mobile view
				if (isCurrentViewMobile) {
					mapWrapperRef.current.className = `${styles["map-wrapper"]} ${styles["map-wrapper--shrink"]}`;
				} else {
					mapWrapperRef.current.classList.remove(
						`${styles["map-wrapper--shrink"]}`
					);
				}
			} else {
				// stationinformation is closed
				detailsRef.current.className = `${styles.details} ${styles["details--close"]}`;
				// mobile view
				if (isCurrentViewMobile) {
					mapWrapperRef.current.className = `${styles["map-wrapper"]} ${styles["map-wrapper--transition"]}`;
					mapWrapperRef.current.setAttribute(
						"style",
						`height: ${fullMobileMapHeight}px`
					);
				} else {
					mapWrapperRef.current.classList.remove(
						`${styles["map-wrapper--transition"]}`
					);
				}
			}
		}

		// invalidateSize after animation is done in other case the wrong width will be taken
		const openStationInformationAnimation = setInterval(() => {
			mapRef?.invalidateSize({ pan: false });
			mapRef?.panTo(
				[selectedStationCoordinates[0], selectedStationCoordinates[1]],
				{ easeLinearity: animationEaseLinearity, duration: 0.2 }
			);
		}, 40);
		setTimeout(
			() => clearInterval(openStationInformationAnimation),
			stationDetailsOpeningAnimationDuration + 100
		);
	}, [showStationInformation, isCurrentViewMobile]);

	const moveMapToStation = (
		station: Station,
		zoomLevel: number = ZOOM_LEVEL_AFTER_STATION_SEARCH,
		duration = 0.35
	) => {
		mapRef?.flyTo(
			[station.geolocation.coordinates[1], station.geolocation.coordinates[0]],
			zoomLevel,
			{ easeLinearity: animationEaseLinearity, duration }
		);
		setSelectedCoordinates([
			station.geolocation.coordinates[1],
			station.geolocation.coordinates[0],
		]);
	};

	const moveMapToRoute = (filteredStations: Station[]) => {
		const stationCoordinates = filteredStations.map((stations) =>
			L.latLng(
				stations.geolocation.coordinates[1],
				stations.geolocation.coordinates[0]
			)
		);

		if (stationCoordinates.length > 0) {
			const bounds = L.latLngBounds(stationCoordinates);
			mapRef?.flyToBounds(bounds, {
				duration: 1.35,
				easeLinearity: animationEaseLinearity,
			});
		}
	};

	const markerClickHandler = (station: Station) => {
		setSelectedStation(station);
		navigate(`/stations/${station.id}`);
		setSelectedCoordinates([
			station.geolocation.coordinates[1],
			station.geolocation.coordinates[0],
		]);
		if (!showStationInformation) {
			setShowStationInformation(true);
		}
		// flyTo just when the Station Information is shown
		if (showStationInformation) {
			moveMapToStation(station, mapRef?.getZoom(), 0.35);
		}
	};

	const { isSuccess: isStationsSuccess, data: stationsData } = useStations();

	// TODO: Add error handling
	const stations =
		isStationsSuccess && stationsData
			? stationsData.filter(
					(station) => station.state !== StationState.Inactive
				)
			: [];

	const { isSuccess: isIssuesSuccess, data: issuesData } = useQuery({
		queryKey: [QUERY_KEY_ALL_ISSUES],
		queryFn: () => ok(getIssues()),
		refetchInterval: 3600000, // one hour in milliseconds
	});

	useEffect(() => {
		if (stationsData) {
			const activeStations = stationsData.filter(
				(station) => station.state !== StationState.Inactive
			);
			const filteredStations = getFilteredStations(
				activeStations,
				isIssuesSuccess ? issuesData.results : [],
				mapFilters
			);
			setDisplayedStations(filteredStations);
		}
	}, [stationsData]);

	useEffect(() => {
		const filteredStations = getFilteredStations(
			stations,
			isIssuesSuccess ? issuesData.results : [],
			mapFilters
		);

		setDisplayedStations(filteredStations);

		if (mapFilters.route !== "") {
			moveMapToRoute(filteredStations);
		}
	}, [mapFilters]);

	const setStationSearch = (stationId: string): void => {
		if (!stationId) {
			return;
		}
		const searchedStation = getStationTextSearchResult(stationId, stations);
		if (searchedStation) {
			moveMapToStation(searchedStation, ZOOM_LEVEL_AFTER_STATION_SEARCH, 1.8);
		}
	};
	// eslint-disable-next-line react/no-unstable-nested-components
	function MapEventHandler() {
		useMapEvents({
			click() {
				if (showStationInformation) {
					navigate("/stations/");
				}
				setShowStationInformation(false);
			},
		});
		return (
			<>
				<MarkerClusterGroup
					maxClusterRadius={50}
					showCoverageOnHover={false}
					iconCreateFunction={(cluster) =>
						CreateStationClusterMarkerIcon({
							cluster,
							showStationInformation: false,
							selectedStationId: selectedStation?.id,
						})
					}
				>
					<ActiveStationMarkers
						stations={displayedStations}
						setSelectedStation={markerClickHandler}
						showStationGeofence={
							currentZoomLevel >= ZOOM_LEVEL_TO_SHOW_STATION_GEOFENCE
						}
						selectedStation={selectedStation}
						showStationInformation={showStationInformation}
					/>
				</MarkerClusterGroup>

				{mapSettings.shouldShowOtherMicromobilityAreas && (
					<MarkerClusterGroup
						maxClusterRadius={50}
						showCoverageOnHover={false}
						disableClusteringAtZoom={17}
						iconCreateFunction={(cluster) => {
							return L.divIcon({
								html: `<div>${cluster.getChildCount()}</div>`,
								className: styles["other-micromobility-area-cluster"],
								iconSize: L.point(28, 28, true),
							});
						}}
					>
						<OtherMicromobilityAreaMarkers
							zoomLevel={currentZoomLevel}
							micromobilityAreas={otherMicromobilityAreas || []}
						/>
					</MarkerClusterGroup>
				)}
			</>
		);
	}

	const draggableOnStart: DraggableEventHandler = (e, ui) => {
		ui.node.setAttribute("style", `height: ${fullMobileMapHeight}px`);
	};

	const draggableOnDrag: DraggableEventHandler = (e, ui) => {
		const newMapHeight = ui.node.getBoundingClientRect().y - mobileHeaderHeight;
		if (newMapHeight >= 0) {
			if (mapWrapperRef.current) {
				mapWrapperRef.current.setAttribute(
					"style",
					`height: ${newMapHeight}px`
				);
				mapWrapperRef.current.className = `${styles["map-wrapper"]} ${styles["map-wrapper--small"]}`;
				mapRef?.invalidateSize();
			}
			setTimeout(() => {
				ui.node.setAttribute(
					"style",
					`${ui.node.getAttribute("style")}; position: absolute; top: ${
						ui.node.getBoundingClientRect().y - ui.y
					}px; height: 100vh`
				);
			}, 0);
		}
	};

	const draggableOnStop: DraggableEventHandler = (e, ui) => {
		const calculateStationInformationHeight = () => {
			if (detailsRef.current && mapWrapperRef.current) {
				detailsRef.current.className = `${styles.details} ${styles["details--open"]}`;
				detailsRef.current.setAttribute(
					"style",
					`height: ${
						fullMobileMapHeight -
						mapWrapperRef.current.getBoundingClientRect().height
					}px;`
				);
			}
		};

		const setMapHeightAndFollowUp = (
			newHeight: number | string,
			stationInformation: boolean
		): void => {
			mapWrapperRef.current?.setAttribute("style", `height: ${newHeight}px`);
			const mapResizeInterval = setInterval(() => mapRef?.invalidateSize(), 10);
			setTimeout(() => {
				clearInterval(mapResizeInterval);
				setShowStationInformation(stationInformation);
			}, stationDetailsOpeningAnimationDuration);

			if (newHeight === 0) {
				setIsSplitView(false);
			} else {
				setIsSplitView(true);
			}
		};

		const splitState = (): void => {
			setTimeout(() => {
				if (detailsRef.current) {
					detailsRef.current.className = `${styles.details} ${styles["details--open"]}`;
					// Dragging beyond small map size
					if (ui.y > bodyHeight * mapSizePercentage) {
						ui.node.setAttribute("style", "");
						// Dragging within small map size
					} else {
						setTimeout(() => {
							ui.node.setAttribute("style", "");
						}, stationDetailsOpeningAnimationDuration);
					}
				}
				setMapHeightAndFollowUp("", true);
				setMapVisible(true);
			}, 0);
		};

		const noMapState = (): void => {
			setTimeout(() => {
				if (detailsRef.current) {
					detailsRef.current.className = `${styles.details} ${styles["details--open"]} ${styles["details--nomap"]}`;
					detailsRef.current.setAttribute("style", "");
				}
				setMapHeightAndFollowUp(0, true);
				setMapVisible(false);
			}, 0);
		};

		const fullMapState = (): void => {
			setTimeout(() => {
				if (detailsRef.current) {
					detailsRef.current.className = `${styles.details} ${styles["details--open"]}`;
					detailsRef.current.setAttribute("style", "");
				}
				setMapHeightAndFollowUp(fullMobileMapHeight, false);
				setMapVisible(true);
			}, 0);
		};

		if (mapWrapperRef.current?.className) {
			mapWrapperRef.current.setAttribute(
				"style",
				`height: ${ui.node.getBoundingClientRect().y - mobileHeaderHeight}px`
			);
			mapWrapperRef.current.className = `${styles["map-wrapper"]} ${styles["map-wrapper--small"]} ${styles["map-wrapper--transition"]}`;
		}

		calculateStationInformationHeight();

		if (mapVisible) {
			if (ui.y <= 0) {
				noMapState();
			} else {
				fullMapState();
			}
		} else if (ui.y < bodyHeight * dragMapBreakpoint) {
			splitState();
		} else {
			fullMapState();
		}
	};

	const { data: station } = useQuery({
		queryKey: getStationQueryKey(stationIdInURL || ""),
		queryFn: async () => {
			return stationService.getStationById(stationIdInURL || "");
		},
		// The query will not execute until routeMatch.params.stationId && !selectedStation evaluates to true
		enabled: !!(stationIdInURL && !selectedStation),
	});

	useEffect(() => {
		if (station && station.state !== StationState.Inactive) {
			setSelectedStation(station);
			setSelectedCoordinates([
				station.geolocation.coordinates[1],
				station.geolocation.coordinates[0],
			]);
			if (!showStationInformation) {
				setShowStationInformation(true);
			}
			// flyTo just when the Station Information is shown
			if (showStationInformation) {
				moveMapToStation(station, mapRef?.getZoom(), 0.35);
			}
		} else {
			setShowStationInformation(false);
		}
	}, [station]);

	// currentZoomLevel has on the initial load the default value with readLocalStorageValue the local storage value is taken
	const zoomLevel: number = stationIdInURL
		? readLocalStorageValue({ key: LOCAL_STORAGE_KEY_MAP_ZOOM })
		: DEFAULT_ZOOM;

	return (
		<div className={styles.layout}>
			<div className={`${styles["map-wrapper"]}`} ref={mapWrapperRef}>
				<MapButtons
					callbackFunctions={{
						setMapFilters,
						setStationSearch,
						setMapSettings,
					}}
					mapSettings={mapSettings}
					mapFilters={mapFilters}
				/>
				<MapContainer
					dragging
					center={selectedStationCoordinates}
					zoom={zoomLevel}
					minZoom={MIN_ZOOM}
					maxZoom={MAX_ZOOM}
					className={styles.map}
					zoomControl={false}
					maxBounds={latLngBounds(MAP_NORTH_WEST_BOUND, MAP_SOUTH_EAST_BOUND)}
					ref={(map) => setMapRef(map !== null ? map : undefined)}
				>
					<ZoomControl position="topright" />
					<TileLayer
						attribution='Daten von <a href="http://osm.org/copyright">OpenStreetMap</a> - Veröffentlicht unter ODbL'
						url="https://{s}-tiles.bvg.de/data/positron/{z}/{x}/{y}.pbf"
					/>
					<VectorTileLayer styleUrl={`${PUBLIC_URL}/mapStyle/style.json`} />

					<MapEventHandler />
					<MicromobilityMailArea zoomLevel={currentZoomLevel} />
					{mapSettings.shouldShowNoParkingZones && (
						<NoParkingZones zones={noParkingZones || []} />
					)}
				</MapContainer>
			</div>

			<Draggable
				disabled={!isCurrentViewMobile}
				axis="y"
				handle=".dragHandle"
				onStart={draggableOnStart}
				onDrag={draggableOnDrag}
				onStop={draggableOnStop}
				position={controlledPosition}
			>
				<div
					ref={detailsRef}
					className={`${styles.details} ${
						isCurrentViewMobile && styles["details--open"]
					}`}
				>
					<Button
						type="link"
						className={styles["details__close-button"]}
						onClick={() => setShowStationInformation(false)}
						title="Stationsinformationen schließen"
					>
						<CloseOutlined onClick={() => navigate("/stations/")} />
					</Button>
					{selectedStation && (
						<StationInformation
							stationId={selectedStation.id}
							isSplitView={isSplitView}
						/>
					)}
				</div>
			</Draggable>
		</div>
	);
}

export default StationCollectionMap;
