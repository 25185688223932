import React from "react";
import { Link } from "react-router-dom";
import { UserRole } from "../../../utilities/api/jelbi-dashboard-api";
import RoleBasedRender from "../../RoleBasedRender";
import SettingsButton, { MapSettingsOptions } from "./Buttons/SettingsButton";
import FilterButton from "./Buttons/FilterButton/FilterButton";
import styles from "./MapButtons.module.scss";
import { MapFilterOptions } from "./Buttons/FilterButton/MapFilterDialog/helper";

import AddStationButton from "./Buttons/AddStationButton";

type MapButtonsProps = {
	mapSettings: MapSettingsOptions;
	mapFilters: MapFilterOptions;
	callbackFunctions: {
		setMapFilters: (filterOptions: MapFilterOptions) => void;
		setStationSearch: (stationId: string) => void;
		setMapSettings: React.Dispatch<React.SetStateAction<MapSettingsOptions>>;
	};
};

function MapButtons({
	mapSettings,
	mapFilters,
	callbackFunctions,
}: MapButtonsProps): JSX.Element {
	const { setMapFilters, setStationSearch, setMapSettings } = callbackFunctions;

	return (
		<div>
			<RoleBasedRender allowedRoles={[UserRole.Admin]}>
				<Link to="/stations/add">
					<div className={styles["add-station-button"]}>
						<AddStationButton />
					</div>
				</Link>
			</RoleBasedRender>
			<div className={styles["filter-button"]}>
				<FilterButton
					callbackFunctions={{
						setMapFilters,
						setStationSearch,
					}}
					mapFilters={mapFilters}
				/>
			</div>
			<div className={styles["settings-button"]}>
				<SettingsButton
					mapSettings={mapSettings}
					setMapSettings={setMapSettings}
				/>
			</div>
		</div>
	);
}

export default MapButtons;
