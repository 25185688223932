import { StationState } from "../api/jelbi-dashboard-api";

// refers to StationState in utilities/api/jelbi-dashboard-api.ts
enum StationStateLabel {
	Active = "Aktiv",
	OpenSoon = "Eröffnet demnächst",
	TemporarilyClosed = "Vorübergehend gesperrt (z.B. Bauarbeiten)",
	Inactive = "Geschlossen",
}

const stationStateOptions = (includeInactive = true) => {
	return (Object.keys(StationState) as (keyof typeof StationState)[])
		.filter(
			(stationState) =>
				includeInactive || (!includeInactive && stationState !== "Inactive")
		)
		.map((stationState) => {
			return {
				key: StationState[stationState],
				label: StationStateLabel[stationState],
				value: StationState[stationState],
			};
		});
};

export default stationStateOptions;
