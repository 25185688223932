enum Problem {
	Vandalism = "Beschädigung",
	Defect = "Defekt",
	Safety = "Verkehrssicherheit",
	Dirt = "Verschmutzung",
	Misc = "Sonstiges",
}

const problemOptions = (Object.keys(Problem) as (keyof typeof Problem)[]).map(
	(item) => {
		return {
			key: Problem[item],
			label: Problem[item],
			value: Problem[item],
		};
	}
);

export default problemOptions;
