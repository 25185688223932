/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum StationFormValidationErrorMessages {
	SHORT_NAME_MISSING = "Bitte füge eine Kurzbezeichnung hinzu.",
	SHORT_NAME_EXISTING = "Diese Kurzbezeichnung existiert bereits für eine Station.",
	NAME_MISSING = "Bitte füge einen Namen hinzu.",
	ADDRESS_MISSING = "Bitte füge eine Adresse hinzu.",
	DISTRICT_MISSING = "Bitte wähle einen Bezirk aus.",
	STATE_MISSING = "Bitte wähle den Status der Station aus.",
	GEO_LOCATION_MISSING = "Bitte füge den Standpunkt der Station als Geokoordinate hinzu.",
	GEO_LOCATION_INVALID_FORMAT = "Bitte gib ein gültiges Format an.",
	GEO_LOCATION_OUTSIDE_BOUNDARY = "Bitte gib eine Koordinate innerhalb von Berlin an.",
	GEO_FENCE_MISSING = "Bitte füge eine geographische Begrenzung der Station hinzu.",
	GEO_FENCE_INVALID_FORMAT = "Bitte gib ein gültiges Format an.",
	GEO_FENCE_MIN_LOCATIONS = "Bitte gib mindestens 4 geographische Standpunkte an.",
	GEO_FENCE_START_END_POINTS_INVALID = "Der erste und letzte geographische Standpunkt sind nicht identisch.",
	PLANNED_CLOSURES_MISSING = "Füge mindestens eine geplante Sperrung hinzu.",
	PLANNED_CLOSURES_OVERLAPPING = "Zeiträume dürfen sich nicht überlappen.",
	PLANNED_CLOSURE_SAME_START_AND_END = "Startdatum und Enddatum dürfen nicht gleich sein.",
	PLANNED_CLOSURE_END_BEFORE_START = "Startdatum darf nicht nach dem Enddatum liegen.",
	PLANNED_CLOSURE_START_MISSING = "Startdatum muss gesetzt sein.",
	PLANNED_CLOSURE_END_MISSING = "Enddatum muss gesetzt sein.",
}

export default StationFormValidationErrorMessages;
