import React from "react";
import { notifications } from "@mantine/notifications";
import { IconExclamationCircleFilled } from "@tabler/icons-react";

export const showUploadFailedNotification = (filename: string) => {
	notifications.show({
		id: "failPhotoUpload",
		position: "top-center",
		message: `Fotoupload für ${filename} fehlgeschlagen`,
		color: "red",
		icon: <IconExclamationCircleFilled />,
	});
};

export const showTooManyAdditionalPhotosNotification = (leftCount: number) => {
	notifications.show({
		id: "tooManyAdditionalPhotos",
		position: "top-center",
		message: `Bitte maximal ${leftCount} ${leftCount === 1 ? "weiteres Bild" : "weitere Bilder"} hochladen.`,
		color: "red",
		icon: <IconExclamationCircleFilled />,
	});
};

export const showTooManyPhotosNotification = (maxCount: number) => {
	notifications.show({
		id: "tooManyPhotos",
		position: "top-center",
		message: `Bitte maximal ${maxCount} Foto${maxCount === 1 ? "" : "s"} hochladen.`,
		color: "red",
		icon: <IconExclamationCircleFilled />,
	});
};
