/**
 * Jelbi Dashboard API
 * 0.54.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "@oazapfts/runtime";
import * as QS from "@oazapfts/runtime/query";
export const defaults: Oazapfts.Defaults<Oazapfts.CustomHeaders> = {
	headers: {},
	baseUrl: "https://api.bvg.de/jelbi-db",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {
	productionViaApigeeProxy: "https://api.bvg.de/jelbi-db",
	productionApiDirectly: "https://api.jelbi.de",
	testViaApigeeProxy: "https://api-test.bvg.de/jelbi-db",
	testApiDirectly: "https://c-api.jelbi.de",
	devViaApigeeProxy: "https://api-dev.bvg.de/jelbi-db",
	devApiDirectly: "https://api-dev.jelbi.de",
	localWithDocker: "https://api.127.0.0.1.nip.io",
	localWithoutDocker: "http://localhost:3001",
};
export type Id = string;
export type Geolocation = {
	type: GeoJsonPointType;
	coordinates: number[];
};
export type Geofence = {
	type: GeoJsonPolygonType;
	coordinates: number[][][];
};
export type Duration = {
	hours: number;
};
export type Vehicles = {
	/** Currently available vehicles at the station. */
	availability: number;
	/** Capacity for vehicle type at this stations. */
	capacity: number;
	/** This object reflects the expected amount of vehicles of the
    vehicle type at this station over the timeframe from "now"
    backwards defined by _duration_.
     */
	expectation: {
		/** Amount of vehicles available during the defined timeframe.
		 */
		actual: number;
		duration: Duration;
		/** Amount of vehicles expected to be available at the station
        during the defined timeframe.
         */
		expected: number;
	};
	vehicleType: VehicleType;
}[];
export type PlannedClosure = {
	/** Date and time when the planned closure of the station starts.
	 */
	start: string;
	/** Date and time when the planned closure of the station ends.
	 */
	end: string;
	/** Comment to the planned closure. */
	comment: string | null;
};
export type PlannedClosuresObject = {
	/** Flag whether planned closures are applied during the relevant
    period.
     */
	enabled: boolean;
	/** Planned closures for the station. */
	closures?: PlannedClosure[];
};
export type Station = {
	type: StationEntityType;
	id: Id;
	/** Date and time when the station was created. */
	createdAt: string;
	/** Short description (abbreviation). Must be unique. */
	shortName: string;
	/** Long description (full name). */
	name: string;
	/** Full address (street, street number, postal code, city). */
	address: string;
	district: BerlinDistrict;
	/** Specifies station details. */
	description: string | null;
	state: StationState;
	/** Comment to the state of a station. */
	stateComment: string | null;
	/** Specifies if station is a point. */
	isPoint: boolean;
	/** Specifies if station has wifi. */
	hasWifi: boolean;
	/** Specifies if station has a smart bench. */
	hasSmartBench: boolean;
	/** Specifies if station has a car charging station. */
	hasCarChargingStation: boolean;
	geolocation: Geolocation;
	geofence: Geofence;
	vehicles: Vehicles;
	/** A collection of infos regarding issues for the station. */
	issues: {
		/** Count of unread issues for this station. Since the unread count
        is not in use, this feature will be removed from the API in
        Q4/2024. Until then, this field is always `0`.
         */
		unreadCount: number;
		/** Count of issues for this station. */
		count: number;
		/** Last date the station has been visited for inspection.
		 */
		lastInspection: string | null;
	};
	plannedClosures: PlannedClosuresObject;
	/** Determines if MSPs get an email when their available micromobility
    at that station exceeds their assigned quota (prerequisite: the
    station's overall quota has been exceeded).
     */
	isGeneratingEmailsToMsps: boolean;
	/** Name of the route the station belongs to. */
	route: string;
};
export type Problem = {
	type: string;
	/** Give a concise human-readable summary of the problem faced
    that is understandable by technically versed stakeholders.
     */
	title?: string;
	/** The "status" member, if present, is only advisory; it conveys the
    HTTP status code used for the convenience of the consumer.
    Generators MUST use the same status code in the actual HTTP
    response, to assure that generic HTTP software that does not
    understand this format still behaves correctly.
     */
	status?: number;
	/** A specific explanation explaining the occurrence in as
    much detail as necessary to understand the problem fully.
    The "detail" member, if present, ought to focus on helping the
    client correct the problem, rather than giving debugging
    information.
     */
	detail?: string;
	instance?: string;
};
export type VehiclesAdd = {
	/** Capacity for vehicle type at this stations. */
	capacity: number;
	/** This object reflects the expected amount of vehicles of the
    vehicle type at this station over the timeframe from "now"
    backwards defined by _duration_.
     */
	expectation: {
		duration: Duration;
		/** Amount of vehicles expected to be available at the station
        during the defined timeframe.
         */
		expected: number;
	};
	vehicleType: VehicleType;
}[];
export type StationAdd = {
	/** Short description (abbreviation). Must be unique. */
	shortName: string;
	/** Long description (full name). */
	name: string;
	/** Full address (street, street number, postal code, city). */
	address: string;
	district: BerlinDistrict;
	/** Specifies station details. */
	description: string | null;
	state: StationStateAdd;
	/** Comment to the state of a station. */
	stateComment: string | null;
	/** Specifies if station is a point. */
	isPoint: boolean;
	/** Specifies if station has wifi. */
	hasWifi: boolean;
	/** Specifies if station has a smart bench. */
	hasSmartBench: boolean;
	/** Specifies if station has a car charging station. */
	hasCarChargingStation: boolean;
	geolocation: Geolocation;
	geofence: Geofence;
	vehicles: VehiclesAdd;
	plannedClosures: PlannedClosuresObject;
	/** Determines if MSPs get an email when their available micromobility
    at that station exceeds their assigned quota (prerequisite: the
    station's overall quota has been exceeded).
     */
	isGeneratingEmailsToMsps: boolean;
	/** Name of the route the station belongs to. */
	route: string;
};
export type VehiclesEdit = {
	/** Capacity for vehicle type at this stations. */
	capacity?: number;
	/** This object reflects the expected amount of vehicles of the
    vehicle type at this station over the timeframe from "now"
    backwards defined by _duration_.
     */
	expectation?: {
		duration?: Duration;
		/** Amount of vehicles expected to be available at the station
        during the defined timeframe.
         */
		expected?: number;
	};
	vehicleType: VehicleType;
}[];
export type StationEdit = {
	/** Short description (abbreviation). Must be unique. */
	shortName?: string;
	/** Long description (full name). */
	name?: string;
	/** Full address (street, street number, postal code, city). */
	address?: string;
	district?: BerlinDistrict;
	/** Specifies station details. */
	description?: string | null;
	state?: StationState;
	/** Comment to the state of a station. */
	stateComment?: string | null;
	/** Specifies if station is a point. */
	isPoint?: boolean;
	/** Specifies if station has wifi. */
	hasWifi?: boolean;
	/** Specifies if station has a smart bench. */
	hasSmartBench?: boolean;
	/** Specifies if station has a car charging station. */
	hasCarChargingStation?: boolean;
	geolocation?: Geolocation;
	geofence?: Geofence;
	vehicles?: VehiclesEdit;
	plannedClosures?: PlannedClosuresObject;
	/** Determines if MSPs get an email when their available micromobility
    at that station exceeds their assigned quota (prerequisite: the
    station's overall quota has been exceeded).
     */
	isGeneratingEmailsToMsps?: boolean;
	/** Name of the route the station belongs to. */
	route?: string;
};
export type StationPartial = {
	/** Name of the station.
	 */
	name: string;
	id: Id;
};
export type PhotoShallow = {
	type: PhotoEntityType;
	id: Id;
};
export type IssueShallow = {
	type: IssueEntityType;
	id: Id;
};
export type Inspection = {
	type: InspectionEntityType;
	id: Id;
	station: StationPartial;
	createdBy: UserRole;
	/** Date and time when the inspection was created. */
	createdAt: string;
	/** Array of photos for the inspections. At least one photo must be
    attached to an inspection.
     */
	photos: PhotoShallow[];
	/** The issues associated with this inspection. It also contains deleted
    issues for documentation purposes.
     */
	issues: IssueShallow[];
};
export type InspectionCollection = {
	/** Shows count of the total number of inspections. */
	totalCount: number;
	results: Inspection[];
};
export type InspectionIssueAdd = {
	/** Station equipment affected by the issue. */
	equipment: string;
	/** Kind of problem. */
	problem: string;
	/** Description of the issue. */
	description: string;
	/** Array of photos for the issue. */
	photoIds?: Id[];
};
export type InspectionAdd = {
	stationId: Id;
	createdBy: UserRole;
	/** Date and time when the inspection was created. If left empty, it
    will be set by the server to the current time.
     */
	createdAt?: string;
	/** Array of photos for the inspection. At least one photo must be
    attached to an inspection.
     */
	photoIds: Id[];
	/** Array of issues of the inspection. */
	issues?: InspectionIssueAdd[];
};
export type CommentShallow = {
	type: CommentEntityType;
	id: Id;
};
export type Issue = {
	type: IssueEntityType;
	id: Id;
	station: StationPartial;
	createdBy: UserRole;
	/** Date and time when the inspection was created. */
	createdAt: string;
	/** Station equipment for which the issue appears. */
	equipment: string;
	/** Kind of problem. */
	problem: string;
	/** Description of the issue. */
	description: string;
	/** Indicates if issue is being tracked. */
	isTracked: boolean;
	/** Array of photos for the inspections. At least one photo must be
    attached to an inspection.
     */
	photos: PhotoShallow[];
	/** Comments related to the issue. */
	comments: CommentShallow[];
};
export type IssueCollection = {
	/** Shows count of the total number of issues. */
	totalCount: number;
	results: Issue[];
};
export type IssueAdd = InspectionIssueAdd & {
	stationId: Id;
	createdBy: UserRole;
	/** Date and time when the inspection was created. */
	createdAt?: string;
};
export type IssueEdit = {
	/** Indicates if issue is being tracked. */
	isTracked?: boolean;
};
export type Comment = {
	type: CommentEntityType;
	id: Id;
	/** The comment content. */
	content: string;
	/** Date and time when the comment was created. */
	createdAt: string;
};
export type CommentCollection = Comment[];
export type CommentAdd = {
	/** The comment content. */
	content: string;
};
export type PhotoAdd = {
	/** A JPEG image binary data encoded in Base64, in data URL format
	 */
	data: string;
};
export type MultiPolygonGeofence = {
	type: GeoJsonMultiPolygonType;
	coordinates: number[][][][];
};
export type CityMicromobilityArea = {
	/** Description of the station. */
	description: string;
	geolocation: Geolocation;
	multiPolygonGeofence: MultiPolygonGeofence;
};
export type MspAddEdit = {
	/** UI name for MSP */
	name: string;
	/** List of emails for contant purposes. */
	email: string[];
	/** Percentage of allowed micromobility at stations. */
	micromobilityQuotaPercentage: number;
	distributionsMspId: string | null;
	/** Image path for now. */
	imageId: string | null;
};
export type Msp = MspAddEdit & {
	id: Id;
};
export type VehicleStateChange = {
	changeType: VehicleChangeType;
	stationId: Id;
	/** Vehicle identifier */
	vehicleId: string;
	/** Mobility service provider identifier */
	mspId: string;
	vehicleType: VehicleType;
};
export type VehicleState = {
	stationId: Id;
	/** Vehicle identifier */
	vehicleId: string;
	/** Mobility service provider identifier */
	mspId: string;
	vehicleType: VehicleType;
};
export type DistributionAggregationType = (
	| "STATION_ID"
	| "MSP_ID"
	| "VEHICLE_TYPE"
)[];
export type MspId = string;
export type DistributionFilters = {
	/** Start date and time of the whole data set */
	start: string;
	/** End date and time of the whole data set */
	end: string;
	/** Stations filter */
	stationIds?: Id[];
	/** Vehicle types filter */
	vehicleTypes?: VehicleType[];
	/** Mobility Service Providers filter */
	mspIds?: MspId[];
};
export type VehicleAmount = {
	vehicleType?: VehicleType;
	/** Amount of given vehicle type. */
	amount: number;
	mspId?: MspId;
	district?: BerlinDistrict;
	stationId?: Id;
};
export type VehiclesStatistic = {
	/** Start date and time of the data set */
	start: string;
	/** End date and time of the data set */
	end: string;
	vehicleDistributions: VehicleAmount[];
};
export type VehicleDistribution = {
	aggregate: DistributionAggregationType;
	timeSlice?: TimeSlice;
	filters: DistributionFilters;
	results: VehiclesStatistic[];
};
/**
 * Returns a summary for all Jelbi stations
 */
export function getStations(
	{
		issueCountStart,
		issueCountEnd,
	}: {
		issueCountStart?: string;
		issueCountEnd?: string;
	} = {},
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: Station[];
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		`/stations${QS.query(
			QS.explode({
				issueCountStart,
				issueCountEnd,
			})
		)}`,
		{
			...opts,
		}
	);
}
/**
 * Add a Jelbi station
 */
export function postStation(
	stationAdd: StationAdd,
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 201;
				data: Station;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		"/stations",
		oazapfts.json({
			...opts,
			method: "POST",
			body: stationAdd,
		})
	);
}
/**
 * Returns information from a single Jelbi station
 */
export function getStation(
	stationId: string,
	{
		issueCountStart,
		issueCountEnd,
	}: {
		issueCountStart?: string;
		issueCountEnd?: string;
	} = {},
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: Station;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		`/stations/${encodeURIComponent(stationId)}${QS.query(
			QS.explode({
				issueCountStart,
				issueCountEnd,
			})
		)}`,
		{
			...opts,
		}
	);
}
/**
 * Edit a Jelbi station
 */
export function editStation(
	stationId: string,
	stationEdit: StationEdit,
	{
		issueCountStart,
		issueCountEnd,
	}: {
		issueCountStart?: string;
		issueCountEnd?: string;
	} = {},
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: Station;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 403;
				data: Problem;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		`/stations/${encodeURIComponent(stationId)}${QS.query(
			QS.explode({
				issueCountStart,
				issueCountEnd,
			})
		)}`,
		oazapfts.json({
			...opts,
			method: "PATCH",
			body: stationEdit,
		})
	);
}
/**
 * Get all inspections
 */
export function getInspections(
	{
		page,
		maxItems,
		start,
		end,
	}: {
		page?: number;
		maxItems?: number;
		start?: string;
		end?: string;
	} = {},
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: InspectionCollection;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		`/inspections${QS.query(
			QS.explode({
				page,
				maxItems,
				start,
				end,
			})
		)}`,
		{
			...opts,
		}
	);
}
/**
 * Add an inspection
 */
export function postInspection(
	inspectionAdd: InspectionAdd,
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 201;
				data: Inspection;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		"/inspections",
		oazapfts.json({
			...opts,
			method: "POST",
			body: inspectionAdd,
		})
	);
}
/**
 * Get single inspection
 */
export function getInspection(
	inspectionId: string,
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: Inspection;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(`/inspections/${encodeURIComponent(inspectionId)}`, {
		...opts,
	});
}
/**
 * Mark a single inspection as deleted
 */
export function deleteInspection(
	inspectionId: string,
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 204;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(`/inspections/${encodeURIComponent(inspectionId)}`, {
		...opts,
		method: "DELETE",
	});
}
/**
 * Get all issues
 */
export function getIssues(
	{
		page,
		maxItems,
		start,
		end,
		stationIds,
		isTracked,
		includeDeleted,
	}: {
		page?: number;
		maxItems?: number;
		start?: string;
		end?: string;
		stationIds?: Id[];
		isTracked?: boolean;
		includeDeleted?: boolean;
	} = {},
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: IssueCollection;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		`/issues${QS.query(
			QS.explode({
				page,
				maxItems,
				start,
				end,
				stationIds,
				isTracked,
				includeDeleted,
			})
		)}`,
		{
			...opts,
		}
	);
}
/**
 * Add an issue
 */
export function postIssue(issueAdd: IssueAdd, opts?: Oazapfts.RequestOpts) {
	return oazapfts.fetchJson<
		| {
				status: 201;
				data: Issue;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 403;
				data: Problem;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		"/issues",
		oazapfts.json({
			...opts,
			method: "POST",
			body: issueAdd,
		})
	);
}
/**
 * Get single issue
 */
export function getIssue(
	issueId: string,
	{
		includeDeleted,
	}: {
		includeDeleted?: boolean;
	} = {},
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: Issue;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		`/issues/${encodeURIComponent(issueId)}${QS.query(
			QS.explode({
				includeDeleted,
			})
		)}`,
		{
			...opts,
		}
	);
}
/**
 * Edit an issue
 */
export function patchIssue(
	issueId: string,
	issueEdit: IssueEdit,
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: Issue;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		`/issues/${encodeURIComponent(issueId)}`,
		oazapfts.json({
			...opts,
			method: "PATCH",
			body: issueEdit,
		})
	);
}
/**
 * Mark a single issue as deleted
 */
export function deleteIssue(issueId: string, opts?: Oazapfts.RequestOpts) {
	return oazapfts.fetchJson<
		| {
				status: 204;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(`/issues/${encodeURIComponent(issueId)}`, {
		...opts,
		method: "DELETE",
	});
}
/**
 * Get all comments of an issue
 */
export function getComments(
	issueId: string,
	{
		includeDeleted,
	}: {
		includeDeleted?: boolean;
	} = {},
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: CommentCollection;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		`/issues/${encodeURIComponent(issueId)}/comments${QS.query(
			QS.explode({
				includeDeleted,
			})
		)}`,
		{
			...opts,
		}
	);
}
/**
 * Add a comment to an issue
 */
export function postComment(
	issueId: string,
	commentAdd: CommentAdd,
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 201;
				data: Comment;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		`/issues/${encodeURIComponent(issueId)}/comments`,
		oazapfts.json({
			...opts,
			method: "POST",
			body: commentAdd,
		})
	);
}
/**
 * Add a photo
 */
export function postPhoto(photoAdd: PhotoAdd, opts?: Oazapfts.RequestOpts) {
	return oazapfts.fetchJson<
		| {
				status: 201;
				data: PhotoShallow;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		"/photos",
		oazapfts.json({
			...opts,
			method: "POST",
			body: photoAdd,
		})
	);
}
/**
 * Get a single photo
 */
export function getPhoto(photoId: string, opts?: Oazapfts.RequestOpts) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: string;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(`/photos/${encodeURIComponent(photoId)}`, {
		...opts,
	});
}
/**
 * Get micromobility no parking zones for all districts.
 */
export function getCityMicromobilityNoParking(opts?: Oazapfts.RequestOpts) {
	return oazapfts.fetchJson<{
		status: 200;
		data: MultiPolygonGeofence[];
	}>("/city-micromobility/no-parking-zones", {
		...opts,
	});
}
/**
 * Get micromobility areas for all districts. Excludes areas from Jelbi stations.
 */
export function getCityMicromobilityAreas(opts?: Oazapfts.RequestOpts) {
	return oazapfts.fetchJson<{
		status: 200;
		data: CityMicromobilityArea[];
	}>("/city-micromobility/areas", {
		...opts,
	});
}
/**
 * Get all MSPs
 */
export function getMsps(opts?: Oazapfts.RequestOpts) {
	return oazapfts.fetchJson<{
		status: 200;
		data: Msp[];
	}>("/msps", {
		...opts,
	});
}
/**
 * Add new MSP
 */
export function addMsp(mspAddEdit: MspAddEdit, opts?: Oazapfts.RequestOpts) {
	return oazapfts.fetchJson<
		| {
				status: 201;
				data: Msp;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 422;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		"/msps",
		oazapfts.json({
			...opts,
			method: "PUT",
			body: mspAddEdit,
		})
	);
}
/**
 * Get MSP
 */
export function getMsp(mspId: string, opts?: Oazapfts.RequestOpts) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: Msp;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(`/msps/${encodeURIComponent(mspId)}`, {
		...opts,
	});
}
/**
 * Edit MSP
 */
export function editMsp(
	mspId: string,
	mspAddEdit: MspAddEdit,
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 201;
				data: Msp;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 422;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		`/msps/${encodeURIComponent(mspId)}`,
		oazapfts.json({
			...opts,
			method: "PUT",
			body: mspAddEdit,
		})
	);
}
/**
 * Delete MSP
 */
export function deleteMsp(mspId: string, opts?: Oazapfts.RequestOpts) {
	return oazapfts.fetchJson<
		| {
				status: 204;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 404;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(`/msps/${encodeURIComponent(mspId)}`, {
		...opts,
		method: "DELETE",
	});
}
/**
 * Add vehicle distribution changes
 */
export function postVehicleDistributionChange(
	body: VehicleStateChange[],
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 201;
				data: VehicleState[];
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 422;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		"/distributions",
		oazapfts.json({
			...opts,
			method: "POST",
			body,
		})
	);
}
/**
 * Get current vehicle distribution
 */
export function getVehicleDistributions(
	{
		time,
	}: {
		time?: string;
	} = {},
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: VehicleState[];
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 422;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		`/distributions${QS.query(
			QS.explode({
				time,
			})
		)}`,
		{
			...opts,
		}
	);
}
/**
 * Get vehicle availabilities
 */
export function getAvailabilities(
	{
		aggregateBy,
		timeSlice,
		districts,
		stationIds,
		vehicleTypes,
		mspIds,
		start,
		end,
	}: {
		aggregateBy?: DistributionAggregationType;
		timeSlice?: TimeSlice;
		districts?: BerlinDistrict[];
		stationIds?: Id[];
		vehicleTypes?: VehicleType[];
		mspIds?: MspId[];
		start?: string;
		end?: string;
	} = {},
	opts?: Oazapfts.RequestOpts
) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: VehicleDistribution;
		  }
		| {
				status: 400;
				data: Problem;
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>(
		`/distributions/availabilities${QS.query(
			QS.explode({
				aggregateBy,
				timeSlice,
				districts,
				stationIds,
				vehicleTypes,
				mspIds,
				start,
				end,
			})
		)}`,
		{
			...opts,
		}
	);
}
/**
 * Get unassigned MSP distribution ids
 */
export function getDistributionsUnassignedMsps(opts?: Oazapfts.RequestOpts) {
	return oazapfts.fetchJson<
		| {
				status: 200;
				data: string[];
		  }
		| {
				status: 500;
				data: Problem;
		  }
	>("/distributions/unassigned-msps", {
		...opts,
	});
}
export enum StationEntityType {
	Stations = "/stations",
}
export enum BerlinDistrict {
	Mitte = "MITTE",
	FriedrichshainKreuzberg = "FRIEDRICHSHAIN_KREUZBERG",
	Pankow = "PANKOW",
	CharlottenburgWilmersdorf = "CHARLOTTENBURG_WILMERSDORF",
	Spandau = "SPANDAU",
	SteglitzZehlendorf = "STEGLITZ_ZEHLENDORF",
	TempelhofSchoeneberg = "TEMPELHOF_SCHOENEBERG",
	Neukoelln = "NEUKOELLN",
	TreptowKoepenick = "TREPTOW_KOEPENICK",
	MarzahnHellersdorf = "MARZAHN_HELLERSDORF",
	Lichtenberg = "LICHTENBERG",
	Reinickendorf = "REINICKENDORF",
	Potsdam = "POTSDAM",
	Kleinmachnow = "KLEINMACHNOW",
}
export enum StationState {
	Active = "ACTIVE",
	OpenSoon = "OPEN_SOON",
	TemporarilyClosed = "TEMPORARILY_CLOSED",
	Inactive = "INACTIVE",
}
export enum GeoJsonPointType {
	Point = "Point",
}
export enum GeoJsonPolygonType {
	Polygon = "Polygon",
}
export enum VehicleType {
	Car = "CAR",
	MotorScooter = "MOTOR_SCOOTER",
	KickScooter = "KICK_SCOOTER",
	Bicycle = "BICYCLE",
	Private = "PRIVATE",
	StationBasedCar = "STATION_BASED_CAR",
	CargoBike = "CARGO_BIKE",
}
export enum StationStateAdd {
	Active = "ACTIVE",
	OpenSoon = "OPEN_SOON",
	TemporarilyClosed = "TEMPORARILY_CLOSED",
}
export enum InspectionEntityType {
	Inspections = "/inspections",
}
export enum UserRole {
	Admin = "ADMIN",
	Ranger = "RANGER",
	Member = "MEMBER",
	Support = "SUPPORT",
}
export enum PhotoEntityType {
	Photos = "/photos",
}
export enum IssueEntityType {
	Issues = "/issues",
}
export enum CommentEntityType {
	Comments = "/comments",
}
export enum GeoJsonMultiPolygonType {
	MultiPolygon = "MultiPolygon",
}
export enum VehicleChangeType {
	Disappearance = "DISAPPEARANCE",
	Appearance = "APPEARANCE",
}
export enum TimeSlice {
	Week = "WEEK",
	Hour = "HOUR",
	QuarterHour = "QUARTER_HOUR",
}
