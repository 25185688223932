import React, { ReactElement, useEffect, useState } from "react";
import { Button, Group, Stack, Table } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import { Msp } from "../../utilities/api/jelbi-dashboard-api";
import classes from "./Msps.module.scss";
import useMsps from "../../utilities/client/hooks/useMsps";
import useMaxAvailableMicromobilityQuota from "./MspAddEditForm/useMaxAvailableMicromobilityQuota";

function Msps(): ReactElement {
	const navigate = useNavigate();
	const msps = useMsps();
	const availableMicromobilityQuota = useMaxAvailableMicromobilityQuota();

	const [usedMicromobilityQuota, setUsedMicromobilityQuota] =
		useState<number>(100);

	useEffect(() => {
		setUsedMicromobilityQuota(100 - availableMicromobilityQuota);
	}, [availableMicromobilityQuota]);

	const rows = (msps ?? []).map((msp: Msp) => (
		<Table.Tr key={msp.id} onClick={() => navigate(`${msp.id}/edit`)}>
			<Table.Td>{msp.name}</Table.Td>
			<Table.Td>{msp.email.join(", ")}</Table.Td>
			<Table.Td>
				{
					// The multiplication by 100 is necessary as we store the
					// percentages in fractions of 1 in the backend
					// `Math.trunc` is necessary to prevent rounding errors
					Math.trunc(msp.micromobilityQuotaPercentage * 100)
				}
				%
			</Table.Td>
			<Table.Td>{msp.distributionsMspId}</Table.Td>
		</Table.Tr>
	));

	return (
		<Stack className={classes.stack}>
			<Group justify="flex-end">
				<Link to="add">
					<Button>MSP hinzufügen</Button>
				</Link>
			</Group>
			<Table.ScrollContainer minWidth={550}>
				<Table>
					<Table.Thead>
						<Table.Tr>
							<Table.Th>Name</Table.Th>
							<Table.Th>E-Mail</Table.Th>
							<Table.Th>
								Kontingent Mikromobilität
								<br />({usedMicromobilityQuota}%)
							</Table.Th>
							<Table.Th>Trafi-ID</Table.Th>
						</Table.Tr>
					</Table.Thead>
					<Table.Tbody>{rows}</Table.Tbody>
				</Table>
			</Table.ScrollContainer>
		</Stack>
	);
}

export default Msps;
