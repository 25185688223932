import { useState, useEffect } from "react";
import { ComboboxData } from "@mantine/core";
import { StationState } from "../../api/jelbi-dashboard-api";
import useStations from "./useStations";

const useStationForSelectData = (): ComboboxData => {
	const [stationsForSelectData, setStationsForSelectData] =
		useState<ComboboxData>([]);

	const { data: stations } = useStations();

	useEffect(() => {
		if (stations && stations.length > 0) {
			setStationsForSelectData(
				stations
					.filter((station) => station.state !== StationState.Inactive)
					.map((station) => ({
						value: station.id,
						label: `${station.shortName} ${station.name}`,
					}))
			);
		}
	}, [stations]);

	return stationsForSelectData;
};

export default useStationForSelectData;
