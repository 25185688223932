import { format, isToday, isYesterday } from "date-fns";

export const humanizedDateTimeFormat = (date: Date): string => {
	let datePart = format(date, "dd.MM.yyyy");
	const timePart = format(date, "HH:mm");

	if (isToday(date)) {
		datePart = "Heute";
	} else if (isYesterday(date)) {
		datePart = "Gestern";
	}

	return `${datePart},\n${timePart} Uhr`;
};

export const getWeekdayName = (date: Date): string => {
	const formatToWeekday = new Intl.DateTimeFormat("de", {
		weekday: "long",
	});

	return formatToWeekday.format(date);
};

export const humanizedDateForFilename = (date: Date): string =>
	format(date, "dd-MM-yyyy");

export const humanizedDateTimeLong = (date: Date): string =>
	format(date, "dd.MM.yyyy HH:mm");
