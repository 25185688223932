import React, { ReactElement } from "react";
import { Loader } from "@mantine/core";
import styles from "./index.module.scss";

type SpinnerProps = {
	height: number;
	width: number;
	showBorder?: boolean;
};

function Spinner({
	height,
	width,
	showBorder = false,
}: SpinnerProps): ReactElement {
	return (
		<div
			className={styles["placeholder-spinner"]}
			style={{
				height: `${height}px`,
				width: `${width}px`,
				border: showBorder ? "solid 1px #ccc" : "0",
			}}
		>
			<Loader />
		</div>
	);
}

export default Spinner;
