// disable eslint for using props spreading as it's best practices in https://mantine.dev/form/use-form/
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from "react";
import { GetInputPropsReturnType } from "@mantine/form/lib/types";
import { Select } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import useStationForSelectData from "../../utilities/client/hooks/useStationForSelectData";

type DashboardDateTimePickerProps = {
	formInputProps?: GetInputPropsReturnType;
	label?: string;
	placeholder?: string;
	withAsterisk?: boolean;
	disabled?: boolean;
};

function DashboardSelectStation({
	formInputProps,
	label = "Standort",
	placeholder = "Bitte Station auswählen",
	withAsterisk = false,
	disabled = false,
}: DashboardDateTimePickerProps): ReactElement {
	const stationsSelectData = useStationForSelectData();

	return (
		<Select
			{...formInputProps}
			value={formInputProps?.defaultValue}
			label={label}
			withAsterisk={withAsterisk}
			placeholder={placeholder}
			data={stationsSelectData}
			searchable
			disabled={disabled}
			leftSection={<IconSearch size={16} />}
		/>
	);
}

export default DashboardSelectStation;
