// disable eslint for using props spreading as it's best practices in https://mantine.dev/form/use-form/
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from "react";
import { Text, SimpleGrid, NumberInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import VehicleImage from "../VehicleImage";
import { VEHICLES_TYPES_IN_STATION_FORM_ORDER } from "./utils/station-form-helper";
import { StationFormValues } from "./utils/station-form-types";

type StationVehiclesInputProps = {
	form: UseFormReturnType<StationFormValues>;
};

function StationVehiclesInput({
	form,
}: StationVehiclesInputProps): ReactElement {
	const VEHICLE_IMAGE_SIZE = 50;

	const vehicleFormInputs: ReactElement[] = [];
	VEHICLES_TYPES_IN_STATION_FORM_ORDER.forEach((vehicleType, index) =>
		vehicleFormInputs.push(
			<VehicleImage
				key={`icon-${vehicleType}`}
				size={VEHICLE_IMAGE_SIZE}
				vehicleType={vehicleType}
			/>,
			<NumberInput
				key={form.key(`vehiclesList.${index}.capacity`)}
				{...form.getInputProps(`vehiclesList.${index}.capacity`)}
				min={0}
			/>,
			<NumberInput
				key={form.key(`vehicles.${index}.duration`)}
				{...form.getInputProps(`vehiclesList.${index}.duration`)}
				min={0}
				suffix="h"
			/>,
			<NumberInput
				key={form.key(`vehicles.${index}.expected`)}
				{...form.getInputProps(`vehiclesList.${index}.expected`)}
				min={0}
			/>
		)
	);
	return (
		<SimpleGrid cols={4} spacing="xs">
			<div />
			<Text size="sm">Kapazität</Text>
			<Text size="sm">Zeitspanne</Text>
			<Text size="sm">Kritische Anzahl</Text>
			{vehicleFormInputs}
		</SimpleGrid>
	);
}

export default StationVehiclesInput;
