export default (problem: string): React.CSSProperties => {
	// default "Sonstiges"
	let backgroundColor = "rgb(255, 226, 153, 0.6)";

	if (problem === "Beschädigung") {
		backgroundColor = "rgb(245, 170, 163, 0.6)";
	}
	if (problem === "Verschmutzung") {
		backgroundColor = "rgb(204, 204, 204, 0.6)";
	}
	if (problem === "Verkehrssicherheit") {
		backgroundColor = "rgb(169, 195, 239, 0.6)";
	}
	if (problem === "Defekt") {
		backgroundColor = "rgb(216, 153, 235, 0.6)";
	}

	return {
		borderWidth: "0",
		backgroundColor,
	};
};
