import { ThemeConfig } from "antd";
import antdCustomStyle from "./global-variables.module.scss";

const customAntdTheme: ThemeConfig = {
	token: {
		// Seed Tokens
		colorPrimary: antdCustomStyle["color-primary"],
		colorSuccess: antdCustomStyle["color-green"],
		colorHighlight: antdCustomStyle["color-red"],
		colorError: antdCustomStyle["color-red"],
		colorLink: antdCustomStyle["color-blue"],
		colorPrimaryTextHover: antdCustomStyle["color-red"],
		colorBgLayout: antdCustomStyle["color-white"],
		borderRadius: 2,
		fontFamily: "Transit",
	},
	components: {
		Progress: {
			defaultColor: antdCustomStyle["color-primary"],
		},
		Menu: {
			itemHeight: 56,
			fontSize: 16,
		},
		Button: {
			borderRadius: 8,
			borderRadiusLG: 8,
			borderRadiusSM: 8,
			textHoverBg: antdCustomStyle["color-white"],
			onlyIconSize: 18,
			onlyIconSizeLG: 22,
			onlyIconSizeSM: 14,
		},
		Upload: {
			actionsColor: antdCustomStyle["color-white"],
		},
		Table: {
			cellPaddingInlineSM: 4,
		},
		DatePicker: {
			activeBorderColor: antdCustomStyle["color-primary"],
		},
	},
};

export default customAntdTheme;
