import { VehicleAmount, VehicleType } from "../api/jelbi-dashboard-api";

export const getVehicleAmount = (
	vehicleAmounts: VehicleAmount[],
	vehicleType: VehicleType
): number => {
	return (
		vehicleAmounts.find(
			(vehicleAmount) => vehicleAmount.vehicleType === vehicleType
		)?.amount || 0
	);
};

export const getVehicleAmounts = (
	vehicleAmounts: VehicleAmount[],
	vehicleType: VehicleType
): VehicleAmount[] => {
	return vehicleAmounts.filter(
		(vehicleAmount) => vehicleAmount.vehicleType === vehicleType
	);
};
