import React, { useState } from "react";
import { Badge, Radio, Stack, Tabs } from "@mantine/core";
import Section from "../Section";
import StationInformationIssues from "../StationInformationIssues";
import StationVehicleAvailability from "../StationVehicleAvailability/StationVehicleAvailability";
import VehicleSummary from "../VehicleSummary/VehicleSummary";
import { Issue, Station } from "../../../utilities/api/jelbi-dashboard-api";

type VehicleInformationViewType = "LIVE" | "WEEK";

type AvailabilitiesProps = {
	results: Issue[];
	station: Station;
};

function Availabilities({ results, station }: AvailabilitiesProps) {
	const [vehicleInformationViewType, setVehicleInformationViewType] =
		useState<VehicleInformationViewType>("LIVE");
	return (
		<Section title="Verfügbarkeiten">
			<Tabs defaultValue="vehicles">
				<Tabs.List mb={20} ml={-4}>
					<Tabs.Tab value="vehicles">Fahrzeuge</Tabs.Tab>
					<Tabs.Tab
						value="problems"
						rightSection={
							<Badge color="#ba0000" circle>
								{results.length}
							</Badge>
						}
					>
						Probleme
					</Tabs.Tab>
				</Tabs.List>
				<Tabs.Panel value="vehicles">
					<Radio.Group
						value={vehicleInformationViewType}
						onChange={(viewType) =>
							setVehicleInformationViewType(
								viewType as VehicleInformationViewType
							)
						}
						name="vehicles-information"
						ml={8}
					>
						<Stack>
							<Radio value="LIVE" label="Live Verfügbarkeiten" />
							<Radio value="WEEK" label="Verfügbarkeiten der letzten 7 Tage" />
						</Stack>
					</Radio.Group>
					{vehicleInformationViewType === "LIVE" ? (
						<VehicleSummary station={station} accumulateOverWeek={false} />
					) : (
						<>
							<VehicleSummary station={station} accumulateOverWeek />
							<StationVehicleAvailability station={station} />
						</>
					)}
				</Tabs.Panel>

				<Tabs.Panel value="problems">
					<div>
						<StationInformationIssues stationId={station.id} />
					</div>
				</Tabs.Panel>
			</Tabs>
		</Section>
	);
}
export default Availabilities;
