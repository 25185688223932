import { useState, useEffect } from "react";
import { MspId, Msp } from "../../api/jelbi-dashboard-api";
import useMsps from "./useMsps";

// in order to not use this cumbersome approach, other API endpoints would have
// to be substantially changed (the distributions endpoint in particular)
const useMspFromDistributionsMspId = (mspId: MspId) => {
	const [msp, setMsp] = useState<Msp | undefined>(undefined);
	const msps = useMsps();

	useEffect(() => {
		if (msps) {
			setMsp(
				msps.find(({ distributionsMspId }) => distributionsMspId === mspId)
			);
		}
	}, [msps]);

	return msp;
};

export default useMspFromDistributionsMspId;
