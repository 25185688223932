import React from "react";
import styles from "./index.module.scss";

export type MicromobilityAvailabilityState =
	| "OVERFILLED"
	| "INSUFFICIENT"
	| "OVERFILLED_INSUFFICIENT";

type MicromobilityAvailabilityBadgeProps = {
	state: MicromobilityAvailabilityState;
};

function MicromobilityAvailabilityBadge({
	state,
}: MicromobilityAvailabilityBadgeProps): JSX.Element {
	const { PUBLIC_URL } = process.env;
	const mapStateToFileNameAndDescription = {
		OVERFILLED: ["overfilled.svg", "überfüllte Mikrombilität-Badge"],
		OVERFILLED_INSUFFICIENT: [
			"overfilled-insufficient.svg",
			"überfüllte und leere Mikrombilität-Badge",
		],
		INSUFFICIENT: ["insufficient.svg", "leere Mikrombilität-Badge"],
	};

	const [iconFileName, altText] = mapStateToFileNameAndDescription[state];
	return (
		<img
			className={styles.icon}
			src={`${PUBLIC_URL}/icons-map/${iconFileName}`}
			alt={altText}
		/>
	);
}

export default MicromobilityAvailabilityBadge;
