import React, { ReactElement, useState } from "react";
import { getHours, startOfDay } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import {
	Select,
	useMantineTheme,
	Text,
	Stack,
	Group,
	Container,
	LoadingOverlay,
	Box,
} from "@mantine/core";
import { BarChart } from "@mantine/charts";
import {
	Station,
	VehicleType,
} from "../../../utilities/api/jelbi-dashboard-api";
import distributionService from "../../../services/distributionService";
import helper, { DropdownOption } from "./utils/helper";
import { getVehicleAvailabilityDayPerHourQueryKey } from "../../../utilities/client/query-keys";

import ChartTooltip from "./ChartTooltip";
import {
	VehicleDistributionDataSetHourChart,
	VehicleDistributionDataSetHour,
} from "./utils/types";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";

type StationVehicleAvailabilityProps = {
	station: Station;
};

function StationVehicleAvailability({
	station,
}: StationVehicleAvailabilityProps): ReactElement {
	const weekDays = helper.generateDropdownOptions();
	const theme = useMantineTheme();
	const isMobileView = useIsMobileView();

	const [selectedWeekDay, setSelectedWeekDay] = useState<DropdownOption>(
		weekDays[weekDays.length - 1]
	);

	const vehicleAvailabilityResponse = useQuery({
		queryKey: getVehicleAvailabilityDayPerHourQueryKey(
			new Date(selectedWeekDay.value),
			station.id
		),

		queryFn: () =>
			distributionService.fetchAvailabilitiesDayPerHour(
				station.id,
				startOfDay(new Date(selectedWeekDay.value))
			),
	});

	if (vehicleAvailabilityResponse.isError) {
		return <span>Etwas ist schief gelaufen</span>;
	}

	let vehicleAvailabilityDataSetDay: VehicleDistributionDataSetHour[] = [];
	const availableVehicleTypes = new Set<string>();
	if (vehicleAvailabilityResponse.data) {
		vehicleAvailabilityDataSetDay =
			vehicleAvailabilityResponse.data.results.map(
				(vehicleAvailabilityHour) => {
					const vehicleDistributionDataSetHourChart: VehicleDistributionDataSetHourChart =
						{
							hour: getHours(
								new Date(vehicleAvailabilityHour.start)
							).toString(),
						};

					vehicleAvailabilityHour.vehicleDistributions
						.filter(({ vehicleType }) => vehicleType !== VehicleType.CargoBike)
						.forEach((distribution) => {
							if (
								Object.values(VehicleType).includes(
									distribution.vehicleType as VehicleType
								)
							) {
								if (distribution.vehicleType) {
									vehicleDistributionDataSetHourChart[
										distribution.vehicleType
									] = distribution.amount;
									availableVehicleTypes.add(distribution.vehicleType);
								}
							}
						});
					return vehicleDistributionDataSetHourChart;
				}
			);
	}

	const chartSeries = helper.getChartSeries(
		Array.from(availableVehicleTypes),
		theme.colors
	);

	return (
		<Container px={0} py={40}>
			<Stack gap={8} mb={16}>
				<Group justify="flex-start">
					<Text fw="bold">Verfügbarkeiten der letzten 7 Tage</Text>
				</Group>
				<Group justify="flex-end">
					<Select
						data={weekDays.map((day) => day.label)}
						defaultValue={selectedWeekDay.label}
						onChange={(weekDayOption) => {
							if (weekDayOption) {
								const selectedWeekDayOption = weekDays.find(
									(weekday) => weekday.label === weekDayOption
								);

								if (selectedWeekDayOption) {
									setSelectedWeekDay({
										key: selectedWeekDayOption.key,
										label: selectedWeekDayOption.label,
										value: selectedWeekDayOption.value,
									});
								}
							}
						}}
					/>
				</Group>
			</Stack>
			{vehicleAvailabilityDataSetDay.length !== 0 ? (
				<BarChart
					h={isMobileView ? 300 : 400}
					type="stacked"
					xAxisLabel="Uhrzeit"
					yAxisLabel="Anzahl"
					data={vehicleAvailabilityDataSetDay}
					dataKey="hour"
					series={chartSeries}
					tickLine="y"
					barProps={{
						label: {
							position: "top",
							fill: "var(--mantine-color-gray-6)",
							fontSize: "var(--mantine-font-size-sm)",
						},
					}}
					yAxisProps={{
						allowDecimals: false,
						domain: [0, "dataMax + 1"],
					}}
					withBarValueLabel
					tooltipAnimationDuration={200}
					tooltipProps={{
						// eslint-disable-next-line react/no-unstable-nested-components
						content: ({ label, payload }) => (
							<ChartTooltip label={label} payload={payload} />
						),
					}}
				/>
			) : (
				<Box pos="relative" h={300} w="100%" bg="gray.2">
					<LoadingOverlay visible title="Wird geladen..." />
				</Box>
			)}
		</Container>
	);
}

export default StationVehicleAvailability;
