import L from "leaflet";

export const MAP_NORTH_WEST_BOUND: [number, number] = [52.99841, 12.12509];
export const MAP_SOUTH_EAST_BOUND: [number, number] = [51.84717, 14.64748];

export const boundaryPolygon = L.polygon([
	MAP_NORTH_WEST_BOUND,
	MAP_SOUTH_EAST_BOUND,
]);

export const checkGeolocationFormat = (
	geolocation: [number, number]
): boolean => {
	// source: https://stackoverflow.com/questions/3518504/regular-expression-for-matching-latitude-longitude-coordinates
	const geolocationCheckRegExp =
		/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

	// location check
	if (!geolocationCheckRegExp.test(geolocation.join(", "))) {
		return false;
	}
	return true;
};
