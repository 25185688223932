import React, { ReactElement, useEffect, useState } from "react";
import { ActionIcon, Checkbox, Modal, SimpleGrid, Text } from "@mantine/core";
import { readLocalStorageValue, useDisclosure } from "@mantine/hooks";
import { ReactComponent as SettingIconInactive } from "../../../../assets/map-icons/cog-line.svg";
import { ReactComponent as SettingIconActive } from "../../../../assets/map-icons/cog-solid.svg";
import areFlatObjectsEqual from "../../../../utilities/client/general-helpers";
import { LOCAL_STORAGE_KEY_MAP_SETTINGS } from "../../../../utilities/client/local-storage-keys";

export type MapSettingsOptions = {
	shouldShowNoParkingZones: boolean;
	shouldShowOtherMicromobilityAreas: boolean;
};

type SettingsButtonProps = {
	mapSettings: MapSettingsOptions;
	setMapSettings: React.Dispatch<React.SetStateAction<MapSettingsOptions>>;
};

function SettingsButton({
	mapSettings,
	setMapSettings,
}: SettingsButtonProps): ReactElement {
	const [opened, { open, close }] = useDisclosure(false);
	const [isSettingActive, setIsSettingActive] = useState(false);

	useEffect(() => {
		if (
			!areFlatObjectsEqual(
				readLocalStorageValue({ key: LOCAL_STORAGE_KEY_MAP_SETTINGS }),
				mapSettings
			)
		) {
			setIsSettingActive(true);
		}
	}, []);

	return (
		<>
			<ActionIcon
				radius="xl"
				bg="#fff"
				bd="1.5px solid"
				variant="outline"
				color="#656C7A"
				aria-label="Einstellungen"
				title="Einstellungen"
				onClick={opened ? close : open}
				style={{ borderRadius: "22px", borderWidth: "1.5px" }}
			>
				{isSettingActive ? (
					<SettingIconActive
						style={{
							width: "28px",
							height: "28px",
							fill: "#656C7A",
						}}
					/>
				) : (
					<SettingIconInactive
						style={{
							width: "28px",
							height: "28px",
							fill: "#656C7A",
						}}
					/>
				)}
			</ActionIcon>

			<Modal
				title={
					<Text component="h2" fw="bold" pl={24}>
						Einstellungen
					</Text>
				}
				centered
				className="settings-modal"
				opened={opened}
				onClose={close}
				w={1000}
			>
				<SimpleGrid p="24px 24px">
					<Text c="grey" tt="uppercase">
						Anzeige für Mikromobilität
					</Text>

					<Checkbox
						checked={mapSettings.shouldShowNoParkingZones}
						label="Parkverbotszonen"
						onChange={() => {
							setMapSettings({
								...mapSettings,
								shouldShowNoParkingZones: !mapSettings.shouldShowNoParkingZones,
							});
							setIsSettingActive(!mapSettings.shouldShowNoParkingZones);
						}}
					/>
					<Checkbox
						checked={mapSettings.shouldShowOtherMicromobilityAreas}
						label="Abstellflächen der Bezirke"
						onChange={() => {
							setMapSettings({
								...mapSettings,
								shouldShowOtherMicromobilityAreas:
									!mapSettings.shouldShowOtherMicromobilityAreas,
							});
							setIsSettingActive(
								!mapSettings.shouldShowOtherMicromobilityAreas
							);
						}}
					/>
				</SimpleGrid>
			</Modal>
		</>
	);
}

export default SettingsButton;
