import React from "react";
import { Station } from "../../../utilities/api/jelbi-dashboard-api";
import StationMarker from "../StationMarker";

const NOT_SELECTED_PIN_OPACITY = 0.3;

type ActiveStationMarkersProps = {
	stations: Station[];
	setSelectedStation: (station: Station) => void;
	showStationGeofence: boolean;
	selectedStation?: Station;
	showStationInformation: boolean;
};

function ActiveStationMarkers({
	stations,
	setSelectedStation,
	showStationGeofence,
	selectedStation,
	showStationInformation,
}: ActiveStationMarkersProps): JSX.Element {
	const getPinOpacity = (station: Station): number => {
		return showStationInformation && station.id !== selectedStation?.id
			? NOT_SELECTED_PIN_OPACITY
			: 1.0;
	};

	const stationMarkers = stations.map((station) => {
		return (
			<StationMarker
				key={station.id}
				station={station}
				setSelectedStation={setSelectedStation}
				showStationGeofence={showStationGeofence}
				opacity={getPinOpacity(station)}
			/>
		);
	});

	return <div>{stationMarkers}</div>;
}

export default ActiveStationMarkers;
