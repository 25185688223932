// disable eslint for using props spreading as it's best practices in https://mantine.dev/form/use-form/
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from "react";
import { DateTimePicker } from "@mantine/dates";
import { IconClock } from "@tabler/icons-react";
import { GetInputPropsReturnType } from "@mantine/form/lib/types";
import { DATE_PICKER_FORMAT } from "../constants";

type DashboardDateTimePickerProps = {
	formInputProps?: GetInputPropsReturnType;
	label: string;
	withAsterisk: boolean;
	minDate?: Date;
	maxDate?: Date;
	disabled?: boolean;
};

function DashboardDateTimePicker({
	formInputProps,
	label,
	withAsterisk = false,
	minDate,
	maxDate,
	disabled = false,
}: DashboardDateTimePickerProps): ReactElement {
	return (
		<DateTimePicker
			{...formInputProps}
			label={label}
			valueFormat={DATE_PICKER_FORMAT}
			withAsterisk={withAsterisk}
			clearable
			minDate={minDate}
			maxDate={maxDate}
			disabled={disabled}
			submitButtonProps={{ display: "none" }}
			timeInputProps={{
				leftSection: <IconClock stroke={1.5} />,
				leftSectionWidth: 42,
				leftSectionProps: {
					style: { marginLeft: "2px" },
				},
				m: 0,
			}}
		/>
	);
}

export default DashboardDateTimePicker;
