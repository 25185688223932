import L from "leaflet";
import { $enum } from "ts-enum-util";
import { isWithinInterval, isEqual, isAfter } from "date-fns";
import StationFormValidationErrorMessages from "./StationFormValidationErrorMessages";
import { PlannedClosureFormType } from "./station-form-types";
import {
	MAP_NORTH_WEST_BOUND,
	MAP_SOUTH_EAST_BOUND,
	checkGeolocationFormat,
} from "../../../utilities/client/map";

const GEO_FENCE_EXAMPLE = `
[
	[13.4080117, 52.4987132],
	[13.4077562, 52.4987112],
	[13.4077589, 52.4985663],
	[13.4080177, 52.4985679],
	[13.4080117, 52.4987132]
]`;
const GEO_LOCATION_EXAMPLE = "[13.4077728, 52.4985916]";
const BOUNDARY_POLYGON = L.polygon([
	MAP_NORTH_WEST_BOUND,
	MAP_SOUTH_EAST_BOUND,
]);

const validateStationShortName = (
	stationShortName: string | undefined,
	oldStationShortName: string | undefined,
	uniqueStationShortNames: string[]
): string | undefined => {
	if (!stationShortName) {
		return StationFormValidationErrorMessages.SHORT_NAME_MISSING;
	}

	if (
		uniqueStationShortNames?.includes(stationShortName) &&
		stationShortName !== oldStationShortName
	) {
		return StationFormValidationErrorMessages.SHORT_NAME_EXISTING;
	}
	return undefined;
};

const validateStationLocation = (
	stationLocation: string | undefined
): string | undefined => {
	if (!stationLocation) {
		return `${StationFormValidationErrorMessages.GEO_LOCATION_MISSING} Zum Beispiel ${GEO_LOCATION_EXAMPLE}`;
	}

	let parsedLocation;
	try {
		parsedLocation = JSON.parse(stationLocation);
		// format check
		if (!checkGeolocationFormat(parsedLocation)) {
			throw new Error();
		}
	} catch (e) {
		return `${StationFormValidationErrorMessages.GEO_LOCATION_INVALID_FORMAT} Zum Beispiel ${GEO_LOCATION_EXAMPLE}`;
	}

	// location check
	if (!BOUNDARY_POLYGON.getBounds().contains(parsedLocation.reverse())) {
		return StationFormValidationErrorMessages.GEO_LOCATION_OUTSIDE_BOUNDARY;
	}

	return undefined;
};

const validateStationGeofence = (
	stationGeofence: string | undefined
): string | undefined => {
	if (!stationGeofence) {
		return `${StationFormValidationErrorMessages.GEO_FENCE_MISSING} Zum Beispiel ${GEO_FENCE_EXAMPLE}`;
	}

	let parsedGeofence;
	try {
		parsedGeofence = JSON.parse(stationGeofence);

		// format check
		if (!Array.isArray(parsedGeofence)) {
			throw new Error();
		}
		if (parsedGeofence.length < 4) {
			throw new Error(
				StationFormValidationErrorMessages.GEO_FENCE_MIN_LOCATIONS
			);
		}
		const firstGeolocation = JSON.stringify(parsedGeofence[0]);
		const lastGeolocation = JSON.stringify(
			parsedGeofence[parsedGeofence.length - 1]
		);
		if (firstGeolocation !== lastGeolocation) {
			throw new Error(
				StationFormValidationErrorMessages.GEO_FENCE_START_END_POINTS_INVALID
			);
		}
		parsedGeofence.forEach((coordinate) => {
			if (!checkGeolocationFormat(coordinate)) {
				throw new Error();
			}
		});
	} catch (e) {
		if (
			$enum(StationFormValidationErrorMessages)
				.getValues()
				.some((errorMsg) => `Error: ${errorMsg}` === String(e))
		) {
			return String(e);
		}
		return `${StationFormValidationErrorMessages.GEO_FENCE_INVALID_FORMAT} Zum Beispiel ${GEO_FENCE_EXAMPLE}`;
	}

	// location check
	const areAllPointsInBoundary = parsedGeofence
		.map((coordinates) => {
			if (!BOUNDARY_POLYGON.getBounds().contains(coordinates.reverse())) {
				return true;
			}
			return false;
		})
		.includes(true);
	if (areAllPointsInBoundary) {
		return StationFormValidationErrorMessages.GEO_LOCATION_OUTSIDE_BOUNDARY;
	}

	return undefined;
};

const validateStationPlannedClosureStart = (
	newStart: Date,
	plannedClosures: PlannedClosureFormType[] | undefined,
	currentFieldLabel: string
): string | undefined => {
	if (!plannedClosures) {
		return undefined;
	}

	if (newStart) {
		for (let i = 0; i < plannedClosures.length; i++) {
			const { start, end } = plannedClosures[i];

			// is date within other closure interval
			if (
				!currentFieldLabel.includes(i.toString()) &&
				isWithinInterval(newStart, {
					start,
					end,
				}) &&
				!isEqual(newStart, end)
			) {
				return StationFormValidationErrorMessages.PLANNED_CLOSURES_OVERLAPPING;
			}

			// newStart can't be equal end
			if (currentFieldLabel.includes(i.toString()) && isEqual(newStart, end)) {
				return StationFormValidationErrorMessages.PLANNED_CLOSURE_SAME_START_AND_END;
			}
			// newStart can't be after end
			if (currentFieldLabel.includes(i.toString()) && isAfter(newStart, end)) {
				return StationFormValidationErrorMessages.PLANNED_CLOSURE_END_BEFORE_START;
			}
		}
	} else {
		return StationFormValidationErrorMessages.PLANNED_CLOSURE_START_MISSING;
	}

	return undefined;
};

const validateStationPlannedClosureEnd = (
	newEnd: Date,
	plannedClosures: PlannedClosureFormType[] | undefined,
	currentFieldLabel: string
): string | undefined => {
	if (!plannedClosures) {
		return undefined;
	}

	if (newEnd) {
		for (let i = 0; i < plannedClosures.length; i++) {
			const { start, end } = plannedClosures[i];

			// is date within other closure timeframe
			if (
				!currentFieldLabel.includes(i.toString()) &&
				isWithinInterval(newEnd, {
					start,
					end,
				}) &&
				!isEqual(newEnd, start)
			) {
				return StationFormValidationErrorMessages.PLANNED_CLOSURES_OVERLAPPING;
			}
			// newEnd can't be equal start
			if (currentFieldLabel.includes(i.toString()) && isEqual(newEnd, start)) {
				return StationFormValidationErrorMessages.PLANNED_CLOSURE_SAME_START_AND_END;
			}
		}
	} else {
		return StationFormValidationErrorMessages.PLANNED_CLOSURE_END_MISSING;
	}

	return undefined;
};

const validatePlannedClosuresAmount = (
	isPlannedClosuresEnabled: boolean,
	plannedClosures: PlannedClosureFormType[] | undefined
) => {
	if (
		isPlannedClosuresEnabled &&
		(!plannedClosures || !plannedClosures.length)
	) {
		return StationFormValidationErrorMessages.PLANNED_CLOSURES_MISSING;
	}
	return undefined;
};

export default {
	validateStationShortName,
	validateStationLocation,
	validateStationGeofence,
	validateStationPlannedClosureStart,
	validateStationPlannedClosureEnd,
	validatePlannedClosuresAmount,
};
