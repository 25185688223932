import { useState, useEffect } from "react";
import useStations from "./useStations";

const useGetUniqueStationShortNames = (): string[] => {
	const [uniqueStationShortNames, setUniqueStationShortNames] = useState<
		string[]
	>([]);

	const { data: stations } = useStations();

	useEffect(() => {
		if (stations && stations.length > 0) {
			setUniqueStationShortNames(stations.map(({ shortName }) => shortName));
		}
	}, [stations]);

	return uniqueStationShortNames;
};

export default useGetUniqueStationShortNames;
