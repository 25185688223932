import React, { ReactElement } from "react";
import { ActionIcon } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ReactComponent as AddStationIcon } from "../../../../assets/map-icons/add-solid.svg";

function AddStationButton(): ReactElement {
	const [opened, { open, close }] = useDisclosure(false);

	return (
		<ActionIcon
			radius="xl"
			bg="var(--mantine-primary-color-6)"
			variant="filled"
			aria-label="Station hinzufügen"
			title="Station hinzufügen"
			onClick={opened ? close : open}
		>
			<AddStationIcon
				style={{
					fill: "#fff",
				}}
			/>
		</ActionIcon>
	);
}

export default AddStationButton;
