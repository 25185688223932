import React from "react";
import styles from "./index.module.scss";

type IssueMarkerBadgeProps = {
	issueCount: number;
};

function IssueMarkerBadge({ issueCount }: IssueMarkerBadgeProps): JSX.Element {
	return (
		<div
			className={
				issueCount > 0
					? styles.badge
					: `${styles.badge} ${styles.badge__inactive}`
			}
		>
			{issueCount}
		</div>
	);
}

export default IssueMarkerBadge;
