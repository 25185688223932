import React, { ReactElement, useState } from "react";
import { Group, Switch } from "@mantine/core";
import { Station } from "../../../utilities/api/jelbi-dashboard-api";
import VehicleAccumulated from "./VehicleAccumulated/VehicleAccumulated";

type VehicleSummaryProps = {
	station: Station;
	accumulateOverWeek: boolean;
};

type DistributionType = "MODE" | "MSP";

function VehicleSummary({
	station,
	accumulateOverWeek,
}: VehicleSummaryProps): ReactElement {
	const [distributionType, setDistributionType] =
		useState<DistributionType>("MODE");

	const toggleMsp = (checked: boolean) => {
		setDistributionType(checked ? "MSP" : "MODE");
	};

	return (
		<>
			<Group justify="flex-end" mb={8} pr={4} mt={16}>
				<Switch
					labelPosition="left"
					label="Zeige MSPs"
					onChange={(e) => toggleMsp(e.currentTarget.checked)}
					disabled={accumulateOverWeek}
				/>
			</Group>

			<VehicleAccumulated
				station={station}
				accumulateOverWeek={accumulateOverWeek}
				showMsps={distributionType === "MSP"}
			/>
		</>
	);
}

export default VehicleSummary;
