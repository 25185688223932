import React, { ReactElement, useState } from "react";
import { Col, Row, Menu } from "antd";
import { MenuOutlined, LogoutOutlined, CloseOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import styles from "./index.module.scss";
import useGetUserRoles from "../../utilities/client/hooks/useGetUserRoles";
import {
	isAdminOrHigher,
	isRangerOrHigher,
} from "../../utilities/client/roles.util";

function Navbar(): ReactElement {
	const [contextMenuOpen, setContextMenuOpen] = useState<boolean>(false);

	const { PUBLIC_URL } = process.env;

	const userRoles = useGetUserRoles();
	const { instance } = useMsal();
	const location = useLocation();

	const logOut = (): void => {
		instance.logoutRedirect();
	};

	let items = [
		{
			key: "/",
			className: styles.navbar__item,
			label: <Link to="/">Stationsübersicht</Link>,
		},
		{
			key: "/inspections",
			className: styles.navbar__item,
			label: <Link to="/inspections">Begehungen</Link>,
		},
		{
			key: "/issues",
			className: styles.navbar__item,
			label: <Link to="/issues">Problemmeldungen</Link>,
		},
		{
			key: "/msps",
			className: styles.navbar__item,
			label: <Link to="/msps">MSPs</Link>,
		},
		{
			key: "/profile",
			className: styles.navbar__item,
			label: <Link to="/profile">Profil</Link>,
		},
		{
			key: "/logout",
			className: styles.navbar__item,
			onClick: () => logOut(),
			label: <LogoutOutlined />,
		},
	];

	if (!isRangerOrHigher(userRoles)) {
		items = items.filter((item) => item.key !== "/inspections");
	}

	if (!isAdminOrHigher(userRoles)) {
		items = items.filter((item) => item.key !== "/msps");
	}

	return (
		<Row align="middle" justify="space-between" className={styles.navbar__row}>
			<Col>
				<Link to="/" className={styles.navbar__logo}>
					<img
						src={`${PUBLIC_URL}/logo.svg`}
						alt="Navbar Logo"
						className={styles.navbar__logo__image}
					/>
				</Link>
			</Col>
			<Col span={2} xl={12}>
				<Menu
					theme="light"
					mode="horizontal"
					triggerSubMenuAction="click"
					selectedKeys={[location.pathname]}
					className={styles.navbar__menu}
					items={items}
					onOpenChange={(event) => {
						if (event.length === 0) {
							setContextMenuOpen(false);
						}
					}}
					overflowedIndicator={
						contextMenuOpen ? (
							<CloseOutlined
								className={styles.navbar__menu__button}
								onClick={() => setContextMenuOpen(!contextMenuOpen)}
							/>
						) : (
							<MenuOutlined
								className={styles.navbar__menu__button}
								onClick={() => setContextMenuOpen(!contextMenuOpen)}
							/>
						)
					}
				/>
			</Col>
		</Row>
	);
}

export default Navbar;
