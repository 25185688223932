import React, { ReactElement } from "react";
import { Text } from "@mantine/core";
import { MspId } from "../../utilities/api/jelbi-dashboard-api";
import useMspImage from "../../utilities/client/hooks/useMspImage";
import useMspName from "../../utilities/client/hooks/useMspName";

type MspImageProps = {
	mspId: MspId;
};

const IMAGE_WIDTH = 36;

function MspImage({ mspId: id }: MspImageProps): ReactElement {
	const { PUBLIC_URL } = process.env;

	const imageId = useMspImage(id);
	const name = useMspName(id);

	const imgSrc = PUBLIC_URL + imageId;

	return name ? (
		<img src={imgSrc} alt={name} title={name} width={IMAGE_WIDTH} />
	) : (
		<Text size="sm" w={IMAGE_WIDTH}>
			{id}
		</Text>
	);
}

export default MspImage;
