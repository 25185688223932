import { QueryClient } from "@tanstack/react-query";
import { ok } from "@oazapfts/runtime";
import { subYears } from "date-fns";
import {
	getStation,
	getStations,
	editStation,
	Station,
	StationEdit,
	VehicleType,
	Vehicles,
} from "../utilities/api/jelbi-dashboard-api";
import {
	getStationQueryKey,
	QUERY_KEY_ALL_STATIONS,
} from "../utilities/client/query-keys";
import { isMicromobility } from "../utilities/client/vehicles";

const fetchStations = async (): Promise<Station[]> => {
	const currentDate = new Date();
	return ok(
		getStations({
			issueCountStart: subYears(currentDate, 1).toISOString(),
			issueCountEnd: currentDate.toISOString(),
		})
	);
};

const getStationById = (stationId: string): Promise<Station> => {
	const currentDate = new Date();
	return ok(
		getStation(stationId, {
			issueCountStart: subYears(currentDate, 1).toISOString(),
			issueCountEnd: currentDate.toISOString(),
		})
	);
};

const editStationById = async (
	stationId: string,
	stationEdit: StationEdit
): Promise<Station> => {
	const currentDate = new Date();
	return ok(
		editStation(stationId, stationEdit, {
			issueCountStart: subYears(currentDate, 1).toISOString(),
			issueCountEnd: currentDate.toISOString(),
		})
	);
};

const updateQueryCache = async (
	queryClient: QueryClient,
	newStation: Station
): Promise<void> => {
	const queryKey = [QUERY_KEY_ALL_STATIONS];

	await queryClient.cancelQueries({ queryKey });

	const previousStations = queryClient.getQueryData<Station[]>(queryKey);

	if (previousStations) {
		queryClient.setQueryData<Station[]>(queryKey, [
			newStation,
			...previousStations,
		]);
	}
};

const updateQueryCacheEditedStation = async (
	queryClient: QueryClient,
	editedStation: Station
): Promise<void> => {
	const queryKey = getStationQueryKey(editedStation.id);

	await queryClient.cancelQueries({ queryKey });

	const previousStations = queryClient.getQueryData<Station>(queryKey);

	if (previousStations) {
		queryClient.setQueryData<Station>(queryKey, editedStation);
	}
};

const micromobilityAvailability = (vehicles: Vehicles) =>
	vehicles
		.filter(({ vehicleType }) => isMicromobility(vehicleType))
		.reduce((prev, { availability }) => prev + availability, 0);

const micromobilityToleranceLimit = (vehicles: Vehicles) =>
	Math.floor(
		(vehicles.find(({ vehicleType }) => vehicleType === VehicleType.KickScooter)
			?.capacity ?? 0) * 1.5
	);

const hasZeroMicromobilityAvailability = (vehicles: Vehicles): boolean =>
	micromobilityAvailability(vehicles) === 0;

const isOverMicromobilityToleranceLimit = (vehicles: Vehicles): boolean =>
	micromobilityAvailability(vehicles) > micromobilityToleranceLimit(vehicles);

export default {
	fetchStations,
	getStationById,
	updateQueryCache,
	editStationById,
	updateQueryCacheEditedStation,
	hasZeroMicromobilityAvailability,
	isOverMicromobilityToleranceLimit,
};
