import React, { ReactElement } from "react";
import { Divider } from "antd";
import { humanizedDateTimeLong } from "../../utilities/client/date-format";

import styles from "./index.module.scss";

type CommentsProps = {
	date: Date;
	comment: string;
};

function Comment({ date, comment }: CommentsProps): ReactElement {
	return (
		<>
			<Divider className={styles.divider} orientation="right" plain>
				{humanizedDateTimeLong(date)}
			</Divider>
			<p className={styles.comment}>{comment}</p>
		</>
	);
}

export default Comment;
