import React from "react";
import { Text } from "@mantine/core";
import Section from "../Section";

type ShortnameProps = {
	shortName: string;
};

function Shortname({ shortName }: ShortnameProps) {
	return (
		<Section title="Kurzbezeichnung">
			<Text size="sm">{shortName}</Text>
		</Section>
	);
}
export default Shortname;
