import { ok } from "@oazapfts/runtime";
import {
	Id,
	IssueCollection,
	Problem,
	deleteIssue,
	getIssues,
	patchIssue,
} from "../utilities/api/jelbi-dashboard-api";

const fetchIssues = async (params: {
	page?: number;
	maxItems?: number;
	start?: string;
	end?: string;
	stationIds?: Id[];
	isTracked?: boolean;
}): Promise<IssueCollection> => {
	return ok(getIssues(params));
};

const removeIssue = async (issueId: string): Promise<Problem | unknown> => {
	return ok(deleteIssue(issueId));
};

const editIssue = async (
	issueId: string,
	isTracked: boolean
): Promise<Problem | unknown> => {
	return ok(patchIssue(issueId, { isTracked }));
};

export default {
	fetchIssues,
	removeIssue,
	editIssue,
};
