import React, { ReactElement } from "react";
import { App, Col, Row, Button, Input, Form } from "antd";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import {
	CommentAdd,
	Comment,
	postComment,
} from "../../../utilities/api/jelbi-dashboard-api";
import CommentComponent from "../../Comment";
import {
	getIssueCommentsQueryKey,
	QUERY_KEY_ALL_ISSUES,
	QUERY_KEY_ALL_TRACKED_ISSUES,
} from "../../../utilities/client/query-keys";
import commentService from "../../../services/commentService";

import styles from "./index.module.scss";
import Spinner from "../../Spinner";

type CommentsProps = {
	issueId: string;
};

function IssueComments({ issueId }: CommentsProps): ReactElement {
	const { message } = App.useApp();
	const [form] = Form.useForm();
	const queryClient = useQueryClient();
	const { data: comments } = useQuery({
		queryKey: getIssueCommentsQueryKey(issueId),
		queryFn: () => commentService.getCommentsForIssue(issueId),
	});

	const updateIssueCommentCache = async (
		cachedIssueId: string,
		comment: Comment
	): Promise<void> => {
		const commentsQueryKey = getIssueCommentsQueryKey(cachedIssueId);
		await queryClient.cancelQueries({ queryKey: commentsQueryKey });
		const commentData = queryClient.getQueryData<Comment[]>(commentsQueryKey);

		if (commentData) {
			const updatedComments = [comment, ...commentData];
			queryClient.setQueryData(commentsQueryKey, updatedComments);

			queryClient.invalidateQueries({
				queryKey: [QUERY_KEY_ALL_ISSUES],
			});
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEY_ALL_TRACKED_ISSUES],
			});
		}
	};

	const addCommentMutation = useMutation({
		mutationFn: async (commentAdd: CommentAdd) => {
			const response = await postComment(issueId, commentAdd);

			if (response.status !== 201) {
				return Promise.reject();
			}

			return Promise.resolve(response.data);
		},
		onSuccess: (comment: Comment) => {
			message.success({
				content: "Kommentar erfolgreich hinzugefügt!",
				key: "addCommentSuccess",
			});
			form.resetFields();
			updateIssueCommentCache(issueId, comment);
		},
		onError: () =>
			message.error({
				content: "Kommentar konnte nicht hinzugefügt werden!",
				key: "addCommentError",
			}),
	});

	const addComment = (values: { comment: string }) => {
		const newComment: CommentAdd = {
			content: values.comment,
		};

		addCommentMutation.mutate(newComment);
	};

	if (!comments) {
		return (
			<div className={styles.spinner}>
				<Spinner height={60} width={100} />
				Kommentare werden geladen...
			</div>
		);
	}

	return (
		<div className={styles["comment-wrapper"]}>
			<Row>
				<Col span={24}>
					<Form
						name="addCommentForm"
						form={form}
						onFinish={addComment}
						preserve={false}
					>
						<Form.Item
							name="comment"
							rules={[
								{
									max: 255,
									message:
										"Der Kommentar darf nicht leer sein und kann maximal 255 Zeichen enthalten",
									required: true,
								},
							]}
						>
							<Input.TextArea
								allowClear
								rows={2}
								maxLength={256}
								count={{
									show: true,
									max: 255,
								}}
								placeholder="Neuen Kommentar hinzufügen..."
								autoFocus
							/>
						</Form.Item>
						<Form.Item>
							<Button
								className={styles["btn-submit"]}
								htmlType="submit"
								loading={addCommentMutation.isPending}
							>
								Kommentieren
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					{comments.map(({ createdAt, content, id }) => (
						<CommentComponent
							date={new Date(createdAt)}
							comment={content}
							key={id}
						/>
					))}
				</Col>
			</Row>
		</div>
	);
}

export default IssueComments;
