import { ok } from "@oazapfts/runtime";
import { useQuery } from "@tanstack/react-query";
import { getCityMicromobilityAreas } from "../../api/jelbi-dashboard-api";
import { QUERY_OTHER_MICROMOBILITY_AREAS } from "../query-keys";

const useOtherMicromobilityAreas = () => {
	const { data } = useQuery({
		queryKey: [QUERY_OTHER_MICROMOBILITY_AREAS],
		queryFn: () => ok(getCityMicromobilityAreas()),
	});

	return data;
};

export default useOtherMicromobilityAreas;
