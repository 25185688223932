import React, { ReactElement, useState } from "react";
import { App, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
	QueryClient,
	useMutation,
	useQuery,
	useQueryClient,
} from "@tanstack/react-query";
import { subYears } from "date-fns";
import styles from "./index.module.scss";
import issuesService from "../../../services/issuesService";
import { Id, Issue } from "../../../utilities/api/jelbi-dashboard-api";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import isClickOnRowValidConditions from "../../../utilities/client/table-configurations";
import ReadIssue from "../../StationIssues/ReadIssue";
import {
	getStationInformationIssuesTableConfigDesktop,
	getStationInformationIssuesTableConfigMobile,
} from "./tableConfig";
import {
	QUERY_KEY_STATION_ISSUES,
	QUERY_KEY_STATION_TRACKED_ISSUES,
} from "../../../utilities/client/query-keys";
import useGetUserRoles from "../../../utilities/client/hooks/useGetUserRoles";

type StationInformationIssuesProps = {
	stationId: Id;
};

const updateCachedIssues = (queryClient: QueryClient, stationId: string) => {
	queryClient.invalidateQueries({
		queryKey: [QUERY_KEY_STATION_ISSUES, stationId],
	});
	queryClient.invalidateQueries({
		queryKey: [QUERY_KEY_STATION_TRACKED_ISSUES, stationId],
	});
};

function StationInformationIssues({
	stationId,
}: StationInformationIssuesProps): ReactElement {
	const isViewMobile = useIsMobileView();
	const queryClient = useQueryClient();
	const { modal } = App.useApp();
	const userRoles = useGetUserRoles();

	const [selectedIssue, setSelectedIssue] = useState<Issue>();
	const [readIssueKeySuffix, setReadIssueKeySuffix] = useState<boolean>(false);

	const queryEndDate = new Date().toISOString();
	const queryStartDate = subYears(queryEndDate, 1).toISOString();

	const { data: issues, isPending } = useQuery({
		queryKey: [QUERY_KEY_STATION_ISSUES, stationId],
		queryFn: () => {
			return issuesService.fetchIssues({
				start: queryStartDate,
				end: queryEndDate,
				stationIds: [stationId],
			});
		},
	});

	const { results } = issues || {
		results: [],
	};

	const deleteMutation = useMutation({
		mutationFn: (issueId: string) => {
			return issuesService.removeIssue(issueId);
		},

		onSuccess: () => {
			updateCachedIssues(queryClient, stationId);
		},
	});

	const trackingMutation = useMutation({
		mutationFn: (issue: Issue) => {
			return issuesService.editIssue(issue.id, !issue.isTracked);
		},
		onSuccess: () => {
			updateCachedIssues(queryClient, stationId);
		},
	});

	const confirmDeleteIssue = (deleteIssueId: string) => {
		modal.confirm({
			title: "Soll die Problemmeldung wirklich gelöscht werden?",
			icon: <ExclamationCircleOutlined />,
			okText: "Löschen",
			onOk() {
				deleteMutation.mutate(deleteIssueId);
			},
			maskClosable: true,
			centered: true,
		});
	};

	const trackIssue = (issue: Issue) => {
		trackingMutation.mutate(issue);
	};

	const columnConfig: ColumnsType<Issue> = isViewMobile
		? getStationInformationIssuesTableConfigMobile(
				confirmDeleteIssue,
				trackIssue,
				userRoles
			)
		: getStationInformationIssuesTableConfigDesktop(
				confirmDeleteIssue,
				trackIssue
			);

	return (
		<>
			<Table
				className={styles["issue-table"]}
				size={isViewMobile ? "small" : "large"}
				dataSource={results}
				columns={columnConfig}
				tableLayout="fixed"
				scroll={{ x: "85%" }}
				rowKey={(issue) => {
					const key = issue.id;
					return `issue-${key}`;
				}}
				pagination={{
					pageSize: 5,
					position: ["bottomRight"],
					showSizeChanger: false,
					total: results.length,
				}}
				loading={isPending}
				rowClassName={styles["issue-table--pointer-cursor"]}
				onRow={(issue) => {
					return {
						onClick: (event) => {
							const { target } = event;
							const isClickOnRowValid = isClickOnRowValidConditions(target);
							// preventing opening a modal when clicking delete button
							if (isClickOnRowValid) {
								setSelectedIssue(issue);
								setReadIssueKeySuffix(!readIssueKeySuffix);
							}
						},
					};
				}}
			/>
			{selectedIssue && (
				<ReadIssue
					key={`${selectedIssue.id}-${readIssueKeySuffix}`}
					issue={selectedIssue}
				/>
			)}
		</>
	);
}

export default StationInformationIssues;
