import React, { ReactElement, createRef, useEffect, useState } from "react";
import { Button, Title, Flex, Group, Image, ScrollArea } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { subYears } from "date-fns";
import { Link } from "react-router-dom";
import { ReactComponent as EditOutlinedIcon } from "../../assets/icons/pencil-line.svg";
import styles from "./StationInformation.module.scss";
import {
	QUERY_KEY_STATION_ISSUES,
	getStationQueryKey,
} from "../../utilities/client/query-keys";
import stationService from "../../services/stationService";
import useIsMobileView from "../../utilities/client/hooks/useIsMobileView";
import issuesService from "../../services/issuesService";
import {
	StationState,
	UserRole,
} from "../../utilities/api/jelbi-dashboard-api";
import RoleBasedRender from "../RoleBasedRender";
import Status from "./sections/Status";
import Closures from "./sections/Closures";
import LastInspection from "./sections/LastInspection";
import Address from "./sections/Address";
import Shortname from "./sections/Shortname";
import Equipments from "./sections/Equipments";
import Availabilities from "./sections/Availabilities";

type StationInformationProps = {
	stationId: string;
	isSplitView: boolean;
};

const SECTION_ICON_SIZE = 26;

function StationInformation({
	stationId,
	isSplitView,
}: StationInformationProps): ReactElement {
	const { PUBLIC_URL } = process.env;

	const scrollAreaRef = createRef<HTMLDivElement>();
	const fixedArea = createRef<HTMLDivElement>();

	const isCurrentViewMobile = useIsMobileView();

	const [fixedAreaHeight, setFixedAreaHeight] = useState(0);
	const navbarHeight = "80px";
	const mapHeight = "25vh";
	useEffect(() => {
		setFixedAreaHeight(fixedArea.current?.getBoundingClientRect().height ?? 0);

		if (isSplitView) {
			if (isCurrentViewMobile) {
				scrollAreaRef.current?.setAttribute(
					"style",
					`height: calc(100vh - ${navbarHeight} - ${fixedAreaHeight}px - ${mapHeight})`
				);
			} else {
				scrollAreaRef.current?.setAttribute("style", `height: 100%`);
			}
		} else {
			scrollAreaRef.current?.setAttribute(
				"style",
				`height: calc(100vh - ${navbarHeight} - ${fixedAreaHeight}px)`
			);
		}
	});

	const getStation = useQuery({
		queryKey: getStationQueryKey(stationId),

		queryFn: () => stationService.getStationById(stationId),
	});

	const queryEndDate = new Date().toISOString();
	const queryStartDate = subYears(queryEndDate, 1).toISOString();

	const { data: issues } = useQuery({
		queryKey: [QUERY_KEY_STATION_ISSUES, stationId],
		queryFn: () =>
			issuesService.fetchIssues({
				start: queryStartDate,
				end: queryEndDate,
				stationIds: [stationId],
			}),
	});

	const { results } = issues || {
		results: [],
	};

	if (!getStation.isSuccess) {
		return <span>Wird geladen...</span>;
	}

	const station = getStation.data;
	const { closures } = station.plannedClosures;

	const calculatedMapHeight =
		isCurrentViewMobile && isSplitView ? mapHeight : "0px";

	return (
		<div className={styles["station-information"]}>
			{/* This component is draggable in StationCollectionMap view */}
			<div
				ref={fixedArea}
				className={styles["station-information__fixed-area"]}
			>
				<Flex className="dragHandle" wrap="nowrap" align="center" gap={16}>
					<Image
						src={
							station.state === StationState.Active
								? `${PUBLIC_URL}/icon-station-details/station.svg`
								: `${PUBLIC_URL}/icon-station-details/station_bw.svg`
						}
						alt="Station logo"
						h={48}
					/>
					<Title order={2} lh="24px" style={{ overflow: "hidden" }}>
						{station.name}
					</Title>
				</Flex>
				<Group justify="flex-end" mt={12}>
					<RoleBasedRender allowedRoles={[UserRole.Admin]}>
						<Link to="edit">
							<Button
								variant="outline"
								leftSection={
									<EditOutlinedIcon
										style={{
											height: "16px",
											width: "16px",
											fill: "var(--mantine-primary-color-6)",
										}}
									/>
								}
								title="Station bearbeiten"
							>
								Station bearbeiten
							</Button>
						</Link>
					</RoleBasedRender>
				</Group>
			</div>
			<ScrollArea
				h={`calc(100vh - ${navbarHeight} - ${fixedAreaHeight}px - ${calculatedMapHeight})`}
				offsetScrollbars="y"
				style={{ maxWidth: "100%" }}
			>
				{/* sections */}
				<Status
					isGeneratingEmailsToMsps={station.isGeneratingEmailsToMsps}
					iconSize={SECTION_ICON_SIZE}
					state={station.state}
				/>
				{closures?.[0] && <Closures closures={closures} />}
				{station.issues.lastInspection && (
					<LastInspection lastInspection={station.issues.lastInspection} />
				)}
				<Address address={station.address} district={station.district} />
				<Shortname shortName={station.shortName} />
				<Equipments station={station} iconSize={SECTION_ICON_SIZE} />
				<Availabilities results={results} station={station} />
			</ScrollArea>
		</div>
	);
}

export default StationInformation;
