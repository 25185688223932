import React, { ReactNode } from "react";
import { Box, Stack, Text } from "@mantine/core";

type SectionProps = {
	title: string;
	children: ReactNode;
};

function Section({ title, children }: SectionProps) {
	return (
		<Stack gap={4} mb={20}>
			<Text
				fw="bold"
				fs="md"
				lh="24px"
				mb={4}
				pl={12}
				bg="var(--mantine-color-gray-1)"
			>
				{title}
			</Text>
			<Box component="div" ml={12}>
				{children}
			</Box>
		</Stack>
	);
}
export default Section;
