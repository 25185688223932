export const isClickOnRowValidConditions = (target: EventTarget) =>
	!(
		(target as HTMLElement).children.length > 0 &&
		(target as HTMLElement).children[0].localName === "svg"
	) &&
	!((target as HTMLElement).localName === "svg") &&
	!(((target as HTMLElement).parentNode as HTMLElement).localName === "svg") &&
	!((target as HTMLElement).localName === "circle") &&
	!((target as HTMLElement).localName === "button") &&
	!(((target as HTMLElement).parentNode as HTMLElement).localName === "button");

export default isClickOnRowValidConditions;
