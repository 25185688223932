import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import App from "./App";
import initMsalInstance from "./config/msal";

const msalInstance = initMsalInstance();
const queryClient = new QueryClient();

const container = document.getElementById("root");

if (container) {
	const root = createRoot(container);

	root.render(
		<React.StrictMode>
			<MsalProvider instance={msalInstance}>
				<QueryClientProvider client={queryClient}>
					<App />
				</QueryClientProvider>
			</MsalProvider>
		</React.StrictMode>
	);
}
