// eslint-disable-next-line no-shadow
export enum JelbiColor {
	MAIN = "#ffda48",
	BLUE = "#007bff",
	INDIGO = "#6610f2",
	PURPLE = "#6f42c1",
	PINK = "#e83e8c",
	RED = "#dc3545",
	ORANGE = "#fd7e14",
	YELLOW = "#ffc107",
	GREEN = "#28a745",
	TEAL = "#20c997",
	CYAN = "#17a2b8",
	WHITE = "#fff",
	BLACK = "#000",
	UNKNOWN = "#343A40",
	GRAY_INACTIVE = "#bebebe",
}

export default JelbiColor;
