import React, { ReactElement, useEffect, useState } from "react";
import { App, Result, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { subYears } from "date-fns";
import {
	useQuery,
	useQueryClient,
	useMutation,
	QueryClient,
} from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { FilterValue } from "antd/lib/table/interface";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
	filterIssuesForMobile,
	getIssueTableConfigDesktop,
	getIssueTableConfigMobile,
} from "./tableConfig";
import { Issue } from "../../../utilities/api/jelbi-dashboard-api";
import {
	QUERY_KEY_ALL_ISSUES,
	QUERY_KEY_ALL_TRACKED_ISSUES,
} from "../../../utilities/client/query-keys";
import styles from "./index.module.scss";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import { isClickOnRowValidConditions } from "../../../utilities/client/table-configurations";
import ReadIssue from "../ReadIssue";
import issuesService from "../../../services/issuesService";
import useGetUserRoles from "../../../utilities/client/hooks/useGetUserRoles";
import useStations from "../../../utilities/client/hooks/useStations";

type IssuesTableProps = {
	issuesPerPage: number;
	showTrackedIssuesOnly?: boolean;
};

const updateCachedIssues = (queryClient: QueryClient) => {
	queryClient.invalidateQueries({
		queryKey: [QUERY_KEY_ALL_ISSUES],
	});
	queryClient.invalidateQueries({
		queryKey: [QUERY_KEY_ALL_TRACKED_ISSUES],
	});
};

function IssuesTable({
	issuesPerPage,
	showTrackedIssuesOnly,
}: IssuesTableProps): ReactElement {
	const [selectedIssue, setSelectedIssue] = useState<Issue>();
	const [readIssueKeySuffix, setReadIssueKeySuffix] = useState<boolean>(false);
	const [tableFilters, setTableFilters] = useState<
		Record<string, FilterValue | null>
	>({});
	const { modal } = App.useApp();
	const isViewMobile = useIsMobileView();
	const userRoles = useGetUserRoles();
	const queryClient = useQueryClient();
	const queryEndDate = new Date().toISOString();
	const queryStartDate = subYears(queryEndDate, 1).toISOString();

	const {
		data: issues,
		isPending,
		isError,
		error,
	} = useQuery({
		queryKey: [
			showTrackedIssuesOnly
				? QUERY_KEY_ALL_TRACKED_ISSUES
				: QUERY_KEY_ALL_ISSUES,
		],
		queryFn: () => {
			if (showTrackedIssuesOnly) {
				return issuesService.fetchIssues({
					start: queryStartDate,
					end: queryEndDate,
					isTracked: true,
				});
			}
			return issuesService.fetchIssues({
				start: queryStartDate,
				end: queryEndDate,
			});
		},
	});

	const deleteMutation = useMutation({
		mutationFn: (issueId: string) => {
			return issuesService.removeIssue(issueId);
		},

		onSuccess: () => {
			updateCachedIssues(queryClient);
		},
	});

	const trackingMutation = useMutation({
		mutationFn: (issue: Issue) => {
			return issuesService.editIssue(issue.id, !issue.isTracked);
		},
		onSuccess: () => {
			updateCachedIssues(queryClient);
		},
	});

	// useHistory
	const navigate = useNavigate();
	const { issueId } = useParams();

	useEffect(() => {
		if (issueId) {
			const sharedIssue = issues?.results.find((issue) => issue.id === issueId);
			if (sharedIssue) {
				setSelectedIssue(sharedIssue);
			}
		}
	});

	const { results } = issues || {
		results: [],
	};

	const stationNames = Array.from(
		new Set(results.map((result) => result.station.name || "unbekannt"))
	);

	const problemTypes = Array.from(
		new Set(results.map((result) => result.problem || "unbekannt"))
	);

	const confirmDeleteIssue = (deleteIssueId: string) => {
		modal.confirm({
			title: "Soll die Problemmeldung wirklich gelöscht werden?",
			icon: <ExclamationCircleOutlined />,
			okText: "Löschen",
			onOk() {
				deleteMutation.mutate(deleteIssueId);
			},
			maskClosable: true,
			centered: true,
		});
	};

	const trackIssue = (issue: Issue) => {
		trackingMutation.mutate(issue);
	};

	const issuesToDisplay = isViewMobile
		? filterIssuesForMobile(results, tableFilters, stationNames, problemTypes)
		: results;

	const { data: stations } = useStations();

	const columnConfig: ColumnsType<Issue> = isViewMobile
		? getIssueTableConfigMobile(
				stationNames,
				problemTypes,
				confirmDeleteIssue,
				trackIssue,
				userRoles
			)
		: getIssueTableConfigDesktop(
				stations || [],
				stationNames,
				problemTypes,
				confirmDeleteIssue,
				trackIssue
			);

	if (isError) {
		return (
			<Result
				status="error"
				title="Tabelle konnte nicht geladen werden"
				subTitle={`Error: ${error.message}`}
			/>
		);
	}

	return (
		<>
			<Table
				className={styles["issue-table"]}
				size={isViewMobile ? "small" : "large"}
				dataSource={issuesToDisplay}
				columns={columnConfig}
				tableLayout="fixed"
				scroll={{ x: "85%" }}
				rowKey={(issue) => {
					const key = issue.id;
					return `issue-${key}`;
				}}
				pagination={{
					pageSize: issuesPerPage,
					position: ["bottomRight"],
					showSizeChanger: false,
					total: issuesToDisplay.length,
				}}
				loading={isPending}
				rowClassName={styles["issue-table--pointer-cursor"]}
				onChange={(_pagination, filters, _sorter, extra) => {
					if (extra.action === "filter") {
						setTableFilters(filters);
					}
				}}
				onRow={(issue) => {
					return {
						onClick: (event) => {
							const { target } = event;
							const isClickOnRowValid = isClickOnRowValidConditions(target);
							// preventing opening a modal when clicking delete button
							if (isClickOnRowValid) {
								setSelectedIssue(issue);
								setReadIssueKeySuffix(!readIssueKeySuffix);
								navigate(`/issues/${issue.id}`);
							}
						},
					};
				}}
			/>
			{selectedIssue && (
				<ReadIssue
					key={`${selectedIssue.id}-${readIssueKeySuffix}`}
					issue={selectedIssue}
					onClose={() => navigate("/issues/")}
				/>
			)}
		</>
	);
}

export default IssuesTable;
