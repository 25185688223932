import React, { ReactElement } from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal, Timeline, Badge } from "antd";
import { isWithinInterval, format } from "date-fns";
import { PlannedClosure } from "../../../utilities/api/jelbi-dashboard-api";
import styles from "./index.module.scss";

type StationClosuresProps = {
	isModalVisible: boolean;
	setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
	closures: PlannedClosure[];
};

function StationClosures({
	isModalVisible,
	setModalVisible,
	closures,
}: StationClosuresProps): ReactElement {
	const timelineItems = closures.map(({ start, end, comment }) => {
		const formatDate = (date: Date) => (
			<p className={styles["station-closures-modal__timeline__time"]}>
				{format(new Date(date), `dd.MM.yyyy - `)}
				<span>{format(new Date(date), `HH:mm `)}Uhr</span>
			</p>
		);

		const timeframe = (
			<>
				{formatDate(new Date(start))}
				{formatDate(new Date(end))}
				{comment && (
					<p className={styles["station-closures-modal__timeline__comment"]}>
						{comment}
					</p>
				)}
			</>
		);
		if (isWithinInterval(new Date(), { start, end })) {
			return { children: timeframe };
		}
		return {
			children: timeframe,
			dot: (
				<Badge
					className={styles["station-closures-modal__timeline__active-dot"]}
				/>
			),
		};
	});

	return (
		<Modal
			title={
				<h2 className={styles["station-closures-modal__title"]}>Sperrungen</h2>
			}
			className="station-closures-modal"
			open={isModalVisible}
			maskClosable
			destroyOnClose
			onCancel={() => setModalVisible(false)}
			closeIcon={
				<CloseOutlined
					className={styles["station-closures-modal__cross-close-button"]}
				/>
			}
			centered
			width={600}
			footer={[
				<Button
					key="back"
					type="primary"
					onClick={() => setModalVisible(false)}
					className={styles["station-closures-modal__close-button"]}
				>
					Schließen
				</Button>,
			]}
		>
			<Timeline
				className={styles["station-closures-modal__timeline"]}
				items={timelineItems}
			/>
		</Modal>
	);
}

export default StationClosures;
