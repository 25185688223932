import React from "react";
import { ChartTooltipProps } from "@mantine/charts";
import { Paper, Group, Text, Badge } from "@mantine/core";
import { getVehicleTypeName } from "../../../utilities/client/vehicles";

function ChartTooltip({ label, payload }: ChartTooltipProps) {
	if (!payload) return null;

	return (
		<Paper px="md" py="sm" withBorder shadow="md" radius="md">
			<Text fw={500} mb={5}>
				{`${label}:00 Uhr`}
			</Text>
			{payload.map((item) => (
				<Group key={item.id}>
					<Badge size="xs" circle color={item.color} />
					<Text key={item.name} fz="md">
						{getVehicleTypeName(item.name)}: {item.value}
					</Text>
				</Group>
			))}
		</Paper>
	);
}

export default ChartTooltip;
