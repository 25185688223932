import { useQuery } from "@tanstack/react-query";
import { QUERY_KEY_ALL_STATIONS } from "../query-keys";
import stationService from "../../../services/stationService";

const useStations = () => {
	const { isSuccess, data } = useQuery({
		queryKey: [QUERY_KEY_ALL_STATIONS],
		queryFn: async () => {
			return stationService.fetchStations();
		},
	});

	return { isSuccess, data };
};

export default useStations;
