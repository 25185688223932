// disable eslint for using props spreading as it's best practices in https://mantine.dev/form/use-form/
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from "react";
import {
	Button,
	Text,
	Stack,
	FileButton,
	FileButtonProps,
} from "@mantine/core";
import classes from "./UploadButton.module.scss";

export type { Photo } from "../utils/photos-reducer";

type UploadButtonProps = {
	capture?: FileButtonProps<true>["capture"];
	icon: ReactElement;
	label: string;
	onChange: FileButtonProps<true>["onChange"];
};

function UploadButton({
	capture,
	icon,
	label,
	onChange,
}: UploadButtonProps): ReactElement {
	return (
		<FileButton
			accept="image/jpeg"
			multiple
			capture={capture}
			onChange={onChange}
		>
			{(props) => (
				<Button
					{...props}
					variant="default"
					style={{ height: "100%" }}
					classNames={{ root: classes["upload-button"] }}
				>
					<Stack align="center" gap={4}>
						{icon}
						<Text>{label}</Text>
					</Stack>
				</Button>
			)}
		</FileButton>
	);
}

export default UploadButton;
