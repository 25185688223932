import React, { ReactElement } from "react";
import { Box, LoadingOverlay, Table } from "@mantine/core";
import { VehicleAccumulatedInnerProps } from "../../../../../utilities/client/vehicle-accumulated";
import { getRowTds, getHead } from "./utils/table-parts";

function VehicleAccumulatedTable({
	availabilities,
	showMsps,
	availableVehicleTypes,
	showLoading,
}: VehicleAccumulatedInnerProps): ReactElement {
	const rowTds = getRowTds(availableVehicleTypes, availabilities, showMsps);
	const heads = getHead(availableVehicleTypes);

	return !showLoading ? (
		<Table>
			<Table.Thead bg="gray.0">
				<Table.Tr>{heads}</Table.Tr>
			</Table.Thead>
			<Table.Tbody>
				<Table.Tr key={availableVehicleTypes.toString()}>{rowTds}</Table.Tr>
			</Table.Tbody>
		</Table>
	) : (
		<Box pos="relative" h={120} w="100%" bg="gray.2">
			<LoadingOverlay visible title="Wird geladen..." />
		</Box>
	);
}

export default VehicleAccumulatedTable;
