import { useEffect, useState } from "react";
import useMsps from "../../../utilities/client/hooks/useMsps";

const useMaxAvailableMicromobilityQuota = (mspId?: string) => {
	const msps = useMsps();

	const [quota, setQuota] = useState<number>(0);

	useEffect(() => {
		if (msps) {
			setQuota(
				msps
					.filter(({ id }) => id !== mspId)
					.reduce(
						(prev: number, { micromobilityQuotaPercentage: curr }) =>
							// The multiplication by 100 is necessary as we store the
							// percentages in fractions of 1 in the backend
							// `Math.trunc` is necessary to prevent rounding errors
							prev - Math.trunc(100 * curr),
						100
					)
			);
		}
	}, [msps, mspId]);

	return quota;
};

export default useMaxAvailableMicromobilityQuota;
