import {
	VehicleAmount,
	VehicleType,
	VehiclesStatistic,
} from "../api/jelbi-dashboard-api";
import { getVehicleAmount, getVehicleAmounts } from "./vehicle-amount";

export const VEHICLE_ACCUMULATED_AVATAR_SIZE = 34;
export const VEHCILE_ACCUMULATED_INNER_ICON_MARGIN = 6;

export type VehicleAccumulatedInnerProps = {
	availabilities: VehiclesStatistic;
	showMsps: boolean;
	availableVehicleTypes: VehicleType[];
	showLoading: boolean;
};

export type AccumulatedVehicleAmount = {
	vehicleType: VehicleType;
	amount?: number;
	mspDistributions?: VehicleAmount[];
};

export const getAccumulatedVehicleAmountData = (
	availableVehicleTypes: VehicleType[],
	availabilities: VehiclesStatistic,
	showMsps: boolean
): AccumulatedVehicleAmount[] =>
	availableVehicleTypes.map((vehicleTypeEnum) =>
		showMsps
			? {
					vehicleType: vehicleTypeEnum,
					mspDistributions: getVehicleAmounts(
						availabilities.vehicleDistributions,
						vehicleTypeEnum
					),
				}
			: {
					vehicleType: vehicleTypeEnum,
					amount: getVehicleAmount(
						availabilities.vehicleDistributions,
						vehicleTypeEnum
					),
				}
	);
