import stationEquipmentOptions from "../../../../utilities/client/station-equipment";
import {
	InspectionFormValues,
	InspectionLocationState,
} from "./add-inspection-form.types";

const initialValues = (
	state: InspectionLocationState
): InspectionFormValues => {
	if (state && state.currentInspection) {
		return {
			stationId: state.currentInspection.stationId,
			createdAt: state.currentInspection.createdAt,
			photos: state.currentInspection.photos,
			detectedIssues: state.currentInspection.detectedIssues,
			issues: state.currentInspection.issues,
		};
	}
	return {
		stationId: "",
		createdAt: new Date(),
		photos: [],
		detectedIssues: stationEquipmentOptions.map((option) => option.key),
		issues: [],
	};
};

export default initialValues;
