import React, { ReactElement } from "react";
import { GeoJSON } from "react-leaflet";
import { MultiPolygonGeofence } from "../../../utilities/api/jelbi-dashboard-api";

type NoParkingZonesProps = {
	zones: MultiPolygonGeofence[];
};

function NoParkingZones({ zones }: NoParkingZonesProps): ReactElement {
	const noParkingZones = zones.map((zone) => (
		<GeoJSON
			style={{
				color: "var(--mantine-color-red-5)",
				weight: 1,
				fillColor: "#d9534f",
			}}
			data={zone}
		/>
	));

	return <div>{noParkingZones}</div>;
}

export default NoParkingZones;
