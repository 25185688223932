import React from "react";
import L, { MarkerCluster } from "leaflet";
import { renderToString } from "react-dom/server";
import ClusterMarker from "./ClusterMarker";

const NOT_SELECTED_PIN_OPACITY = 0.5;

type CreateStationClusterIconProps = {
	cluster: MarkerCluster;
	showStationInformation: boolean;
	selectedStationId?: string;
};

export default function CreateStationClusterIcon({
	cluster,
	showStationInformation,
	selectedStationId,
}: CreateStationClusterIconProps) {
	const childMarker = cluster.getAllChildMarkers();

	const issueCount = childMarker
		.map((marker: L.Marker) =>
			parseInt(marker.options.alt?.split(",")[1] || "0", 10)
		)
		.reduce((accu: number, curr: number) => accu + curr, 0);

	const showPlannedClosureBadge = childMarker
		.map((marker: L.Marker) => marker.options.alt?.split(",")[3] || "false")
		.some((isPlannedClosureShown) => isPlannedClosureShown === "true");

	const showZeroMicromobilityAvailability = childMarker
		.map((marker: L.Marker) => marker.options.alt?.split(",")[4] || "false")
		.some(
			(isZeroMicromobilityAvailability) =>
				isZeroMicromobilityAvailability === "true"
		);

	const showOverMicromobilityToleranceLimit = childMarker
		.map((marker: L.Marker) => marker.options.alt?.split(",")[5] || "false")
		.some((isOverToleranceLimit) => isOverToleranceLimit === "true");

	const opacity =
		childMarker
			.map((marker: L.Marker) => marker.options.alt?.split(",")[0])
			.includes(selectedStationId) || !showStationInformation
			? 1
			: NOT_SELECTED_PIN_OPACITY;

	return new L.DivIcon({
		html: renderToString(
			<ClusterMarker
				childCount={cluster.getChildCount()}
				issueCount={issueCount}
				showPlannedClosureBadge={showPlannedClosureBadge}
				opacity={opacity}
				showZeroMicromobilityAvailabilityBadge={
					showZeroMicromobilityAvailability
				}
				showOverMicromobilityToleranceLimit={
					showOverMicromobilityToleranceLimit
				}
			/>
		),
		className: "",
		iconSize: [38, 38],
	});
}
