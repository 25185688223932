import { useQuery } from "@tanstack/react-query";
import { ok } from "@oazapfts/runtime";
import { QUERY_KEY_UNASSIGNED_DISTRIBUTIONS_MSP_IDS } from "../query-keys";
import { getDistributionsUnassignedMsps } from "../../api/jelbi-dashboard-api";

const useUnassignedDistributionsMspIds = () => {
	const { data } = useQuery({
		queryKey: [QUERY_KEY_UNASSIGNED_DISTRIBUTIONS_MSP_IDS],
		queryFn: () => ok(getDistributionsUnassignedMsps()),
	});

	return data || [];
};

export default useUnassignedDistributionsMspIds;
