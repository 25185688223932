import React from "react";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconExclamationCircleFilled } from "@tabler/icons-react";

const showEditMspSuccessfulNotification = (mspName: string) => {
	notifications.show({
		id: "successEditMsp",
		position: "top-center",
		message: `MSP ${mspName} erfolgreich geändert!`,
		color: "green",
		icon: <IconCheck />,
	});
};

const showEditMspFailedNotification = () => {
	notifications.show({
		id: "failEditMsp",
		position: "top-center",
		message: "MSP konnte nicht geändert werden!",
		color: "red",
		icon: <IconExclamationCircleFilled />,
	});
};

const showAddMspSuccessfulNotification = (mspName: string) => {
	notifications.show({
		id: "successAddMsp",
		position: "top-center",
		message: `MSP ${mspName} hinzugefügt!`,
		color: "green",
		icon: <IconCheck />,
	});
};

const showAddMspFailedNotification = () => {
	notifications.show({
		id: "failAddMsp",
		position: "top-center",
		message: "MSP konnte nicht hinzugefügt werden!",
		color: "red",
		icon: <IconExclamationCircleFilled />,
	});
};

const showDeleteMspSuccessfulNotification = (mspName: string) => {
	notifications.show({
		id: "successDeleteMsp",
		position: "top-center",
		message: `MSP ${mspName} gelöscht!`,
		color: "green",
		icon: <IconCheck />,
	});
};

const showDeleteMspFailedNotification = (mspName: string) => {
	notifications.show({
		id: "failDeleteMsp",
		position: "top-center",
		message: `MSP ${mspName} konnte nicht gelöscht werden!`,
		color: "red",
		icon: <IconExclamationCircleFilled />,
	});
};

export default {
	showEditMspSuccessfulNotification,
	showEditMspFailedNotification,
	showAddMspSuccessfulNotification,
	showAddMspFailedNotification,
	showDeleteMspSuccessfulNotification,
	showDeleteMspFailedNotification,
};
