import React from "react";
import { Stack, Group, Text } from "@mantine/core";
import { VehicleAmount } from "../../../../../../utilities/api/jelbi-dashboard-api";
import MspImage from "../../../../../MspImage/MspImage";

const getVehicleAmount = (
	showMsps: boolean,
	mspDistributions: VehicleAmount[] | undefined,
	amount: number | undefined
) => {
	const zeroAmount = <Text pl={8}>0</Text>;
	if (!showMsps) {
		return <Text>{amount ?? 0}</Text>;
	}

	if (!mspDistributions || mspDistributions.length === 0) {
		return zeroAmount;
	}

	return (
		<Stack align="flex-start" gap={4} mb={8}>
			{mspDistributions?.map((mspDistribution: VehicleAmount) => {
				return (
					<Group wrap="nowrap" key={mspDistribution.mspId}>
						{mspDistribution.mspId ? (
							<>
								<Text ta="center" w={24} pt={4}>
									{mspDistribution.amount}
								</Text>
								<MspImage mspId={mspDistribution.mspId || ""} />
							</>
						) : (
							zeroAmount
						)}
					</Group>
				);
			})}
		</Stack>
	);
};

export default getVehicleAmount;
