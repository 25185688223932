import React from "react";
import {
	DeleteOutlined,
	FilterOutlined,
	CommentOutlined,
	BookOutlined,
	MoreOutlined,
	PaperClipOutlined,
	FileImageOutlined,
} from "@ant-design/icons";
import { Dropdown, Tag, Flex, MenuProps } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FilterValue } from "antd/lib/table/interface";
import {
	Issue,
	PhotoShallow,
	Station,
	StationPartial,
	UserRole,
} from "../../../utilities/api/jelbi-dashboard-api";
import { humanizedDateTimeFormat } from "../../../utilities/client/date-format";
import styles from "./index.module.scss";
import getProblemTagStyle from "../../../styles/getProblemTagStyle";
import RoleBasedRender from "../../RoleBasedRender";
import { isRangerOrHigher } from "../../../utilities/client/roles.util";

const PROBLEM_STATION_COLUMN_KEY = "problem+station";

export const getIssueTableConfigMobile = (
	stationNames: string[],
	problemTypes: string[],
	deleteIssue: (issueId: string) => void,
	trackIssue: (issue: Issue) => void,
	userRoles: UserRole[]
): ColumnsType<Issue> => [
	{
		key: PROBLEM_STATION_COLUMN_KEY,
		title: (
			<span className={styles["issue-table__column-header"]}>
				Problem/Standort
			</span>
		),
		render: (issue: Issue) => {
			return (
				<>
					<Tag style={getProblemTagStyle(issue.problem)}>
						<span>{issue.problem}</span>
					</Tag>

					<div className={styles["issue-table__station-text"]}>
						{issue.station.name}
					</div>
				</>
			);
		},
		filters: [
			{
				text: "Problem",
				value: "problem",
				children: problemTypes
					.map((problem) => {
						return { text: problem, value: problem };
					})
					.sort((a, b) => a.text.localeCompare(b.text)),
			},
			{
				text: "Standort",
				value: "station",
				children: stationNames
					.map((stationName) => {
						return { text: stationName, value: stationName };
					})
					.sort((a, b) => a.text.localeCompare(b.text)),
			},
		],
		filterMode: "tree",
		filterIcon: <FilterOutlined />,
	},
	{
		key: "createdAt",
		title: <span className={styles["issue-table__column-header"]}>Datum</span>,
		dataIndex: "createdAt",
		width: 80,
		render: (reportedAtDate: string) => {
			return humanizedDateTimeFormat(new Date(reportedAtDate));
		},
	},
	{
		key: "actions",
		width: 40,
		render: (_, issue) => {
			let items: MenuProps["items"] = [
				{
					key: "nachverfolgen",
					label: (
						<span
							className={
								issue.isTracked
									? styles["issue-table__dropdown__content--tracked"]
									: styles["issue-table__dropdown__content"]
							}
						>
							Nachverfolgen
						</span>
					),
					icon: (
						<BookOutlined
							className={styles["issue-table__dropdown__content"]}
							style={issue.isTracked ? { color: "#4079E3" } : {}}
							twoToneColor="#eb2f96"
							title="Nachverfolgen"
						/>
					),
					onClick: (e) => {
						e.domEvent.stopPropagation();
						trackIssue(issue);
					},
				},
				{
					type: "divider",
				},
				{
					key: "löschen",
					label: (
						<span className={styles["issue-table__dropdown__content"]}>
							Löschen
						</span>
					),
					icon: (
						<DeleteOutlined
							className={styles["issue-table__dropdown__content"]}
							title="Löschen"
						/>
					),
					onClick: (e) => {
						e.domEvent.stopPropagation();
						deleteIssue(issue.id);
					},
				},
			];

			if (!isRangerOrHigher(userRoles)) {
				items = items.filter(
					(item) => item && item.key !== "löschen" && item.type !== "divider"
				);
			}

			return (
				<Dropdown menu={{ items }} trigger={["click"]}>
					<Flex
						align="center"
						justify="center"
						className={styles["issue-table__dropdown__container"]}
						role="button"
						title="Aktionen anzeigen"
						onClick={(e) => e.stopPropagation()}
					>
						<MoreOutlined className={styles["issue-table__dropdown__icon"]} />
					</Flex>
				</Dropdown>
			);
		},
		align: "center",
	},
];

export const filterIssuesForMobile = (
	issues: Issue[],
	tableFilters: Record<string, FilterValue | null>,
	stationNames: string[],
	problemTypes: string[]
): Issue[] => {
	const relevantFilters = tableFilters[PROBLEM_STATION_COLUMN_KEY];
	if (relevantFilters) {
		const filterStationNames = relevantFilters.filter(
			(filter) => typeof filter === "string" && stationNames.includes(filter)
		);
		const filterProblem = relevantFilters.filter(
			(filter) => typeof filter === "string" && problemTypes.includes(filter)
		);

		if (filterStationNames.length > 0 && filterProblem.length > 0) {
			return issues.filter(
				(issue) =>
					filterStationNames.includes(issue.station.name) &&
					filterProblem.includes(issue.problem)
			);
		}
		if (filterStationNames.length > 0) {
			return issues.filter((issue) =>
				filterStationNames.includes(issue.station.name)
			);
		}
		if (filterProblem.length > 0) {
			return issues.filter((issue) => filterProblem.includes(issue.problem));
		}
	}
	return issues;
};

export const getIssueTableConfigDesktop = (
	issues: Station[],
	issueStationNames: string[],
	problemTypes: string[],
	deleteIssue: (issueId: string) => void,
	trackIssue: (issue: Issue) => void
): ColumnsType<Issue> => [
	{
		key: "problem",
		title: (
			<span className={styles["issue-table__column-header"]}>Problem</span>
		),
		dataIndex: "problem",
		width: 150,
		render: (problem: string) => {
			return (
				<Tag style={getProblemTagStyle(problem)}>
					<span>{problem}</span>
				</Tag>
			);
		},
		filters: problemTypes
			.map((problem) => {
				return { text: problem, value: problem };
			})
			.sort((a, b) => a.text.localeCompare(b.text)),
		onFilter: (problem, record) => record.problem === problem,
		filterIcon: <FilterOutlined />,
	},
	{
		key: "description",
		title: (
			<span className={styles["issue-table__column-header"]}>Beschreibung</span>
		),
		dataIndex: "description",
		render: (description: string) => {
			return (
				<span className={styles["issue-table__description"]}>
					{description}
				</span>
			);
		},
	},
	{
		key: "comments",
		title: (
			<CommentOutlined className={styles["issue-table__icon-column-title"]} />
		),
		dataIndex: "comments",
		width: 50,
		render: ({ length }: object[]) =>
			length !== 0 && (
				<CommentOutlined className={styles["issue-table__icon-flag"]} />
			),
		align: "center",
	},
	{
		key: "photos",
		title: (
			<PaperClipOutlined className={styles["issue-table__icon-column-title"]} />
		),
		dataIndex: "photos",
		width: 50,
		render: ({ length }: PhotoShallow[]) =>
			length !== 0 && (
				<FileImageOutlined className={styles["issue-table__icon-flag"]} />
			),
		align: "center",
	},

	{
		key: "station",
		title: (
			<span className={styles["issue-table__column-header"]}>Standort</span>
		),
		dataIndex: "station",
		width: 250,
		render: (station: StationPartial) => station.name,
		filters: issueStationNames
			.map((stationName) => {
				const { shortName } =
					issues.find((station) => station.name === stationName) || {};
				const fullStationName = `${
					shortName ? `${shortName} ` : ""
				}${stationName}`;
				return { text: fullStationName, value: stationName };
			})
			.sort((a, b) =>
				a.text
					.substring(a.text.indexOf(" ") + 1)
					.localeCompare(b.text.substring(b.text.indexOf(" ") + 1))
			),
		onFilter: (stationName, record) => record.station.name === stationName,
		filterSearch: true,
		filterIcon: <FilterOutlined />,
	},
	{
		key: "createdAt",
		title: <span className={styles["issue-table__column-header"]}>Datum</span>,
		dataIndex: "createdAt",
		width: 170,
		render: (reportedAtDate: string) => {
			return humanizedDateTimeFormat(new Date(reportedAtDate));
		},
	},
	{
		key: "actions",
		title: (
			<span className={styles["issue-table__column-header"]}>Aktionen</span>
		),
		width: 130,
		render: (_, issue) => {
			return (
				<>
					<BookOutlined
						className={styles["issue-table__action"]}
						style={issue.isTracked ? { color: "#4079E3" } : {}}
						twoToneColor="#eb2f96"
						title="Nachverfolgen"
						onClick={() => {
							trackIssue(issue);
						}}
					/>
					<RoleBasedRender allowedRoles={[UserRole.Admin, UserRole.Ranger]}>
						<DeleteOutlined
							className={styles["issue-table__action"]}
							title="Löschen"
							onClick={() => deleteIssue(issue.id)}
						/>
					</RoleBasedRender>
				</>
			);
		},
		align: "center",
	},
];
