import { NavigateFunction } from "react-router-dom";
import { IssueAdd } from "../../../../utilities/api/jelbi-dashboard-api";
import { InspectionFormValues } from "../../../Inspections/AddInspection/utils/add-inspection-form.types";

const submitInspectionIssue = (
	currentInspection: InspectionFormValues,
	submitValues: IssueAdd,
	navigate: NavigateFunction
) => {
	const newIssueList = [...currentInspection.issues];
	const currentIssue = newIssueList.find(
		({ equipment }) => equipment === submitValues.equipment
	);
	if (currentIssue) {
		newIssueList.splice(newIssueList.indexOf(currentIssue), 1, {
			...currentIssue,
			...submitValues,
		});
	} else {
		newIssueList.push(submitValues);
	}
	navigate("/inspections/add", {
		state: {
			currentInspection: {
				...currentInspection,
				issues: newIssueList,
			},
		},
		replace: true,
	});
};

export default submitInspectionIssue;
