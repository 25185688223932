import React from "react";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconExclamationCircleFilled } from "@tabler/icons-react";

const showDeleteInspectionSuccessfulNotification = () => {
	notifications.show({
		id: "successDeleteInspection",
		position: "top-center",
		message: `Begehung gelöscht!`,
		color: "green",
		icon: <IconCheck />,
	});
};

const showDeleteInspectionFailedNotification = () => {
	notifications.show({
		id: "failDeleteInspection",
		position: "top-center",
		message: `Begehung konnte nicht gelöscht werden!`,
		color: "red",
		icon: <IconExclamationCircleFilled />,
	});
};

export default {
	showDeleteInspectionSuccessfulNotification,
	showDeleteInspectionFailedNotification,
};
