import React, { ReactElement } from "react";
import { Avatar } from "antd";
import { VehicleType } from "../../utilities/api/jelbi-dashboard-api";
import {
	getVehicleTypeImage,
	getVehicleTypeName,
} from "../../utilities/client/vehicles";

type VehicleImageProps = {
	vehicleType: VehicleType;
	size?: "large" | "small" | "default" | number;
};

function VehicleImage({
	vehicleType: type,
	size = 64,
}: VehicleImageProps): ReactElement {
	const { PUBLIC_URL } = process.env;

	const imgSrc = (
		<img
			src={PUBLIC_URL + getVehicleTypeImage(type)}
			alt={getVehicleTypeName(type)}
			title={getVehicleTypeName(type)}
		/>
	);

	return <Avatar shape="square" size={size} src={imgSrc} />;
}

export default VehicleImage;
