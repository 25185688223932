import React, { ReactElement } from "react";
import { List, Image, Text, Group, Box, LoadingOverlay } from "@mantine/core";
import styles from "./VehicleAccumulatedList.module.scss";
import {
	getVehicleTypeImage,
	getVehicleTypeName,
} from "../../../../../utilities/client/vehicles";
import {
	VehicleAccumulatedInnerProps,
	getAccumulatedVehicleAmountData,
} from "../../../../../utilities/client/vehicle-accumulated";
import getVehicleAmount from "./utils/getVehicleAmount";

function VehicleAccumulatedList({
	availabilities,
	showMsps,
	availableVehicleTypes,
	showLoading,
}: VehicleAccumulatedInnerProps): ReactElement {
	const { PUBLIC_URL } = process.env;
	const accumulatedVehicleAmountOld = getAccumulatedVehicleAmountData(
		availableVehicleTypes,
		availabilities,
		showMsps
	);

	const vehicleListItems = accumulatedVehicleAmountOld.map((vehicle) => {
		return (
			<List.Item
				classNames={{
					item: styles.item,
					itemWrapper: styles["item-wrapper"],
					itemLabel: styles["item-label"],
				}}
				key={vehicle.vehicleType}
				icon={
					<Image
						src={PUBLIC_URL + getVehicleTypeImage(vehicle.vehicleType)}
						alt={getVehicleTypeName(vehicle.vehicleType)}
						w={34}
						ml={8}
					/>
				}
			>
				<Group wrap="nowrap" ml={12} gap={28} grow>
					<Text size="sm">{getVehicleTypeName(vehicle.vehicleType)}</Text>
					{getVehicleAmount(showMsps, vehicle.mspDistributions, vehicle.amount)}
				</Group>
			</List.Item>
		);
	});

	return !showLoading ? (
		<List className={styles["vehicle-accumulated-list"]} spacing={12}>
			{vehicleListItems}
		</List>
	) : (
		<Box pos="relative" h={200} w="100%" bg="gray.2">
			<LoadingOverlay visible title="Wird geladen..." />
		</Box>
	);
}

export default VehicleAccumulatedList;
