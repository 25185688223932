import { BerlinDistrict } from "../api/jelbi-dashboard-api";

enum BerlinDistrictLabel {
	Mitte = "Mitte",
	FriedrichshainKreuzberg = "Friedrichshain-Kreuzberg",
	Pankow = "Pankow",
	CharlottenburgWilmersdorf = "Charlottenburg-Wilmersdorf",
	Spandau = "Spandau",
	SteglitzZehlendorf = "Steglitz-Zehlendorf",
	TempelhofSchoeneberg = "Tempelhof-Schöneberg",
	Neukoelln = "Neukölln",
	TreptowKoepenick = "Treptow-Köpenick",
	MarzahnHellersdorf = "Marzahn-Hellersdorf",
	Lichtenberg = "Lichtenberg",
	Reinickendorf = "Reinickendorf",
	Potsdam = "Potsdam",
	Kleinmachnow = "Kleinmachnow",
}

const districtOptions = (
	Object.keys(BerlinDistrict) as (keyof typeof BerlinDistrict)[]
).map((district) => {
	return {
		key: BerlinDistrict[district],
		label: BerlinDistrictLabel[district],
		value: BerlinDistrict[district],
	};
});

export default districtOptions;
