import {
	VehicleType,
	Station,
	BerlinDistrict,
	GeoJsonPointType,
	GeoJsonPolygonType,
	StationState,
	Vehicles,
} from "../../../utilities/api/jelbi-dashboard-api";
import {
	StationFormSubmitValues as StationFormSubmitType,
	StationFormValues as StationFormType,
	StationFormValues,
} from "./station-form-types";

const STATION_EQUIPMENT_OPTIONS: {
	label: string;
	value: keyof StationFormValues;
}[] = [
	{ label: "Wifi", value: "hasWifi" },
	{ label: "Smart Bench", value: "hasSmartBench" },
	{ label: "Carsharing Ladesäule(n)", value: "hasCarChargingStation" },
];

const VEHICLES_TYPES_IN_STATION_FORM_ORDER = [
	VehicleType.Car,
	VehicleType.Bicycle,
	VehicleType.KickScooter,
	VehicleType.MotorScooter,
	VehicleType.StationBasedCar,
	VehicleType.CargoBike,
];

const getInitialVehicleValues = (
	vehicles: Vehicles = []
): StationFormType["vehiclesList"] =>
	VEHICLES_TYPES_IN_STATION_FORM_ORDER.map((formVehicleType) => {
		const currentValues = vehicles.find(
			({ vehicleType }) => vehicleType === formVehicleType
		);
		return {
			vehicleType: formVehicleType,
			capacity: currentValues?.capacity ?? 0,
			duration: currentValues?.expectation.duration.hours ?? 24,
			expected: currentValues?.expectation.expected ?? 0,
		};
	});

const getInitialValues = (station?: Station): StationFormType => {
	return {
		shortName: station ? station.shortName : "",
		name: station ? station.name : "",
		address: station ? station.address : "",
		district: station ? station.district : "",
		description: station ? station.description : null,
		state: station ? station.state : "",
		stateComment: station ? station.stateComment : null,
		isPoint: station ? station.isPoint : false,
		hasWifi: station ? station.hasWifi : false,
		hasSmartBench: station ? station.hasSmartBench : false,
		hasCarChargingStation: station ? station.hasCarChargingStation : false,
		geolocationCoordinates: station
			? JSON.stringify(station.geolocation.coordinates)
			: "",
		geofenceCoordinates: station
			? JSON.stringify(station.geofence.coordinates[0])
			: "",
		vehiclesList: getInitialVehicleValues(station?.vehicles),
		plannedClosures: {
			enabled: station ? station.plannedClosures.enabled : false,
		},
		plannedClosuresList:
			station && station.plannedClosures.closures
				? station.plannedClosures.closures.map(({ start, end, comment }) => ({
						start: new Date(start),
						end: new Date(end),
						comment,
					}))
				: [],
		isGeneratingEmailsToMsps: station
			? station.isGeneratingEmailsToMsps
			: false,
		route: station ? station.route : "",
	};
};

const getTransformedValues = (
	values: StationFormType
): StationFormSubmitType => ({
	shortName: values.shortName,
	name: values.name,
	address: values.address,
	district: values.district as BerlinDistrict,
	description: values.description,
	state: values.plannedClosures.enabled ? StationState.Active : values.state,
	stateComment: values.stateComment,
	isPoint: values.isPoint,
	hasWifi: values.hasWifi,
	hasSmartBench: values.hasSmartBench,
	hasCarChargingStation: values.hasCarChargingStation,
	geolocation: {
		type: GeoJsonPointType.Point,
		coordinates: values.geolocationCoordinates
			? JSON.parse(values.geolocationCoordinates)
			: [],
	},
	geofence: {
		type: GeoJsonPolygonType.Polygon,
		coordinates: values.geofenceCoordinates
			? [JSON.parse(values.geofenceCoordinates)]
			: [],
	},
	vehicles: values.vehiclesList
		? values.vehiclesList.map(
				({ vehicleType, capacity, duration, expected }) => ({
					vehicleType,
					capacity,
					expectation: { duration: { hours: duration }, expected },
				})
			)
		: [],
	plannedClosures: {
		enabled: values.plannedClosures.enabled,
		closures: values.plannedClosuresList
			? values.plannedClosuresList.map(({ start, end, comment }) => ({
					start: start.toISOString(),
					end: end.toISOString(),
					comment,
				}))
			: undefined,
	},
	isGeneratingEmailsToMsps: values.isGeneratingEmailsToMsps,
	route: values.route,
});

export {
	STATION_EQUIPMENT_OPTIONS,
	VEHICLES_TYPES_IN_STATION_FORM_ORDER,
	getInitialValues,
	getTransformedValues,
};
