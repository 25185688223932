import React from "react";
import { Text } from "@mantine/core";
import { format } from "date-fns";
import Section from "../Section";

type LastInspectionProps = {
	lastInspection: string;
};

function LastInspection({ lastInspection }: LastInspectionProps) {
	return (
		<Section title="Letzte Begehung">
			<Text size="sm">
				{format(new Date(lastInspection), `dd.MM.yyyy `)}
				<Text fs="italic" component="span">
					{format(new Date(lastInspection), `HH:mm`)}
				</Text>
			</Text>
		</Section>
	);
}
export default LastInspection;
