import { Msp, MspAddEdit } from "../../../utilities/api/jelbi-dashboard-api";

export type MspAddEditFormValues = Omit<MspAddEdit, "email"> & {
	email: Array<{ value: string }>;
};

export type MspAddEditFormProps = {
	msp?: Msp;
};

const emptyInitialValues = {
	name: "",
	email: [],
	micromobilityQuotaPercentage: 0,
	distributionsMspId: null,
	imageId: null,
};

export const generateInitialValuesFromMsp = (
	msp?: Msp
): MspAddEditFormValues =>
	msp
		? {
				name: msp.name,
				imageId: msp.imageId,
				distributionsMspId: msp.distributionsMspId,
				// `Math.trunc` is necessary to prevent rounding errors
				micromobilityQuotaPercentage: Math.trunc(
					// The multiplication by 100 is necessary as we store the
					// percentages in fractions of 1 in the backend
					msp.micromobilityQuotaPercentage * 100
				),
				// This conversion is necessary as Mantine forms don't work with arrays
				// of primitives when validating values
				email: msp.email.map((value) => ({ value })),
			}
		: emptyInitialValues;

export const mspAddEditFormValuesToMspAddEditValues = (
	values: MspAddEditFormValues
): MspAddEdit => ({
	...values,
	micromobilityQuotaPercentage:
		// The division by 100 is necessary as we store the
		// percentages in fractions of 1 in the backend
		// `Math.trunc` is necessary to prevent rounding errors
		Math.trunc(values.micromobilityQuotaPercentage) / 100,
	// This conversion is necessary as Mantine forms don't work with arrays
	// of primitives when validating values
	email: values.email.map(({ value }) => value),
});
