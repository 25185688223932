import { ok } from "@oazapfts/runtime";
import { useQuery } from "@tanstack/react-query";
import { getCityMicromobilityNoParking } from "../../api/jelbi-dashboard-api";
import { QUERY_NO_PARKING_ZONES } from "../query-keys";

const useNoParkingZones = () => {
	const { data } = useQuery({
		queryKey: [QUERY_NO_PARKING_ZONES],
		queryFn: () => ok(getCityMicromobilityNoParking()),
	});

	return data;
};

export default useNoParkingZones;
