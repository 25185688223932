import React, { ReactElement } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconExclamationCircleFilled } from "@tabler/icons-react";
import { modals } from "@mantine/modals";
import { LoadingOverlay, Text } from "@mantine/core";
import { Station, StationState } from "../../utilities/api/jelbi-dashboard-api";
import stationService from "../../services/stationService";
import StationForm from "./StationForm";
import { getStationQueryKey } from "../../utilities/client/query-keys";
import { StationFormSubmitValues } from "./utils/station-form-types";

function EditStation(): ReactElement {
	const { stationId } = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const { data: station } = useQuery({
		queryKey: getStationQueryKey(stationId || ""),
		queryFn: async () => {
			return stationService.getStationById(stationId || "");
		},
		// The query will not execute until routeMatch.params.stationId && !selectedStation evaluates to true
		enabled: !!stationId,
	});

	const editStationMutation = useMutation({
		mutationFn: async (stationChanges: StationFormSubmitValues) => {
			const response = await stationService.editStationById(stationId || "", {
				...stationChanges,
				state: stationChanges.state as StationState,
			});

			if (!response) {
				return Promise.reject();
			}
			return Promise.resolve(response);
		},
		onSuccess: (editedStation: Station) => {
			notifications.show({
				id: "successEditStation",
				position: "top-center",
				message: `Station ${editedStation.name} erfolgreich geändert!`,
				color: "green",
				icon: <IconCheck />,
			});

			stationService.updateQueryCacheEditedStation(queryClient, editedStation); // TODO: update QueryCache for single station

			if (editedStation.state === StationState.Inactive) {
				navigate("/stations/");
			} else {
				navigate(-1);
			}
		},
		onError: () => {
			notifications.show({
				id: "failEditStation",
				position: "top-center",
				message: "Station konnte nicht geändert werden!",
				color: "red",
				icon: <IconExclamationCircleFilled />,
			});
		},
	});

	const confirmEditStation = (changedStation: StationFormSubmitValues) => {
		if (changedStation.state === StationState.Inactive) {
			modals.openConfirmModal({
				title: "Station wirklich schließen?",
				children: (
					<Text>
						Soll {changedStation.name} wirklich geschlossen werden? Eine
						Änderungen des Status ist dann nicht mehr möglich!
					</Text>
				),
				labels: { confirm: "Station schließen", cancel: "Abbrechen" },
				onConfirm: () => {
					editStationMutation.mutate(changedStation);
				},
			});
		} else {
			editStationMutation.mutate(changedStation);
		}
	};

	return (
		<>
			<LoadingOverlay visible={!station} />
			{station && (
				<StationForm onSubmit={confirmEditStation} station={station} />
			)}
		</>
	);
}

export default EditStation;
