/* eslint-disable react/jsx-props-no-spreading */
// for reference:
// https://mantine.dev/form/get-input-props/#getinputprops-handler

import { ok } from "@oazapfts/runtime";
import React, { ReactElement, useEffect, useState } from "react";
import {
	ActionIcon,
	Button,
	Group,
	TextInput,
	NumberInput,
	Text,
	Select,
	Slider,
	Stack,
	InputLabel,
	Title,
	Grid,
} from "@mantine/core";
import { isEmail, isInRange, isNotEmpty, useForm } from "@mantine/form";
import { modals } from "@mantine/modals";
import { IconTrashX } from "@tabler/icons-react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
	MspAddEdit,
	addMsp,
	editMsp,
	deleteMsp,
} from "../../../utilities/api/jelbi-dashboard-api";
import {
	MspAddEditFormProps,
	MspAddEditFormValues,
	generateInitialValuesFromMsp,
	mspAddEditFormValuesToMspAddEditValues,
} from "./MspAddEditForm.util";
import notifications from "./MspAddEditForm.notifications";
import useUnassignedDistributionsMspIds from "../../../utilities/client/hooks/useUnassignedDistributionsMspIds";
import useMaxAvailableMicromobilityQuota from "./useMaxAvailableMicromobilityQuota";
import {
	QUERY_KEY_ALL_MSPS,
	QUERY_KEY_UNASSIGNED_DISTRIBUTIONS_MSP_IDS,
} from "../../../utilities/client/query-keys";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import classes from "./MspAddEditForm.module.scss";

function MspAddEditForm({ msp }: MspAddEditFormProps): ReactElement {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const maxAvailableQuota = useMaxAvailableMicromobilityQuota(msp?.id);
	const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
	const isMobile = useIsMobileView();
	const form = useForm<
		MspAddEditFormValues,
		(values: MspAddEditFormValues) => MspAddEdit
	>({
		validateInputOnChange: true,
		initialValues: generateInitialValuesFromMsp(msp),
		validate: {
			name: isNotEmpty("Bitte Namen angeben"),
			email: { value: isEmail("Ungültige E-Mail") },
			micromobilityQuotaPercentage: isInRange(
				{ min: 0, max: maxAvailableQuota },
				`Muss zwischen 0 und ${maxAvailableQuota} sein`
			),
		},
		transformValues: mspAddEditFormValuesToMspAddEditValues,
	});

	useEffect(() => {
		if (form.isValid() && form.isDirty()) {
			setIsSaveButtonEnabled(true);
		} else {
			setIsSaveButtonEnabled(false);
		}
	}, [form]);

	const unassignedDistributionsMspIds = useUnassignedDistributionsMspIds();

	// this prevents a possible bug after assigning an mspId and directly
	// reopening the corresponding MSP in the UI for editing again
	const onSuccessfulSubmit = () => {
		queryClient.invalidateQueries({
			queryKey: [QUERY_KEY_UNASSIGNED_DISTRIBUTIONS_MSP_IDS],
		});
		queryClient.invalidateQueries({ queryKey: [QUERY_KEY_ALL_MSPS] });
		navigate(-1);
	};

	const emailFields = form.getValues().email.map((_, index) => (
		<TextInput
			placeholder="Bitte gib eine E-Mail an"
			key={form.key(`email.${index}`)}
			{...form.getInputProps(`email.${index}.value`)}
			rightSection={
				<ActionIcon
					onClick={() => form.removeListItem("email", index)}
					color="red"
					variant="transparent"
				>
					<IconTrashX />
				</ActionIcon>
			}
		/>
	));

	const openDeleteModal = () => {
		if (msp) {
			modals.openConfirmModal({
				title: "Wirklich löschen?",
				children: (
					<Text size="sm">Willst du den MSP {msp.name} wirklich löschen?</Text>
				),
				labels: {
					confirm: "Löschen",
					cancel: "Abbrechen",
				},
				confirmProps: { color: "red", fullWidth: isMobile },
				cancelProps: { fullWidth: isMobile },
				groupProps: {
					style: { flexDirection: isMobile ? "column-reverse" : "row" },
				},
				onConfirm: () =>
					ok(deleteMsp(msp.id))
						.then(() => {
							notifications.showDeleteMspSuccessfulNotification(msp.name);
							onSuccessfulSubmit();
						})
						.catch(() =>
							notifications.showDeleteMspFailedNotification(msp.name)
						),
				withCloseButton: false,
			});
		}
	};

	const isDistributionsMspIdSelectDisabled =
		!msp?.distributionsMspId &&
		(unassignedDistributionsMspIds === undefined ||
			unassignedDistributionsMspIds.length === 0);

	const distributionsMspIdSelection = [
		...(unassignedDistributionsMspIds ?? []),
		...(msp?.distributionsMspId ? [msp.distributionsMspId] : []),
	];

	return (
		<>
			<Title className={classes.heading} order={3}>
				{msp ? "MSP ändern" : "MSP anlegen"}
			</Title>
			<form
				className={classes.container}
				onSubmit={form.onSubmit((values) => {
					if (msp !== undefined) {
						ok(editMsp(msp.id, values))
							.then(() => {
								notifications.showEditMspSuccessfulNotification(values.name);
								onSuccessfulSubmit();
							})
							.catch(() => notifications.showEditMspFailedNotification());
					} else {
						ok(addMsp(values))
							.then(() => {
								notifications.showAddMspSuccessfulNotification(values.name);
								onSuccessfulSubmit();
							})
							.catch(() => notifications.showAddMspFailedNotification());
					}
				})}
			>
				<Grid gutter={{ base: 28, lg: 60 }}>
					<Grid.Col span={{ base: 12, lg: 6 }}>
						<TextInput
							withAsterisk
							label="Name"
							placeholder="Bitte gib einen Namen an"
							key={form.key("name")}
							{...form.getInputProps("name")}
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<Stack gap={12}>
							<NumberInput
								withAsterisk
								label="Kontingent Mikromobilität"
								placeholder="0"
								suffix="%"
								step={1}
								min={0}
								max={maxAvailableQuota}
								key={`${form.key("micromobilityQuotaPercentage")}-numberInput`}
								{...form.getInputProps("micromobilityQuotaPercentage")}
							/>
							<Slider
								label={null}
								step={1}
								min={0}
								max={maxAvailableQuota}
								key={`${form.key("micromobilityQuotaPercentage")}-slider`}
								{...form.getInputProps("micromobilityQuotaPercentage")}
							/>
						</Stack>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<Select
							clearable
							placeholder={
								isDistributionsMspIdSelectDisabled
									? "Keine weiteren Trafi-IDs verfügbar"
									: "Wählen Sie eine Trafi-ID"
							}
							data={distributionsMspIdSelection}
							disabled={isDistributionsMspIdSelectDisabled}
							value={msp?.distributionsMspId}
							label="Trafi-ID"
							key={form.key("distributionsMspId")}
							{...form.getInputProps("distributionsMspId")}
							comboboxProps={{ withinPortal: false }}
						/>
					</Grid.Col>

					<Grid.Col span={{ base: 12, lg: 6 }}>
						<div>
							<InputLabel>E-MAIL</InputLabel>
							<Stack gap={16}>
								{emailFields}
								<Group justify="flex-end">
									<Button
										onClick={() => form.insertListItem("email", { value: "" })}
									>
										{emailFields.length ? "Weitere" : ""} E-Mail hinzufügen
									</Button>
								</Group>
							</Stack>
						</div>
					</Grid.Col>

					<Grid.Col span={{ base: 12 }}>
						<Group
							justify="flex-end"
							gap={16}
							style={{ flexDirection: isMobile ? "column-reverse" : "row" }}
						>
							<Button
								variant="outline"
								fullWidth={isMobile}
								onClick={() => {
									navigate(-1);
								}}
							>
								Zurück
							</Button>
							{msp && (
								<Button
									variant="default"
									fullWidth={isMobile}
									onClick={openDeleteModal}
								>
									Löschen
								</Button>
							)}

							<Button
								type="submit"
								fullWidth={isMobile}
								disabled={!isSaveButtonEnabled}
							>
								Speichern
							</Button>
						</Group>
					</Grid.Col>
				</Grid>
			</form>
		</>
	);
}

export default MspAddEditForm;
