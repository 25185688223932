// disable eslint for using props spreading as it's best practices in https://mantine.dev/form/use-form/
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useEffect, useReducer } from "react";
import { Flex, Input } from "@mantine/core";
import { IconCamera, IconPlus } from "@tabler/icons-react";
import { useForm } from "@mantine/form";
import {
	Photo,
	PhotoUpdateActionType,
	PhotosReducer,
	photosReducer,
} from "./utils/photos-reducer";
import uploadImage from "./utils/upload-image";
import {
	showTooManyAdditionalPhotosNotification,
	showTooManyPhotosNotification,
	showUploadFailedNotification,
} from "./utils/notifications";
import ImagePreview from "./ImagePreview";
import { getPhoto } from "../../utilities/api/jelbi-dashboard-api";
import UploadButton from "./UploadButton/UploadButton";
import useIsMobileView from "../../utilities/client/hooks/useIsMobileView";

export type { Photo } from "./utils/photos-reducer";

type PhotoUploadProps = ReturnType<
	ReturnType<typeof useForm>["getInputProps"]
> & { maxItems: number; defaultValue?: Photo[]; required?: boolean };

function PhotoUpload({
	maxItems,
	onChange,
	defaultValue,
	required = false,
}: PhotoUploadProps): ReactElement {
	const isMobile = useIsMobileView();

	const [photos, dispatchPhotos] = useReducer<PhotosReducer>(
		photosReducer,
		defaultValue ?? []
	);
	const deletePhoto = (id: string) =>
		dispatchPhotos({ type: PhotoUpdateActionType.Delete, id });

	const addPhotos = (files: File[]) => {
		const photosCountLeft = maxItems - photos.length;
		if (files.length <= photosCountLeft) {
			dispatchPhotos({ type: PhotoUpdateActionType.Add, files });
			files.forEach((file) => {
				uploadImage(file)
					.then((id) =>
						dispatchPhotos({
							type: PhotoUpdateActionType.UploadComplete,
							file,
							uploadedId: id,
						})
					)
					.catch(() => {
						showUploadFailedNotification(file.name);
						dispatchPhotos({
							type: PhotoUpdateActionType.RemoveFailedUpload,
							file,
						});
					});
			});
		} else if (photos.length === 0) {
			showTooManyPhotosNotification(maxItems);
		} else {
			showTooManyAdditionalPhotosNotification(photosCountLeft);
		}
	};

	useEffect(() => onChange(photos), [photos]);

	const previews = photos.map((photo) => (
		<ImagePreview
			key={photo.id}
			photo={photo}
			onDelete={() => deletePhoto(photo.id)}
		/>
	));

	useEffect(() => {
		if (defaultValue) {
			defaultValue.forEach(({ id }: Photo) =>
				getPhoto(id).then((response) => {
					if (response.status === 200) {
						dispatchPhotos({
							type: PhotoUpdateActionType.UpdateDashboardPhoto,
							data: response.data,
							id,
						});
					} else {
						deletePhoto(id);
					}
				})
			);
		}
	}, []);

	return (
		<Input.Wrapper
			label={`Fotos (${photos.length}/${maxItems})`}
			required={required}
		>
			<Flex wrap="wrap" gap={16}>
				{photos.length < maxItems && (
					<>
						<UploadButton
							icon={<IconPlus size={36} />}
							label="Hochladen"
							onChange={addPhotos}
						/>
						{isMobile && (
							<UploadButton
								icon={<IconCamera size={36} />}
								label="Kamera"
								capture="environment"
								onChange={addPhotos}
							/>
						)}
					</>
				)}
				{previews}
			</Flex>
		</Input.Wrapper>
	);
}

export default PhotoUpload;
