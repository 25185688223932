// disable eslint for using props spreading as it's best practices in https://mantine.dev/form/use-form/
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement, useState } from "react";
import { Checkbox, InputLabel, SimpleGrid, Stack, Text } from "@mantine/core";
import stationEquipmentOptions from "../../../utilities/client/station-equipment";
import { InspectionUseFormReturnType } from "./utils/add-inspection-form.types";

type InspectionEquipmentInputProps = {
	form: InspectionUseFormReturnType;
};

function InspectionEquipmentInput({
	form,
}: InspectionEquipmentInputProps): ReactElement {
	const [acceptedEquipments, setAcceptedEquipments] = useState<string[]>(
		stationEquipmentOptions
			.map((option) => option.key)
			.filter((option) => !form.getValues().detectedIssues.includes(option))
	);

	return (
		<Stack gap={0}>
			<InputLabel>Ausstattung</InputLabel>
			<Text size="sm" mb="16px">
				Zu allen nicht ausgewählten Ausstattungen werden anschließend
				Problemmeldungen vorbereitet.
			</Text>
			<Checkbox.Group
				value={acceptedEquipments}
				onChange={(equipmentValues) => {
					setAcceptedEquipments(equipmentValues);
					form.setValues({
						detectedIssues: stationEquipmentOptions
							.map((option) => option.key)
							.filter((option) => !equipmentValues.includes(option)),
						issues: form
							.getValues()
							.issues.filter(
								({ equipment }) => !equipmentValues.includes(equipment)
							),
					});
				}}
			>
				<SimpleGrid cols={{ base: 2, lg: 4 }}>
					{stationEquipmentOptions.map((equipmentOption) => (
						<Checkbox
							key={equipmentOption.key}
							label={equipmentOption.label}
							value={equipmentOption.value}
							checked={!form.key("detectedIssues.Beleuchtung")}
						/>
					))}
				</SimpleGrid>
			</Checkbox.Group>
		</Stack>
	);
}

export default InspectionEquipmentInput;
