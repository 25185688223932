import { useState, useEffect } from "react";
import { MspId } from "../../api/jelbi-dashboard-api";
import useMspFromDistributionsMspId from "./useMspFromDistributionsMspId";

const useMspImage = (mspId: MspId) => {
	const [imageId, setImageId] = useState<string>("");
	const msp = useMspFromDistributionsMspId(mspId);

	useEffect(() => {
		setImageId(msp?.imageId ?? "");
	}, [msp]);

	return imageId;
};

export default useMspImage;
