import { AddIssueFormValues } from "./add-issue-types";
import {
	InspectionIssueAdd,
	IssueAdd,
} from "../../../../utilities/api/jelbi-dashboard-api";

export type AddIssueTransformValues<To = IssueAdd | InspectionIssueAdd> = (
	values: AddIssueFormValues
) => To;

export const transformValuesToAddIssue: AddIssueTransformValues<IssueAdd> = (
	values
) => ({
	createdAt: values.createdAt.toISOString(),
	createdBy: values.createdBy,
	description: values.description,
	equipment: values.equipment,
	problem: values.problem,
	stationId: values.stationId,
	...(values.photos ? { photoIds: values.photos.map(({ id }) => id) } : {}),
});

export const transformValuesToInspectionIssueAdd: AddIssueTransformValues<
	InspectionIssueAdd
> = (values) => ({
	description: values.description,
	equipment: values.equipment,
	problem: values.problem,
	...(values.photos ? { photoIds: values.photos.map(({ id }) => id) } : {}),
});
