/* eslint-disable react/jsx-props-no-spreading */
// for reference:
// https://mantine.dev/form/get-input-props/#getinputprops-handler

import React from "react";
import { useForm } from "@mantine/form";
import {
	Grid,
	Group,
	Stack,
	Button,
	Checkbox,
	RadioGroup,
	Radio,
	Autocomplete,
	Text,
} from "@mantine/core";
import { Station } from "../../../../../../utilities/api/jelbi-dashboard-api";
import {
	MapFilterOptions,
	defaultMapFilters,
	setIssueFilterOption,
	setStationTypeFilterOption,
	transformStationsToRouteFilterOptions,
	transformStationsToStationFilterOptions,
} from "./helper";

type MapFilterDialogProps = {
	callbackFunctions: {
		setMapFilters: (filterOptions: MapFilterOptions) => void;
		setStationSearch: (stationId: string) => void;
		closeModal: () => void;
		setIsMapFilterActive: (isActive: boolean) => void;
	};
	stations: Station[];
	isMapFilterActive: boolean;
	mapFilters: MapFilterOptions;
};

function MapFilterDialog({
	callbackFunctions,
	stations,
	mapFilters,
}: MapFilterDialogProps): JSX.Element {
	const { setStationSearch, setIsMapFilterActive, setMapFilters, closeModal } =
		callbackFunctions;
	const issueFilters = Object.entries(mapFilters)
		.filter(([, value]) => typeof value === "boolean" && value === true)
		.map(([key]) => key);
	const form = useForm({
		mode: "uncontrolled",
		initialValues: {
			stationName: "",
			routeSearch: mapFilters.route,
			issueTypes: issueFilters,
			stationType: mapFilters.stationTypeFilterOption,
		},
	});
	const stationsAutocompleteOptions =
		transformStationsToStationFilterOptions(stations);
	const routeAutocompleteOptions =
		transformStationsToRouteFilterOptions(stations);

	return (
		<div style={{ maxWidth: "600px" }}>
			<form>
				<Grid gutter={{ base: 28, lg: 32 }}>
					<Grid.Col span={12}>
						<Autocomplete
							label={
								<Text c="grey" tt="uppercase">
									Station suchen
								</Text>
							}
							placeholder="Stationsnamen eingeben"
							data={stationsAutocompleteOptions}
							limit={10}
							comboboxProps={{ zIndex: 1001 }}
							{...form.getInputProps("stationName")}
							key={form.key("stationName")}
							onOptionSubmit={(value) => {
								const selectedOption = stationsAutocompleteOptions.find(
									(opt) => opt.value === value
								);
								if (selectedOption) {
									setStationSearch(selectedOption.key);
									closeModal();
								}
							}}
						/>
					</Grid.Col>

					<Grid.Col span={12}>
						<Autocomplete
							label={
								<Text c="grey" tt="uppercase">
									Route anzeigen
								</Text>
							}
							placeholder="Route eingeben"
							data={routeAutocompleteOptions}
							limit={10}
							comboboxProps={{ zIndex: 1001 }}
							{...form.getInputProps("routeSearch")}
							key={form.key("routeSearch")}
							onOptionSubmit={(value) => {
								const selectedOption = routeAutocompleteOptions.find(
									(opt) => opt.value === value
								);
								if (selectedOption) {
									setMapFilters({ ...mapFilters, route: selectedOption.key });
									setIsMapFilterActive(true);
								}
							}}
						/>
					</Grid.Col>

					<Grid.Col span={12}>
						<Checkbox.Group
							label={
								<Text c="grey" tt="uppercase">
									Problemmeldungen
								</Text>
							}
							{...form.getInputProps("issueTypes")}
							key={form.key("issueTypes")}
							onChange={(checkedIssues: string[]) => {
								setIssueFilterOption(
									checkedIssues,
									mapFilters,
									setMapFilters,
									setIsMapFilterActive
								);
							}}
						>
							<Group>
								<Checkbox
									w={150}
									value="showDamageIssues"
									label="Beschädigung"
								/>
								<Checkbox w={150} value="showDefectIssues" label="Defekt" />
								<Checkbox w={150} value="showOtherIssues" label="Sonstiges" />
								<Checkbox
									w={150}
									value="showTrafficSafetyIssues"
									label="Verkehrssicherheit"
								/>
								<Checkbox
									w={150}
									value="showPollutionIssues"
									label="Verschmutzung"
								/>
							</Group>
						</Checkbox.Group>
					</Grid.Col>

					<Grid.Col span={12}>
						<RadioGroup
							label={
								<Text c="grey" tt="uppercase">
									Stationstyp
								</Text>
							}
							{...form.getInputProps("stationType")}
							key={form.key("stationType")}
							onChange={(value) => {
								setStationTypeFilterOption(
									value,
									mapFilters,
									setMapFilters,
									setIsMapFilterActive
								);
							}}
						>
							<Stack gap={12}>
								<Radio value="showAllTypes" label="Alle Stationstypen" />
								<Radio value="showPoints" label="Nur Jelbi-Punkte" />
								<Radio value="showStations" label="Nur Jelbi-Stationen" />
							</Stack>
						</RadioGroup>
					</Grid.Col>

					<Grid.Col span={12}>
						<Button
							onClick={() => {
								setMapFilters(defaultMapFilters);
								form.setInitialValues({
									stationName: "",
									routeSearch: "",
									issueTypes: [],
									stationType: defaultMapFilters.stationTypeFilterOption,
								});
								form.reset();
								setIsMapFilterActive(false);
							}}
							variant="subtle"
						>
							Filter zurücksetzen
						</Button>
					</Grid.Col>
				</Grid>
			</form>
		</div>
	);
}

export default MapFilterDialog;
