import React from "react";
import L from "leaflet";

import { renderToString } from "react-dom/server";

import { Marker, Popup, GeoJSON } from "react-leaflet";
import styles from "./OtherMicromobilityAreaMarker.module.scss";
import { MultiPolygonGeofence } from "../../../../utilities/api/jelbi-dashboard-api";

type OtherMicromobilityAreaMarkerProps = {
	geolocation: GeoJSON.Point;
	detectionArea: MultiPolygonGeofence;
	opacity: number;
	description: string;
	zoomLevel: number;
};

function OtherMicromobilityAreaMarker({
	geolocation,
	detectionArea,
	opacity,
	description,
	zoomLevel,
}: OtherMicromobilityAreaMarkerProps): JSX.Element {
	const ZOOM_LEVEL_TO_SHOW_CIRCLE = 18;
	const isDetectionAreaShown =
		zoomLevel && zoomLevel >= ZOOM_LEVEL_TO_SHOW_CIRCLE;
	const { PUBLIC_URL } = process.env;
	const iconImage = `${PUBLIC_URL}/icons-map/map-pin-line.svg`;
	const [lng, lat] = geolocation.coordinates;

	const icon = new L.DivIcon({
		html: renderToString(
			<div>
				<img
					className={styles.icon}
					src={iconImage}
					alt={geolocation.coordinates.toString()}
				/>
			</div>
		),
		className: styles.badge__badgeBackground,
		popupAnchor: [0, -17],
		iconAnchor: [19, 19],
	});

	return (
		<div key={`${geolocation.coordinates.toString()}-icon`}>
			{isDetectionAreaShown && (
				<GeoJSON
					style={{
						color: "var(--mantine-color-gray-6)",
						fillColor: "var(--mantine-color-gray-5)",
					}}
					data={detectionArea}
				/>
			)}
			<Marker
				alt={`${description}-${geolocation.coordinates.toString()}`}
				key={geolocation.coordinates.toString()}
				position={[lat, lng]}
				icon={icon}
				opacity={opacity}
				eventHandlers={{
					click: (e) => e.target.openPopup(),
				}}
			>
				<Popup
					key={`${geolocation.coordinates.toString()}-popup`}
					className={styles.popup}
				>
					<span className={styles.title}>{description}</span>
				</Popup>
			</Marker>
		</div>
	);
}

export default OtherMicromobilityAreaMarker;
