import imageCompression from "browser-image-compression";
import { postPhoto } from "../../../utilities/api/jelbi-dashboard-api";

const photoUploadOptions = {
	maxSizeMB: 1,
	maxWidthOrHeight: 1920,
	useWebWorker: true,
};

const toBase64 = (photo: File) =>
	new Promise<string>((resolve) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result as string);
		};

		reader.readAsDataURL(photo);
	});

const uploadImage = (file: File) =>
	imageCompression(file, photoUploadOptions)
		.then(toBase64)
		.then((data) => postPhoto({ data }))
		.then(({ status, data }) => {
			if (status === 201) {
				return data.id;
			}

			throw data;
		});

export default uploadImage;
