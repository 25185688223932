import { ok } from "@oazapfts/runtime";
import {
	InspectionCollection,
	getInspections,
	deleteInspection,
	Problem,
} from "../utilities/api/jelbi-dashboard-api";

const fetchInspections = async (
	start?: string,
	end?: string,
	page?: number,
	maxItems?: number
): Promise<InspectionCollection> => {
	return ok(getInspections({ start, end, page, maxItems }));
};

const removeInspection = async (
	inspectionId: string
): Promise<Problem | unknown> => {
	return ok(deleteInspection(inspectionId));
};

export default {
	fetchInspections,
	removeInspection,
};
