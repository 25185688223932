import { isNotEmpty } from "@mantine/form";
import { FormRulesRecord } from "@mantine/form/lib/types";
import { AddIssueFormValues } from "./add-issue-types";

const validate: FormRulesRecord<AddIssueFormValues> = {
	createdAt: isNotEmpty("Bitte Datum angeben"),
	description: isNotEmpty("Bitte Beschreibung hinzufügen"),
	equipment: isNotEmpty("Bitte Ausstattung auswählen"),
	problem: isNotEmpty("Bitte Problem auswählen"),
	stationId: isNotEmpty("Bitte Station auswählen"),
	photos: {
		isProcessing: (status) => status === true,
	},
};

export default validate;
