import { UserRole } from "../api/jelbi-dashboard-api";

export const getHighestUserRole = (userRoles: UserRole[]): UserRole | null => {
	if (userRoles.includes(UserRole.Admin)) {
		return UserRole.Admin;
	}

	if (userRoles.includes(UserRole.Support)) {
		return UserRole.Support;
	}

	if (userRoles.includes(UserRole.Ranger)) {
		return UserRole.Ranger;
	}

	if (userRoles.includes(UserRole.Member)) {
		return UserRole.Member;
	}

	return null;
};

export const mapUserRoleToString = (role: UserRole): string => {
	switch (role) {
		case UserRole.Ranger:
			return "Ranger";
			break;
		case UserRole.Member:
			return "Jelbi-Team";
			break;
		case UserRole.Support:
			return "Support";
			break;
		case UserRole.Admin:
			return "Alleskönner";
			break;
		default:
			return "";
	}
};

export const isRangerOrHigher = (roles: UserRole[]): boolean => {
	return (
		roles.includes(UserRole.Support) ||
		roles.includes(UserRole.Admin) ||
		roles.includes(UserRole.Ranger)
	);
};

export const isAdminOrHigher = (roles: UserRole[]): boolean => {
	return roles.includes(UserRole.Support) || roles.includes(UserRole.Admin);
};
