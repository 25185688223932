import React from "react";
import styles from "./index.module.scss";

function PlannedClosureBadge(): JSX.Element {
	const { PUBLIC_URL } = process.env;
	return (
		<img
			className={styles.icon}
			src={`${PUBLIC_URL}/icons-map/jelbi_clock_badge.svg`}
			alt="Geplante Sperrung Badge"
		/>
	);
}

export default PlannedClosureBadge;
