import React, { ReactElement } from "react";
import { useQuery } from "@tanstack/react-query";
import { subYears } from "date-fns";
import { Link } from "react-router-dom";
import { Button, Group, SimpleGrid, Stack, Tabs, Title } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import issuesService from "../../services/issuesService";
import { QUERY_KEY_ALL_TRACKED_ISSUES } from "../../utilities/client/query-keys";
import IssuesTable from "./IssuesTable";
import classes from "./StationIssues.module.scss";

function StationIssues(): ReactElement {
	const { data: trackedIssuesCollection } = useQuery({
		queryKey: [QUERY_KEY_ALL_TRACKED_ISSUES],
		queryFn: () =>
			issuesService.fetchIssues({
				start: subYears(new Date(), 1).toISOString(),
				end: new Date().toISOString(),
				isTracked: true,
			}),
	});

	return (
		<Stack className={classes.container}>
			<SimpleGrid>
				<Title order={3}>Problemmeldungen</Title>
				<Group justify="flex-end">
					<Link to="add">
						<Button leftSection={<IconPlus />}>Neue Problemmeldung</Button>
					</Link>
				</Group>
			</SimpleGrid>
			<Tabs defaultValue="all">
				<Tabs.List>
					<Tabs.Tab value="all">Alle</Tabs.Tab>
					<Tabs.Tab value="bookmarks">
						Zur Nachverfolgung
						{trackedIssuesCollection?.results.length
							? ` (${trackedIssuesCollection.results.length})`
							: ""}
					</Tabs.Tab>
				</Tabs.List>

				<Tabs.Panel value="all">
					<IssuesTable issuesPerPage={7} />
				</Tabs.Panel>
				<Tabs.Panel value="bookmarks">
					<IssuesTable issuesPerPage={7} showTrackedIssuesOnly />
				</Tabs.Panel>
			</Tabs>
		</Stack>
	);
}

export default StationIssues;
