import { ReactNode } from "react";
import { UserRole } from "../utilities/api/jelbi-dashboard-api";
import useGetUserRoles from "../utilities/client/hooks/useGetUserRoles";

type RoleBasedRenderProps = {
	children: ReactNode;
	allowedRoles: UserRole[];
};

function RoleBasedRender({ children, allowedRoles }: RoleBasedRenderProps) {
	const userRoles = useGetUserRoles();

	const isCurrentUserAllowed =
		userRoles.includes(UserRole.Support) ||
		userRoles.some((role) => allowedRoles.includes(role));

	if (isCurrentUserAllowed) {
		return children;
	}
}

export default RoleBasedRender;
