import React from "react";
import OtherMicromobilityAreaMarker from "./OtherMicromobilityAreaMarker";
import { CityMicromobilityArea } from "../../../../utilities/api/jelbi-dashboard-api";

type OtherMicromobilityAreaMarkersProps = {
	zoomLevel: number;
	micromobilityAreas: CityMicromobilityArea[];
};

function OtherMicromobilityAreaMarkers({
	zoomLevel,
	micromobilityAreas,
}: OtherMicromobilityAreaMarkersProps): JSX.Element {
	const otherMicromobilityAreaMarkers = micromobilityAreas.map((area) => (
		<OtherMicromobilityAreaMarker
			geolocation={area.geolocation}
			detectionArea={area.multiPolygonGeofence}
			opacity={1}
			description={area.description}
			zoomLevel={zoomLevel}
		/>
	));

	return <div>{otherMicromobilityAreaMarkers}</div>;
}

export default OtherMicromobilityAreaMarkers;
