import React, { ReactElement, useState } from "react";
import {
	CloseOutlined,
	DownloadOutlined,
	RotateLeftOutlined,
	RotateRightOutlined,
	ZoomInOutlined,
	ZoomOutOutlined,
} from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { Col, Modal, Row, Image, Space } from "antd";
import IssueComment from "../IssueComments";
import { Issue, getPhoto } from "../../../utilities/api/jelbi-dashboard-api";
import useIsMobileView from "../../../utilities/client/hooks/useIsMobileView";
import styles from "./index.module.scss";
import {
	humanizedDateForFilename,
	humanizedDateTimeFormat,
} from "../../../utilities/client/date-format";
import { getPhotosQueryKey } from "../../../utilities/client/query-keys";
import Spinner from "../../Spinner";

const COLUMN_SPAN_DESKTOP = 12;
const COLUMN_SPAN_MOBILE = 24;

type ReadIssueProps = {
	issue: Issue;
	onClose?: () => void;
};

function ReadIssue({ issue, onClose }: ReadIssueProps): ReactElement {
	const [isModalVisible, setModalVisible] = useState<boolean>(true);
	const isMobile = useIsMobileView();
	const columnSpan = isMobile ? COLUMN_SPAN_MOBILE : COLUMN_SPAN_DESKTOP;
	const [selectedPhotoUri, setSelectedPhotoUri] = useState("");

	const getPhotos = useQuery({
		queryKey: [getPhotosQueryKey(issue.id)],
		queryFn: async () =>
			Promise.all(issue.photos.map(async (photo) => getPhoto(photo.id))),
	});

	const onDownload = () => {
		const url = selectedPhotoUri;
		const link = document.createElement("a");
		link.href = url;
		const date = humanizedDateForFilename(new Date(issue.createdAt));
		link.download = `issue-photo-${date}.jpeg`;
		document.body.appendChild(link);
		link.click();
		URL.revokeObjectURL(url);
		link.remove();
	};

	let photos: JSX.Element | null = null;

	if (getPhotos.data && getPhotos.isSuccess) {
		const photosData = getPhotos.data.map((response) => response.data);
		const photosCollection = photosData.map((photoData) => {
			const photoUri = `data:image/jpeg;base64,${photoData}`;
			return (
				<div className={styles["photo-wrapper"]} key={Math.random()}>
					<Image
						width={100}
						height={100}
						style={{ objectFit: "cover" }}
						src={photoUri}
						preview={{
							closeIcon: <CloseOutlined style={{ fontSize: 28 }} />,
						}}
						onClick={() => setSelectedPhotoUri(photoUri)}
					/>
				</div>
			);
		});
		photos = (
			<Image.PreviewGroup
				preview={{
					// eslint-disable-next-line react/no-unstable-nested-components
					toolbarRender: (
						_,
						{
							transform: { scale },
							actions: { onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
						}
					) => (
						<Space size={12} className={styles["toolbar-wrapper"]}>
							<DownloadOutlined
								className={styles["toolbar-action"]}
								onClick={onDownload}
							/>
							<RotateLeftOutlined
								className={styles["toolbar-action"]}
								onClick={onRotateLeft}
							/>
							<RotateRightOutlined
								className={styles["toolbar-action"]}
								onClick={onRotateRight}
							/>
							<ZoomOutOutlined
								className={styles["toolbar-action"]}
								disabled={scale === 1}
								onClick={onZoomOut}
							/>
							<ZoomInOutlined
								className={styles["toolbar-action"]}
								disabled={scale === 50}
								onClick={onZoomIn}
							/>
						</Space>
					),
				}}
			>
				{photosCollection}
			</Image.PreviewGroup>
		);
	}
	const photoPlaceholders = issue.photos.map((photo) => (
		<Spinner height={100} width={100} showBorder key={photo.id} />
	));

	return (
		<Modal
			className={styles["read-issue"]}
			open={isModalVisible}
			onOk={() => {
				setModalVisible(false);
			}}
			cancelButtonProps={{ style: { display: "none" } }}
			destroyOnClose
			afterClose={onClose}
			maskClosable={false}
			closeIcon={<CloseOutlined className={styles["close-button"]} />}
			onCancel={() => setModalVisible(false)}
			width={1000}
			okText="Schließen"
			title={<h2 className={styles.title}>Problemmeldung</h2>}
		>
			<Row className={styles["table-row"]} gutter={[16, 8]}>
				<Col span={columnSpan} className={styles["issue-details"]}>
					<p>STANDORT</p>
					{issue.station.name}
				</Col>
				<Col span={columnSpan} className={styles["issue-details"]}>
					<p>DATUM</p>
					{humanizedDateTimeFormat(new Date(issue.createdAt))}
				</Col>
				<Col span={columnSpan} className={styles["issue-details"]}>
					<p>AUSSTATTUNG</p>
					{issue.equipment}
				</Col>
				<Col span={columnSpan} className={styles["issue-details"]}>
					<p>PROBLEM</p>
					{issue.problem}
				</Col>
				<Col span={24} className={styles["issue-details"]}>
					<p>BESCHREIBUNG</p>
					{issue.description}
				</Col>
				{issue.photos.length > 0 && (
					<Col span={24} className={styles["issue-details"]}>
						<p>FOTOS</p>
						{isMobile && (
							<p className={styles["issue-details-info"]}>
								Klicke auf ein Foto um es groß anzuzeigen
							</p>
						)}
						{photos || (
							<div className={styles["photo-placeholder-wrapper"]}>
								{photoPlaceholders}
							</div>
						)}
					</Col>
				)}
				<Col span={24} className={styles["issue-details"]}>
					<IssueComment issueId={issue.id} />
				</Col>
			</Row>
		</Modal>
	);
}

export default ReadIssue;
