import React from "react";
import { Flex, Stack, Text, Image } from "@mantine/core";
import Section from "../Section";
import stationStateOptions from "../../../utilities/client/station-state";
import { StationState } from "../../../utilities/api/jelbi-dashboard-api";
import { ReactComponent as MailIcon } from "../../../assets/icons/mail-line.svg";

type StatusProps = {
	isGeneratingEmailsToMsps: boolean;
	state: StationState;
	iconSize: number;
};

function Status({ isGeneratingEmailsToMsps, state, iconSize }: StatusProps) {
	const { PUBLIC_URL } = process.env;
	const stationStates = stationStateOptions();
	return (
		<Section title="Status">
			<Stack gap={12}>
				<Flex gap={12} align="center">
					<Image
						src={`${PUBLIC_URL}/icon-station-details/station_bw.svg`}
						alt="Station logo"
						h={iconSize}
					/>
					<Text size="sm">
						{
							stationStates.find((stateOption) => stateOption.key === state)
								?.label
						}
					</Text>
				</Flex>
				{isGeneratingEmailsToMsps && (
					<Flex gap={12} align="center">
						<MailIcon
							style={{
								height: `${iconSize}px`,
								width: `${iconSize}px`,
							}}
						/>
						<Text size="sm">MSPs bekommen eine E-Mail</Text>
					</Flex>
				)}
			</Stack>
		</Section>
	);
}
export default Status;
