import React from "react";
import { Text } from "@mantine/core";
import districtOptions from "../../../utilities/client/districts";
import Section from "../Section";

type AddressProps = {
	address: string;
	district: string;
};

function Address({ address, district }: AddressProps) {
	return (
		<Section title="Adresse">
			<Text size="sm">{address}</Text>
			<Text size="sm">
				{
					districtOptions.find(
						(districtOption) => districtOption.key === district
					)?.label
				}
			</Text>
		</Section>
	);
}
export default Address;
