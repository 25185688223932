import React from "react";
import IssueMarkerBadge from "../IssueMarkerBadge";

import styles from "./index.module.scss";
import PlannedClosureBadge from "../PlannedClosureBadge";
import MicromobilityAvailabilityBadge, {
	MicromobilityAvailabilityState,
} from "../MicromobilityAvailabilityBadge";

type ClusterMarkerProps = {
	childCount: number;
	issueCount: number;
	showZeroMicromobilityAvailabilityBadge: boolean;
	showOverMicromobilityToleranceLimit: boolean;
	showPlannedClosureBadge?: boolean;
	opacity: number;
};

function ClusterMarker({
	childCount,
	issueCount,
	showZeroMicromobilityAvailabilityBadge,
	showOverMicromobilityToleranceLimit,
	showPlannedClosureBadge,
	opacity,
}: ClusterMarkerProps): JSX.Element {
	let micromobilityAvailabilityState: MicromobilityAvailabilityState | null;
	switch (true) {
		case showOverMicromobilityToleranceLimit &&
			showZeroMicromobilityAvailabilityBadge:
			micromobilityAvailabilityState = "OVERFILLED_INSUFFICIENT";
			break;
		case showOverMicromobilityToleranceLimit:
			micromobilityAvailabilityState = "OVERFILLED";
			break;
		case showZeroMicromobilityAvailabilityBadge:
			micromobilityAvailabilityState = "INSUFFICIENT";
			break;
		default:
			micromobilityAvailabilityState = null;
	}

	return (
		<div style={{ opacity }}>
			<div className={styles["cluster-box__outer"]}>
				<div className={styles["cluster-box__inner"]}>{childCount}</div>
			</div>
			<div className={styles["cluster-box__top-right-icons"]}>
				<IssueMarkerBadge issueCount={issueCount} />
			</div>
			<div className={styles["cluster-box__bottom-right-icons"]}>
				{micromobilityAvailabilityState !== null ? (
					<MicromobilityAvailabilityBadge
						state={micromobilityAvailabilityState}
					/>
				) : null}
			</div>
			<div className={styles["cluster-box__bottom-left-icons"]}>
				{showPlannedClosureBadge && <PlannedClosureBadge />}
			</div>
		</div>
	);
}

export default ClusterMarker;
