import { ok } from "@oazapfts/runtime";
import { endOfDay, startOfDay, subDays } from "date-fns";
import {
	getAvailabilities,
	TimeSlice,
	VehicleDistribution,
} from "../utilities/api/jelbi-dashboard-api";

const fetchAvailabilitiesDayPerHour = (
	stationId: string,
	startDate: Date
): Promise<VehicleDistribution> =>
	ok(
		getAvailabilities({
			aggregateBy: ["VEHICLE_TYPE"],
			stationIds: [stationId],
			start: startDate.toISOString(),
			end: endOfDay(startDate).toISOString(),
			timeSlice: TimeSlice.Hour,
		})
	);

const fetchAvailabilitiesStationLiveWeek = (
	stationId: string,
	withMsps: boolean,
	accumulateOverWeek: boolean
): Promise<VehicleDistribution> => {
	const requestParameters = accumulateOverWeek
		? {
				stationIds: [stationId],
				start: startOfDay(subDays(new Date(), 7)).toISOString(),
				timeSlice: TimeSlice.Week,
			}
		: {
				start: new Date().toISOString(),
				stationIds: [stationId],
			};

	if (withMsps) {
		return ok(
			getAvailabilities({
				aggregateBy: ["STATION_ID", "MSP_ID", "VEHICLE_TYPE"],
				...requestParameters,
			})
		);
	}

	return ok(
		getAvailabilities({
			aggregateBy: ["STATION_ID", "VEHICLE_TYPE"],
			...requestParameters,
		})
	);
};

export default {
	fetchAvailabilitiesDayPerHour,
	fetchAvailabilitiesStationLiveWeek,
};
