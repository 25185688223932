import React, { ReactElement } from "react";
import { GeoJSON } from "react-leaflet";
import { useMantineTheme } from "@mantine/core";
import micromobilityMailBorder from "./micromobility-mail-border.json";

type MicromobilityMailAreaProps = { zoomLevel?: number };

function MicromobilityMailArea({
	zoomLevel,
}: MicromobilityMailAreaProps): ReactElement {
	const ZOOM_LEVEL_TO_SHOW_FILLING = 16;
	const isFillingShown = !(
		zoomLevel && zoomLevel >= ZOOM_LEVEL_TO_SHOW_FILLING
	);

	const theme = useMantineTheme();

	return (
		<GeoJSON
			style={{
				dashArray: [4, 8],
				color: theme.colors.dark[3],
				fillColor: theme.colors.dark[9],
				fillOpacity: isFillingShown ? 0.08 : 0,
			}}
			data={micromobilityMailBorder as GeoJSON.Polygon}
		/>
	);
}

export default MicromobilityMailArea;
