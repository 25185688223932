import React from "react";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconExclamationCircleFilled } from "@tabler/icons-react";

const showAddInspectionSuccessfulNotification = () => {
	notifications.show({
		id: "successAddInspection",
		position: "top-center",
		message: `Begehung hinzugefügt!`,
		color: "green",
		icon: <IconCheck />,
	});
};

const showAddInspectionFailedNotification = () => {
	notifications.show({
		id: "failAddInspection",
		position: "top-center",
		message: "Begehung konnte nicht hinzugefügt werden!",
		color: "red",
		icon: <IconExclamationCircleFilled />,
	});
};

export default {
	showAddInspectionSuccessfulNotification,
	showAddInspectionFailedNotification,
};
