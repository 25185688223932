import { eachDayOfInterval, subDays, isToday } from "date-fns";
import { BarChartSeries } from "@mantine/charts";
import { DefaultMantineColor, MantineColorsTuple } from "@mantine/core";
import { getWeekdayName } from "../../../../utilities/client/date-format";
import { VehicleType } from "../../../../utilities/api/jelbi-dashboard-api";

export type DropdownOption = {
	key: string;
	label: string;
	value: string;
};

const generateDropdownOptions = (): DropdownOption[] => {
	const weekDays = eachDayOfInterval({
		start: subDays(new Date(), 7),
		end: new Date(),
	});

	return weekDays.map((weekday) => {
		return {
			key: weekday.toISOString(),
			label: isToday(weekday) ? "Heute" : getWeekdayName(weekday),
			value: weekday.toISOString(),
		};
	});
};

const getChartSeries = (
	vehicleTypes: string[],
	colors: Record<DefaultMantineColor, MantineColorsTuple>
): BarChartSeries[] => {
	const colorNames = Object.keys(colors);
	const seriesColor: BarChartSeries[] = [];

	vehicleTypes
		.filter((vehicleType) => vehicleType !== VehicleType.CargoBike)
		.forEach((vehicleType, i) => {
			seriesColor.push({
				name: vehicleType,
				color: `${colorNames[i + 3]}.5`,
			});
		});
	return seriesColor;
};

export default {
	generateDropdownOptions,
	getChartSeries,
};
