import { useState, useEffect } from "react";
import { MspId } from "../../api/jelbi-dashboard-api";
import useMspFromDistributionsMspId from "./useMspFromDistributionsMspId";

const useMspName = (mspId: MspId) => {
	const [name, setName] = useState<string>("");
	const msp = useMspFromDistributionsMspId(mspId);

	useEffect(() => {
		setName(msp?.name ?? "");
	}, [msp]);

	return name;
};

export default useMspName;
